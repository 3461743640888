<template xmlns:svg="http://www.w3.org/1999/xhtml">
  <svg
    viewBox="0 0 399.7 209.8"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <svg:style>
      {{ style }}
    </svg:style>

    <g id="image_group_13">
      <g>
        <defs>
          <rect height="193.7" id="image_clipping_frame_5_13" width="147.4" />
        </defs>
        <clipPath id="image_clipping_frame_1_13">
          <use xlink:href="#image_clipping_frame_5_13" />
        </clipPath>
        <g clip-path="url(#image_clipping_frame_1_13)">
          <image
            :href="
              mainPicture ||
              'data:image/jpeg;base64,/9j/4QkqRXhpZgAATU0AKgAAAAgABwESAAMAAAABAAEAAAEaAAUAAAABAAAAYgEbAAUAAAABAAAA\
agEoAAMAAAABAAIAAAExAAIAAAAhAAAAcgEyAAIAAAAUAAAAk4dpAAQAAAABAAAAqAAAANQALcbA\
AAAnEAAtxsAAACcQQWRvYmUgUGhvdG9zaG9wIDIyLjIgKE1hY2ludG9zaCkAMjAyMTowMzowNSAw\
OToyMzoxNQAAAAOgAQADAAAAAf//AACgAgAEAAAAAQAAAmagAwAEAAAAAQAAAy8AAAAAAAAABgED\
AAMAAAABAAYAAAEaAAUAAAABAAABIgEbAAUAAAABAAABKgEoAAMAAAABAAIAAAIBAAQAAAABAAAB\
MgICAAQAAAABAAAH8AAAAAAAAABIAAAAAQAAAEgAAAAB/9j/7QAMQWRvYmVfQ00AAf/uAA5BZG9i\
ZQBkgAAAAAH/2wCEAAwICAgJCAwJCQwRCwoLERUPDAwPFRgTExUTExgRDAwMDAwMEQwMDAwMDAwM\
DAwMDAwMDAwMDAwMDAwMDAwMDAwBDQsLDQ4NEA4OEBQODg4UFA4ODg4UEQwMDAwMEREMDAwMDAwR\
DAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDP/AABEIAKAAeQMBIgACEQEDEQH/3QAEAAj/xAE/\
AAABBQEBAQEBAQAAAAAAAAADAAECBAUGBwgJCgsBAAEFAQEBAQEBAAAAAAAAAAEAAgMEBQYHCAkK\
CxAAAQQBAwIEAgUHBggFAwwzAQACEQMEIRIxBUFRYRMicYEyBhSRobFCIyQVUsFiMzRygtFDByWS\
U/Dh8WNzNRaisoMmRJNUZEXCo3Q2F9JV4mXys4TD03Xj80YnlKSFtJXE1OT0pbXF1eX1VmZ2hpam\
tsbW5vY3R1dnd4eXp7fH1+f3EQACAgECBAQDBAUGBwcGBTUBAAIRAyExEgRBUWFxIhMFMoGRFKGx\
QiPBUtHwMyRi4XKCkkNTFWNzNPElBhaisoMHJjXC0kSTVKMXZEVVNnRl4vKzhMPTdePzRpSkhbSV\
xNTk9KW1xdXl9VZmdoaWprbG1ub2JzdHV2d3h5ent8f/2gAMAwEAAhEDEQA/AORSSSSUpJJJJSkk\
kklKSSSSUpJJJJSkkkklKSSSSUpJJJJT/9DkUkkklKSSSSUpJJJJSkkkklKSSSSUpJJJJSkkkklK\
SSSSU//R5FJJJJSkkkklKSSSSUpJJJJSkkkklKSSSSUpJJJJSkkkklP/0uRSSSSUpJJM/wCg74H8\
iSnd6R9UeodTxW5jra8THs1pNgc97wNPUbWzbsq/ce9/6RXv/G/yf/LGr/tl/wD6UXRW32Yn1cOT\
RDbMfAZZVIloc2ljmyz85q5D/nt9Yf8ASUf9sN/8kkpu/wDjf5P/AJY1f9sv/wDSiX/jf5P/AJY1\
f9sv/wDSipf89vrD/pKP+2G/+SS/57fWH/SUf9sN/wDJJKbv/jf5P/ljV/2y/wD9KJf+N/k/+WNX\
/bL/AP0oqX/Pb6w/6Sj/ALYb/wCSS/57fWH/AElH/bDf/JJKTZ31G6ljY778e+vMdWC51LGuZYWj\
V3pby9trv+D/AMxc4CCARqDqCvTPqv1DK6l0yjMyy03uusaSxoY2GP2s9gXm2QAMm8AQBbYAB4b3\
JKYJJJJKUkkkkp//0+RSSSSUpRf9B3wP5FJRf9B3wP5ElPpef/4lL/8A02j/AM8NXBdJ6XkdWzmY\
WOWsJaX2WvktYxv07C1vuf8AS2MZ++u9z/8AxKX/APptH/nhq4TonVrOkdQbmNZ6zC11V1U7S6t0\
E7H67bGOYx7ElOl1z6oW9LwjnUZP2qmogXtcz03tDjsFrdr7Gvr3u9/57Fz66fr/ANb6eodPfgYN\
Flbb4F9t20HYCH+lUyp1n03N99jnLP8Aq30N/WM2bARgYxByngwXfnMxa3f6S7893+Bp/Sf6JJTk\
JLR6/wBHs6P1B1Grsa2bMS0/nVzrW7/hsf8Am7f+t2/4VZySn0P6j/8AION/4Yu/8+LgMn+lX/8A\
HW/9W9d/9R/+Qcb/AMMXf+fFwGT/AEq//jrf+rekpGkkkkpSSSSSn//U5FJJJJSlF/0HfA/kUlF/\
0HfA/kSU+l5//iUv/wDTaP8Azw1eakgCTwF6Vn/+JS//ANNo/wDPDVzX1O6RjWXDqudZU2uh36pT\
Y9jS+1v/AGoex7v5vH/wX+kv/wCJSUt/zH6m7AxrmPazMucPtGNZoKq3n2Wbvz7aG+/Ko/63V/Nr\
scDAxunYdWFigimofSP0nuP85dZ/wlrv/SaJ9qxf+5FP/brP/JpfacT/ALkU/wDbtf8A5NJTW6z0\
mnq+A/DtIY8Hfj3H/B2ge1//ABb/AObv/wCDXmd1N2PdZj5DDVfS4strPLXDkf8AkV6r9pxP+5FP\
/btf/k1zf1x6Xi5lH7UxLaXZeO3bfW2xhNtI4c1od7rsb/wSj/iq0lN/6j/8g43/AIYu/wDPi4DJ\
/pV//HW/9W9d/wDUf/kHG/8ADF3/AJ8XAZP9Kv8A+Ot/6t6SkaSSSSlJJJJKf//V5FJJJJSkz/oO\
+B/InSSU+mW0WZf1cONRDrcjAZXVJhpc6ljWy/8ANauNP1H6+TJoxyfE3M/uUukfW7qHTMVuG6qv\
Lx69KRYXMewHX022M3bqv3GPZ+jV7/xwMn/yuq/7ef8A+k0lOf8A8xuvf6DH/wC3mf3Jf8xuvf6D\
H/7eZ/ctD/xwMn/yuq/7ef8A+k0v/HAyf/K6r/t5/wD6TSU5/wDzG69/oMf/ALeZ/cl/zG68NRRj\
/wDbzP7lof8AjgZP/ldV/wBvP/8ASaX/AI4GT/5XVf8Abz//AEmkp6H6rdPyundMow8trW3tuscQ\
xweIe/cz3tXm+QQcm8gyDbYQR4b3LoM7689Sycd9GPRXhOsBa65jnPsDTo70t4Y2p3/Cf5i5wAAA\
DQDQBJSkkkklKSSSSU//1uRSSSSUpJJJJSkkkklKSSSSUpJJJJSkkkklKSSSSUpJJJJT/9fkUkkk\
lKSSSSUpJJJJSkkkklKSSSSUpJJJJSkkkklKSSSSU//Q5FJJJJSkkkklKSSSSUpJJJJSkkkklKSS\
SSUpJJJJSkkkklP/2f/tESJQaG90b3Nob3AgMy4wADhCSU0EJQAAAAAAEAAAAAAAAAAAAAAAAAAA\
AAA4QklNBDoAAAAAAOUAAAAQAAAAAQAAAAAAC3ByaW50T3V0cHV0AAAABQAAAABQc3RTYm9vbAEA\
AAAASW50ZWVudW0AAAAASW50ZQAAAABDbHJtAAAAD3ByaW50U2l4dGVlbkJpdGJvb2wAAAAAC3By\
aW50ZXJOYW1lVEVYVAAAAAEAAAAAAA9wcmludFByb29mU2V0dXBPYmpjAAAADABQAHIAbwBvAGYA\
IABTAGUAdAB1AHAAAAAAAApwcm9vZlNldHVwAAAAAQAAAABCbHRuZW51bQAAAAxidWlsdGluUHJv\
b2YAAAAJcHJvb2ZDTVlLADhCSU0EOwAAAAACLQAAABAAAAABAAAAAAAScHJpbnRPdXRwdXRPcHRp\
b25zAAAAFwAAAABDcHRuYm9vbAAAAAAAQ2xicmJvb2wAAAAAAFJnc01ib29sAAAAAABDcm5DYm9v\
bAAAAAAAQ250Q2Jvb2wAAAAAAExibHNib29sAAAAAABOZ3R2Ym9vbAAAAAAARW1sRGJvb2wAAAAA\
AEludHJib29sAAAAAABCY2tnT2JqYwAAAAEAAAAAAABSR0JDAAAAAwAAAABSZCAgZG91YkBv4AAA\
AAAAAAAAAEdybiBkb3ViQG/gAAAAAAAAAAAAQmwgIGRvdWJAb+AAAAAAAAAAAABCcmRUVW50RiNS\
bHQAAAAAAAAAAAAAAABCbGQgVW50RiNSbHQAAAAAAAAAAAAAAABSc2x0VW50RiNQeGxAcsAAAAAA\
AAAAAAp2ZWN0b3JEYXRhYm9vbAEAAAAAUGdQc2VudW0AAAAAUGdQcwAAAABQZ1BDAAAAAExlZnRV\
bnRGI1JsdAAAAAAAAAAAAAAAAFRvcCBVbnRGI1JsdAAAAAAAAAAAAAAAAFNjbCBVbnRGI1ByY0BZ\
AAAAAAAAAAAAEGNyb3BXaGVuUHJpbnRpbmdib29sAAAAAA5jcm9wUmVjdEJvdHRvbWxvbmcAAAAA\
AAAADGNyb3BSZWN0TGVmdGxvbmcAAAAAAAAADWNyb3BSZWN0UmlnaHRsb25nAAAAAAAAAAtjcm9w\
UmVjdFRvcGxvbmcAAAAAADhCSU0D7QAAAAAAEAEsAAAAAQACASwAAAABAAI4QklNBCYAAAAAAA4A\
AAAAAAAAAAAAP4AAADhCSU0EDQAAAAAABAAAAFo4QklNBBkAAAAAAAQAAAAeOEJJTQPzAAAAAAAJ\
AAAAAAAAAAABADhCSU0nEAAAAAAACgABAAAAAAAAAAI4QklNA/UAAAAAAEgAL2ZmAAEAbGZmAAYA\
AAAAAAEAL2ZmAAEAoZmaAAYAAAAAAAEAMgAAAAEAWgAAAAYAAAAAAAEANQAAAAEALQAAAAYAAAAA\
AAE4QklNA/gAAAAAAHAAAP////////////////////////////8D6AAAAAD/////////////////\
////////////A+gAAAAA/////////////////////////////wPoAAAAAP//////////////////\
//////////8D6AAAOEJJTQQAAAAAAAACAAE4QklNBAIAAAAAAAQAAAAAOEJJTQQwAAAAAAACAQE4\
QklNBC0AAAAAAAYAAQAAAAI4QklNBAgAAAAAABAAAAABAAACQAAAAkAAAAAAOEJJTQQeAAAAAAAE\
AAAAADhCSU0EGgAAAAADSQAAAAYAAAAAAAAAAAAAAy8AAAJmAAAACgBVAG4AdABpAHQAbABlAGQA\
LQAyAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAJmAAADLwAAAAAAAAAAAAAAAAAA\
AAABAAAAAAAAAAAAAAAAAAAAAAAAABAAAAABAAAAAAAAbnVsbAAAAAIAAAAGYm91bmRzT2JqYwAA\
AAEAAAAAAABSY3QxAAAABAAAAABUb3AgbG9uZwAAAAAAAAAATGVmdGxvbmcAAAAAAAAAAEJ0b21s\
b25nAAADLwAAAABSZ2h0bG9uZwAAAmYAAAAGc2xpY2VzVmxMcwAAAAFPYmpjAAAAAQAAAAAABXNs\
aWNlAAAAEgAAAAdzbGljZUlEbG9uZwAAAAAAAAAHZ3JvdXBJRGxvbmcAAAAAAAAABm9yaWdpbmVu\
dW0AAAAMRVNsaWNlT3JpZ2luAAAADWF1dG9HZW5lcmF0ZWQAAAAAVHlwZWVudW0AAAAKRVNsaWNl\
VHlwZQAAAABJbWcgAAAABmJvdW5kc09iamMAAAABAAAAAAAAUmN0MQAAAAQAAAAAVG9wIGxvbmcA\
AAAAAAAAAExlZnRsb25nAAAAAAAAAABCdG9tbG9uZwAAAy8AAAAAUmdodGxvbmcAAAJmAAAAA3Vy\
bFRFWFQAAAABAAAAAAAAbnVsbFRFWFQAAAABAAAAAAAATXNnZVRFWFQAAAABAAAAAAAGYWx0VGFn\
VEVYVAAAAAEAAAAAAA5jZWxsVGV4dElzSFRNTGJvb2wBAAAACGNlbGxUZXh0VEVYVAAAAAEAAAAA\
AAlob3J6QWxpZ25lbnVtAAAAD0VTbGljZUhvcnpBbGlnbgAAAAdkZWZhdWx0AAAACXZlcnRBbGln\
bmVudW0AAAAPRVNsaWNlVmVydEFsaWduAAAAB2RlZmF1bHQAAAALYmdDb2xvclR5cGVlbnVtAAAA\
EUVTbGljZUJHQ29sb3JUeXBlAAAAAE5vbmUAAAAJdG9wT3V0c2V0bG9uZwAAAAAAAAAKbGVmdE91\
dHNldGxvbmcAAAAAAAAADGJvdHRvbU91dHNldGxvbmcAAAAAAAAAC3JpZ2h0T3V0c2V0bG9uZwAA\
AAAAOEJJTQQoAAAAAAAMAAAAAj/wAAAAAAAAOEJJTQQUAAAAAAAEAAAAAzhCSU0EDAAAAAAIDAAA\
AAEAAAB5AAAAoAAAAWwAAOOAAAAH8AAYAAH/2P/tAAxBZG9iZV9DTQAB/+4ADkFkb2JlAGSAAAAA\
Af/bAIQADAgICAkIDAkJDBELCgsRFQ8MDA8VGBMTFRMTGBEMDAwMDAwRDAwMDAwMDAwMDAwMDAwM\
DAwMDAwMDAwMDAwMDAENCwsNDg0QDg4QFA4ODhQUDg4ODhQRDAwMDAwREQwMDAwMDBEMDAwMDAwM\
DAwMDAwMDAwMDAwMDAwMDAwMDAwM/8AAEQgAoAB5AwEiAAIRAQMRAf/dAAQACP/EAT8AAAEFAQEB\
AQEBAAAAAAAAAAMAAQIEBQYHCAkKCwEAAQUBAQEBAQEAAAAAAAAAAQACAwQFBgcICQoLEAABBAED\
AgQCBQcGCAUDDDMBAAIRAwQhEjEFQVFhEyJxgTIGFJGhsUIjJBVSwWIzNHKC0UMHJZJT8OHxY3M1\
FqKygyZEk1RkRcKjdDYX0lXiZfKzhMPTdePzRieUpIW0lcTU5PSltcXV5fVWZnaGlqa2xtbm9jdH\
V2d3h5ent8fX5/cRAAICAQIEBAMEBQYHBwYFNQEAAhEDITESBEFRYXEiEwUygZEUobFCI8FS0fAz\
JGLhcoKSQ1MVY3M08SUGFqKygwcmNcLSRJNUoxdkRVU2dGXi8rOEw9N14/NGlKSFtJXE1OT0pbXF\
1eX1VmZ2hpamtsbW5vYnN0dXZ3eHl6e3x//aAAwDAQACEQMRAD8A5FJJJJSkkkklKSSSSUpJJJJS\
kkkklKSSSSUpJJJJSkkkklP/0ORSSSSUpJJJJSkkkklKSSSSUpJJJJSkkkklKSSSSUpJJJJT/9Hk\
UkkklKSSSSUpJJJJSkkkklKSSSSUpJJJJSkkkklKSSSSU//S5FJJJJSkkkz/AKDvgfyJKd3pH1R6\
h1PFbmOtrxMezWk2Bz3vA09RtbNuyr9x73/pFe/8b/J/8sav+2X/APpRdFbfZifVw5NENsx8BllU\
iWhzaWObLPzmrkP+e31h/wBJR/2w3/ySSm7/AON/k/8AljV/2y//ANKJf+N/k/8AljV/2y//ANKK\
l/z2+sP+ko/7Yb/5JL/nt9Yf9JR/2w3/AMkkpu/+N/k/+WNX/bL/AP0ol/43+T/5Y1f9sv8A/Sip\
f89vrD/pKP8Athv/AJJL/nt9Yf8ASUf9sN/8kkpNnfUbqWNjvvx768x1YLnUsa5lhaNXelvL22u/\
4P8AzFzgIIBGoOoK9M+q/UMrqXTKMzLLTe66xpLGhjYY/az2BebZAAybwBAFtgAHhvckpgkkkkpS\
SSSSn//T5FJJJJSlF/0HfA/kUlF/0HfA/kSU+l5//iUv/wDTaP8Azw1cF0npeR1bOZhY5awlpfZa\
+S1jG/TsLW+5/wBLYxn7673P/wDEpf8A+m0f+eGrhOidWs6R1BuY1nrMLXVXVTtLq3QTsfrtsY5j\
HsSU6XXPqhb0vCOdRk/aqaiBe1zPTe0OOwWt2vsa+ve73/nsXPrp+v8A1vp6h09+Bg0WVtvgX23b\
QdgIf6VTKnWfTc332Ocs/wCrfQ39YzZsBGBjEHKeDBd+czFrd/pLvz3f4Gn9J/oklOQktHr/AEez\
o/UHUauxrZsxLT+dXOtbv+Gx/wCbt/63b/hVnJKfQ/qP/wAg43/hi7/z4uAyf6Vf/wAdb/1b13/1\
H/5Bxv8Awxd/58XAZP8ASr/+Ot/6t6SkaSSSSlJJJJKf/9TkUkkklKUX/Qd8D+RSUX/Qd8D+RJT6\
Xn/+JS//ANNo/wDPDV5qSAJPAXpWf/4lL/8A02j/AM8NXNfU7pGNZcOq51lTa6HfqlNj2NL7W/8A\
ah7Hu/m8f/Bf6S//AIlJS3/MfqbsDGuY9rMy5w+0Y1mgqrefZZu/Ptob78qj/rdX82uxwMDG6dh1\
YWKCKah9I/Se4/zl1n/CWu/9Jon2rF/7kU/9us/8ml9pxP8AuRT/ANu1/wDk0lNbrPSaer4D8O0h\
jwd+Pcf8HaB7X/8AFv8A5u//AINeZ3U3Y91mPkMNV9Liy2s8tcOR/wCRXqv2nE/7kU/9u1/+TXN/\
XHpeLmUftTEtpdl47dt9bbGE20jhzWh3uuxv/BKP+KrSU3/qP/yDjf8Ahi7/AM+LgMn+lX/8db/1\
b13/ANR/+Qcb/wAMXf8AnxcBk/0q/wD463/q3pKRpJJJKUkkkkp//9XkUkkklKTP+g74H8idJJT6\
ZbRZl/Vw41EOtyMBldUmGlzqWNbL/wA1q40/Ufr5MmjHJ8Tcz+5S6R9buodMxW4bqq8vHr0pFhcx\
7AdfTbYzduq/cY9n6NXv/HAyf/K6r/t5/wD6TSU5/wDzG69/oMf/ALeZ/cl/zG69/oMf/t5n9y0P\
/HAyf/K6r/t5/wD6TS/8cDJ/8rqv+3n/APpNJTn/APMbr3+gx/8At5n9yX/Mbrw1FGP/ANvM/uWh\
/wCOBk/+V1X/AG8//wBJpf8AjgZP/ldV/wBvP/8ASaSnofqt0/K6d0yjDy2tbe26xxDHB4h79zPe\
1eb5BBybyDINthBHhvcugzvrz1LJx30Y9FeE6wFrrmOc+wNOjvS3hjanf8J/mLnAAAANANAElKSS\
SSUpJJJJT//W5FJJJJSkkkklKSSSSUpJJJJSkkkklKSSSSUpJJJJSkkkklP/1+RSSSSUpJJJJSkk\
kklKSSSSUpJJJJSkkkklKSSSSUpJJJJT/9DkUkkklKSSSSUpJJJJSkkkklKSSSSUpJJJJSkkkklK\
SSSSU//ZOEJJTQQhAAAAAABXAAAAAQEAAAAPAEEAZABvAGIAZQAgAFAAaABvAHQAbwBzAGgAbwBw\
AAAAFABBAGQAbwBiAGUAIABQAGgAbwB0AG8AcwBoAG8AcAAgADIAMAAyADEAAAABADhCSU0EBgAA\
AAAABwAFAAAAAQEA/+EObmh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8APD94cGFja2V0IGJl\
Z2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxu\
czp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNi4wLWMwMDYgNzku\
MTY0NjQ4LCAyMDIxLzAxLzEyLTE1OjUyOjI5ICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9\
Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2Ny\
aXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEu\
MC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpz\
dEV2dD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlRXZlbnQjIiB4\
bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnBob3Rvc2hv\
cD0iaHR0cDovL25zLmFkb2JlLmNvbS9waG90b3Nob3AvMS4wLyIgeG1wOkNyZWF0b3JUb29sPSJB\
ZG9iZSBQaG90b3Nob3AgMjIuMiAoTWFjaW50b3NoKSIgeG1wOkNyZWF0ZURhdGU9IjIwMjEtMDMt\
MDVUMDk6MTQ6NTUrMDI6MDAiIHhtcDpNb2RpZnlEYXRlPSIyMDIxLTAzLTA1VDA5OjIzOjE1KzAy\
OjAwIiB4bXA6TWV0YWRhdGFEYXRlPSIyMDIxLTAzLTA1VDA5OjIzOjE1KzAyOjAwIiB4bXBNTTpE\
b2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6MjcwMzIxZWQtZTE4My1iNjQ5LWIwYmEt\
YmY3MTNhZjUxMDY2IiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjU0ZDA2NjRkLTRjNmItNDRm\
YS1iN2FlLTM2OGIwZGUwODg4ZCIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJhZG9iZTpkb2Np\
ZDpwaG90b3Nob3A6MjdhOTEzYmQtNWVlYi01ZTQyLTk4NDQtZTMxZGNiM2MzODUwIiBkYzpmb3Jt\
YXQ9ImltYWdlL2pwZWciIHBob3Rvc2hvcDpDb2xvck1vZGU9IjMiIHBob3Rvc2hvcDpJQ0NQcm9m\
aWxlPSJEaXNwbGF5Ij4gPHhtcE1NOkhpc3Rvcnk+IDxyZGY6U2VxPiA8cmRmOmxpIHN0RXZ0OmFj\
dGlvbj0iY3JlYXRlZCIgc3RFdnQ6aW5zdGFuY2VJRD0ieG1wLmlpZDo0ZmMzZDFhMC0wODdmLTRl\
OGEtYTZlOC05ODY3ZDBjNmJhMjciIHN0RXZ0OndoZW49IjIwMjEtMDMtMDVUMDk6MTQ6NTUrMDI6\
MDAiIHN0RXZ0OnNvZnR3YXJlQWdlbnQ9IkFkb2JlIFBob3Rvc2hvcCAyMi4yIChNYWNpbnRvc2gp\
Ii8+IDxyZGY6bGkgc3RFdnQ6YWN0aW9uPSJjb252ZXJ0ZWQiIHN0RXZ0OnBhcmFtZXRlcnM9ImZy\
b20gYXBwbGljYXRpb24vdm5kLmFkb2JlLnBob3Rvc2hvcCB0byBpbWFnZS9qcGVnIi8+IDxyZGY6\
bGkgc3RFdnQ6YWN0aW9uPSJzYXZlZCIgc3RFdnQ6aW5zdGFuY2VJRD0ieG1wLmlpZDo1NGQwNjY0\
ZC00YzZiLTQ0ZmEtYjdhZS0zNjhiMGRlMDg4OGQiIHN0RXZ0OndoZW49IjIwMjEtMDMtMDVUMDk6\
MjM6MTUrMDI6MDAiIHN0RXZ0OnNvZnR3YXJlQWdlbnQ9IkFkb2JlIFBob3Rvc2hvcCAyMi4yIChN\
YWNpbnRvc2gpIiBzdEV2dDpjaGFuZ2VkPSIvIi8+IDwvcmRmOlNlcT4gPC94bXBNTTpIaXN0b3J5\
PiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAg\
ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICA8P3hwYWNrZXQgZW5kPSJ3\
Ij8+/+IQCElDQ19QUk9GSUxFAAEBAAAP+GFwcGwCEAAAbW50clJHQiBYWVogB+UAAQAHAAEANQA1\
YWNzcEFQUEwAAAAAQVBQTAAAAAAAAAAAAAAAAAAAAAEAAPbWAAEAAAAA0y1hcHBsAAAAAAAAAAAA\
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAASZGVzYwAAAVwAAABiZHNjbQAA\
AcAAAAScY3BydAAABlwAAAAjd3RwdAAABoAAAAAUclhZWgAABpQAAAAUZ1hZWgAABqgAAAAUYlhZ\
WgAABrwAAAAUclRSQwAABtAAAAgMYWFyZwAADtwAAAAgdmNndAAADvwAAAAwbmRpbgAADywAAAA+\
Y2hhZAAAD2wAAAAsbW1vZAAAD5gAAAAodmNncAAAD8AAAAA4YlRSQwAABtAAAAgMZ1RSQwAABtAA\
AAgMYWFiZwAADtwAAAAgYWFnZwAADtwAAAAgZGVzYwAAAAAAAAAIRGlzcGxheQAAAAAAAAAAAAAA\
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA\
AAAAAAAAAAAAAAAAAG1sdWMAAAAAAAAAJgAAAAxockhSAAAAFAAAAdhrb0tSAAAADAAAAexuYk5P\
AAAAEgAAAfhpZAAAAAAAEgAAAgpodUhVAAAAFAAAAhxjc0NaAAAAFgAAAjBkYURLAAAAHAAAAkZu\
bE5MAAAAFgAAAmJmaUZJAAAAEAAAAnhpdElUAAAAGAAAAohlc0VTAAAAFgAAAqByb1JPAAAAEgAA\
ArZmckNBAAAAFgAAAshhcgAAAAAAFAAAAt51a1VBAAAAHAAAAvJoZUlMAAAAFgAAAw56aFRXAAAA\
CgAAAyR2aVZOAAAADgAAAy5za1NLAAAAFgAAAzx6aENOAAAACgAAAyRydVJVAAAAJAAAA1JlbkdC\
AAAAFAAAA3ZmckZSAAAAFgAAA4ptcwAAAAAAEgAAA6BoaUlOAAAAEgAAA7J0aFRIAAAADAAAA8Rj\
YUVTAAAAGAAAA9BlbkFVAAAAFAAAA3Zlc1hMAAAAEgAAArZkZURFAAAAEAAAA+hlblVTAAAAEgAA\
A/hwdEJSAAAAGAAABApwbFBMAAAAEgAABCJlbEdSAAAAIgAABDRzdlNFAAAAEAAABFZ0clRSAAAA\
FAAABGZwdFBUAAAAFgAABHpqYUpQAAAADAAABJAATABDAEQAIAB1ACAAYgBvAGoAac7st+wAIABM\
AEMARABGAGEAcgBnAGUALQBMAEMARABMAEMARAAgAFcAYQByAG4AYQBTAHoA7QBuAGUAcwAgAEwA\
QwBEAEIAYQByAGUAdgBuAP0AIABMAEMARABMAEMARAAtAGYAYQByAHYAZQBzAGsA5gByAG0ASwBs\
AGUAdQByAGUAbgAtAEwAQwBEAFYA5AByAGkALQBMAEMARABMAEMARAAgAGEAIABjAG8AbABvAHIA\
aQBMAEMARAAgAGEAIABjAG8AbABvAHIATABDAEQAIABjAG8AbABvAHIAQQBDAEwAIABjAG8AdQBs\
AGUAdQByIA8ATABDAEQAIAZFBkQGSAZGBikEGgQ+BDsETAQ+BEAEPgQyBDgEOQAgAEwAQwBEIA8A\
TABDAEQAIAXmBdEF4gXVBeAF2V9pgnIATABDAEQATABDAEQAIABNAOAAdQBGAGEAcgBlAGIAbgD9\
ACAATABDAEQEJgQyBDUEQgQ9BD4EOQAgBBYEGgAtBDQEOARBBD8EOwQ1BDkAQwBvAGwAbwB1AHIA\
IABMAEMARABMAEMARAAgAGMAbwB1AGwAZQB1AHIAVwBhAHIAbgBhACAATABDAEQJMAkCCRcJQAko\
ACAATABDAEQATABDAEQAIA4qDjUATABDAEQAIABlAG4AIABjAG8AbABvAHIARgBhAHIAYgAtAEwA\
QwBEAEMAbwBsAG8AcgAgAEwAQwBEAEwAQwBEACAAQwBvAGwAbwByAGkAZABvAEsAbwBsAG8AcgAg\
AEwAQwBEA4gDswPHA8EDyQO8A7cAIAO/A7gDzAO9A7cAIABMAEMARABGAOQAcgBnAC0ATABDAEQA\
UgBlAG4AawBsAGkAIABMAEMARABMAEMARAAgAGEAIABDAG8AcgBlAHMwqzDpMPwATABDAER0ZXh0\
AAAAAENvcHlyaWdodCBBcHBsZSBJbmMuLCAyMDIxAABYWVogAAAAAAAA81EAAQAAAAEWzFhZWiAA\
AAAAAACD3wAAPb////+7WFlaIAAAAAAAAEq/AACxNwAACrlYWVogAAAAAAAAKDgAABELAADIuWN1\
cnYAAAAAAAAEAAAAAAUACgAPABQAGQAeACMAKAAtADIANgA7AEAARQBKAE8AVABZAF4AYwBoAG0A\
cgB3AHwAgQCGAIsAkACVAJoAnwCjAKgArQCyALcAvADBAMYAywDQANUA2wDgAOUA6wDwAPYA+wEB\
AQcBDQETARkBHwElASsBMgE4AT4BRQFMAVIBWQFgAWcBbgF1AXwBgwGLAZIBmgGhAakBsQG5AcEB\
yQHRAdkB4QHpAfIB+gIDAgwCFAIdAiYCLwI4AkECSwJUAl0CZwJxAnoChAKOApgCogKsArYCwQLL\
AtUC4ALrAvUDAAMLAxYDIQMtAzgDQwNPA1oDZgNyA34DigOWA6IDrgO6A8cD0wPgA+wD+QQGBBME\
IAQtBDsESARVBGMEcQR+BIwEmgSoBLYExATTBOEE8AT+BQ0FHAUrBToFSQVYBWcFdwWGBZYFpgW1\
BcUF1QXlBfYGBgYWBicGNwZIBlkGagZ7BowGnQavBsAG0QbjBvUHBwcZBysHPQdPB2EHdAeGB5kH\
rAe/B9IH5Qf4CAsIHwgyCEYIWghuCIIIlgiqCL4I0gjnCPsJEAklCToJTwlkCXkJjwmkCboJzwnl\
CfsKEQonCj0KVApqCoEKmAquCsUK3ArzCwsLIgs5C1ELaQuAC5gLsAvIC+EL+QwSDCoMQwxcDHUM\
jgynDMAM2QzzDQ0NJg1ADVoNdA2ODakNww3eDfgOEw4uDkkOZA5/DpsOtg7SDu4PCQ8lD0EPXg96\
D5YPsw/PD+wQCRAmEEMQYRB+EJsQuRDXEPURExExEU8RbRGMEaoRyRHoEgcSJhJFEmQShBKjEsMS\
4xMDEyMTQxNjE4MTpBPFE+UUBhQnFEkUahSLFK0UzhTwFRIVNBVWFXgVmxW9FeAWAxYmFkkWbBaP\
FrIW1hb6Fx0XQRdlF4kXrhfSF/cYGxhAGGUYihivGNUY+hkgGUUZaxmRGbcZ3RoEGioaURp3Gp4a\
xRrsGxQbOxtjG4obshvaHAIcKhxSHHscoxzMHPUdHh1HHXAdmR3DHeweFh5AHmoelB6+HukfEx8+\
H2kflB+/H+ogFSBBIGwgmCDEIPAhHCFIIXUhoSHOIfsiJyJVIoIiryLdIwojOCNmI5QjwiPwJB8k\
TSR8JKsk2iUJJTglaCWXJccl9yYnJlcmhya3JugnGCdJJ3onqyfcKA0oPyhxKKIo1CkGKTgpaymd\
KdAqAio1KmgqmyrPKwIrNitpK50r0SwFLDksbiyiLNctDC1BLXYtqy3hLhYuTC6CLrcu7i8kL1ov\
kS/HL/4wNTBsMKQw2zESMUoxgjG6MfIyKjJjMpsy1DMNM0YzfzO4M/E0KzRlNJ402DUTNU01hzXC\
Nf02NzZyNq426TckN2A3nDfXOBQ4UDiMOMg5BTlCOX85vDn5OjY6dDqyOu87LTtrO6o76DwnPGU8\
pDzjPSI9YT2hPeA+ID5gPqA+4D8hP2E/oj/iQCNAZECmQOdBKUFqQaxB7kIwQnJCtUL3QzpDfUPA\
RANER0SKRM5FEkVVRZpF3kYiRmdGq0bwRzVHe0fASAVIS0iRSNdJHUljSalJ8Eo3Sn1KxEsMS1NL\
mkviTCpMcky6TQJNSk2TTdxOJU5uTrdPAE9JT5NP3VAnUHFQu1EGUVBRm1HmUjFSfFLHUxNTX1Oq\
U/ZUQlSPVNtVKFV1VcJWD1ZcVqlW91dEV5JX4FgvWH1Yy1kaWWlZuFoHWlZaplr1W0VblVvlXDVc\
hlzWXSddeF3JXhpebF69Xw9fYV+zYAVgV2CqYPxhT2GiYfViSWKcYvBjQ2OXY+tkQGSUZOllPWWS\
ZedmPWaSZuhnPWeTZ+loP2iWaOxpQ2maafFqSGqfavdrT2una/9sV2yvbQhtYG25bhJua27Ebx5v\
eG/RcCtwhnDgcTpxlXHwcktypnMBc11zuHQUdHB0zHUodYV14XY+dpt2+HdWd7N4EXhueMx5KnmJ\
eed6RnqlewR7Y3vCfCF8gXzhfUF9oX4BfmJ+wn8jf4R/5YBHgKiBCoFrgc2CMIKSgvSDV4O6hB2E\
gITjhUeFq4YOhnKG14c7h5+IBIhpiM6JM4mZif6KZIrKizCLlov8jGOMyo0xjZiN/45mjs6PNo+e\
kAaQbpDWkT+RqJIRknqS45NNk7aUIJSKlPSVX5XJljSWn5cKl3WX4JhMmLiZJJmQmfyaaJrVm0Kb\
r5wcnImc951kndKeQJ6unx2fi5/6oGmg2KFHobaiJqKWowajdqPmpFakx6U4pammGqaLpv2nbqfg\
qFKoxKk3qamqHKqPqwKrdavprFys0K1ErbiuLa6hrxavi7AAsHWw6rFgsdayS7LCszizrrQltJy1\
E7WKtgG2ebbwt2i34LhZuNG5SrnCuju6tbsuu6e8IbybvRW9j74KvoS+/796v/XAcMDswWfB48Jf\
wtvDWMPUxFHEzsVLxcjGRsbDx0HHv8g9yLzJOsm5yjjKt8s2y7bMNcy1zTXNtc42zrbPN8+40DnQ\
utE80b7SP9LB00TTxtRJ1MvVTtXR1lXW2Ndc1+DYZNjo2WzZ8dp22vvbgNwF3IrdEN2W3hzeot8p\
36/gNuC94UThzOJT4tvjY+Pr5HPk/OWE5g3mlucf56noMui86Ubp0Opb6uXrcOv77IbtEe2c7iju\
tO9A78zwWPDl8XLx//KM8xnzp/Q09ML1UPXe9m32+/eK+Bn4qPk4+cf6V/rn+3f8B/yY/Sn9uv5L\
/tz/bf//cGFyYQAAAAAAAwAAAAJmZgAA8qcAAA1ZAAAT0AAAClt2Y2d0AAAAAAAAAAEAAQAAAAAA\
AAABAAAAAQAAAAAAAAABAAAAAQAAAAAAAAABAABuZGluAAAAAAAAADYAAK4UAABR7AAAQ9cAALCk\
AAAmZgAAD1wAAFANAABUOQACMzMAAjMzAAIzMwAAAAAAAAAAc2YzMgAAAAAAAQxCAAAF3v//8yYA\
AAeTAAD9kP//+6L///2jAAAD3AAAwG5tbW9kAAAAAAAABhAAAKA8/WJtYgAAAAAAAAAAAAAAAAAA\
AAAAAAAAdmNncAAAAAAAAwAAAAJmZgADAAAAAmZmAAMAAAACZmYAAAACMzM0AAAAAAIzMzQAAAAA\
AjMzNAD/7gAOQWRvYmUAZEAAAAAB/9sAhAAEAwMDAwMEAwMEBgQDBAYHBQQEBQcIBgYHBgYICggJ\
CQkJCAoKDAwMDAwKDAwMDAwMDAwMDAwMDAwMDAwMDAwMAQQFBQgHCA8KCg8UDg4OFBQODg4OFBEM\
DAwMDBERDAwMDAwMEQwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAMvAmYDAREAAhEB\
AxEB/90ABABN/8QBogAAAAcBAQEBAQAAAAAAAAAABAUDAgYBAAcICQoLAQACAgMBAQEBAQAAAAAA\
AAABAAIDBAUGBwgJCgsQAAIBAwMCBAIGBwMEAgYCcwECAxEEAAUhEjFBUQYTYSJxgRQykaEHFbFC\
I8FS0eEzFmLwJHKC8SVDNFOSorJjc8I1RCeTo7M2F1RkdMPS4ggmgwkKGBmElEVGpLRW01UoGvLj\
88TU5PRldYWVpbXF1eX1ZnaGlqa2xtbm9jdHV2d3h5ent8fX5/c4SFhoeIiYqLjI2Oj4KTlJWWl5\
iZmpucnZ6fkqOkpaanqKmqq6ytrq+hEAAgIBAgMFBQQFBgQIAwNtAQACEQMEIRIxQQVRE2EiBnGB\
kTKhsfAUwdHhI0IVUmJy8TMkNEOCFpJTJaJjssIHc9I14kSDF1STCAkKGBkmNkUaJ2R0VTfyo7PD\
KCnT4/OElKS0xNTk9GV1hZWltcXV5fVGVmZ2hpamtsbW5vZHV2d3h5ent8fX5/c4SFhoeIiYqLjI\
2Oj4OUlZaXmJmam5ydnp+So6SlpqeoqaqrrK2ur6/9oADAMBAAIRAxEAPwDzrirsVdirsVdirsVd\
irsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdi\
rsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir\
sVdirsVdirsVdirsVdirsVdir//Q864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXY\
q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq\
7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq//0fOu\
KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2K\
uxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Ku\
xV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv/9LzrirsVdirsVdirsVdirsVdirsVdirsVdi\
rsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir\
sVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirs\
VdirsVdir//T864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq\
7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7\
FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq//1POuKuxV2KuxV2KuxV2K\
uxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Ku\
xV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kux\
V2KuxV2KuxV2KuxV2KuxV2Kv/9XzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir\
sVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirs\
VdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir//W864q\
7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7\
FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7F\
XYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq//1/OuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Ku\
xV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kux\
V2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV\
2KuxV2Kv/9DzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirs\
VdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsV\
dirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir//R864q7FXYq7FXYq7FXYq7\
FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7F\
XYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FX\
Yq7FXYq7FXYq7FXYq7FXYq//0vOuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kux\
V2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV\
2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv/9Pzrirs\
VdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsV\
dirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVd\
irsVdirsVdirsVdirsVdirsVdirsVdirsVdir//U864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7F\
XYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FX\
Yq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXY\
q7FXYq//1fOuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV\
2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2\
KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv/9bzrirsVdirsVdirsVdirsV\
dirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVd\
irsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdi\
rsVdirsVdirsVdirsVdir//X864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FX\
Yq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXY\
q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq//0POuKuxV\
2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2\
KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2K\
uxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv/9HzrirsVdirsVdirsVdirsVdirsVdirsVdirsVd\
irsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdi\
rsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir\
sVdir//S864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXY\
q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq\
7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq//0/OuKuxV2KuxV2KuxV2KuxV2\
KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2K\
uxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Ku\
xV2KuxV2KuxV2KuxV2Kv/9TzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdi\
rsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir\
sVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir//V864q7FXY\
q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq\
7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7\
FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq//1vOuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2K\
uxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Ku\
xV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kux\
V2Kv/9fzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir\
sVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirs\
VdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir//Q864q7FXYq7FXYq7FXYq7FXYq\
7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7\
FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7F\
XYq7FXYq7FXYq7FXYq//0fOuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Ku\
xV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kux\
V2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv/9LzrirsVdir\
sVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirs\
VdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsV\
dirsVdirsVdirsVdirsVdirsVdirsVdir//T864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7\
FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7F\
XYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FX\
Yq//1POuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kux\
V2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV\
2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv/9XzrirsVdirsVdirsVdirsVdirs\
VdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsV\
dirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVd\
irsVdirsVdirsVdir//W864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7F\
XYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FX\
Yq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq//1/OuKuxV2Kux\
V2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV\
2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2\
KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv/9DzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsV\
dirsVdirsVdirsVeheQ/yd81+elW8hRdO0Qn/jo3QNHH/FSDd/nsv+Vir3TQ/wDnHTyHpqK2qG51\
e4H2zLIYYj8ki4kf8EcVT4fkl+V4/wClAn/I+5/6q4q7/lSX5X/9WCP/AJH3H/VXFXf8qS/K/wD6\
sEf/ACPuP+quKu/5Ul+V/wD1YI/+R9x/1VxV3/Kkvyv/AOrBH/yPuP8Aqrirv+VJflf/ANWCP/kf\
cf8AVXFXf8qS/K//AKsEf/I+4/6q4q7/AJUl+V//AFYI/wDkfcf9VcVd/wAqS/K//qwR/wDI+4/6\
q4q7/lSX5X/9WCP/AJH3H/VXFXf8qS/K/wD6sEf/ACPuP+quKu/5Ul+V/wD1YI/+R9x/1VxV3/Kk\
vyv/AOrBH/yPuP8Aqrirv+VJflf/ANWCP/kfcf8AVXFXf8qS/K//AKsEf/I+4/6q4q7/AJUl+V//\
AFYI/wDkfcf9VcVd/wAqS/K//qwR/wDI+4/6q4q7/lSX5X/9WCP/AJH3H/VXFXf8qS/K/wD6sEf/\
ACPuP+quKu/5Ul+V/wD1YI/+R9x/1VxV3/Kkvyv/AOrBH/yPuP8Aqrirv+VJflf/ANWCP/kfcf8A\
VXFXf8qS/K//AKsEf/I+4/6q4q7/AJUl+V//AFYI/wDkfcf9VcVd/wAqS/K//qwR/wDI+4/6q4q7\
/lSX5X/9WCP/AJH3H/VXFXf8qS/K/wD6sEf/ACPuP+quKu/5Ul+V/wD1YI/+R9x/1VxVZL+R35YS\
oyDQhGSKB0uLgMPlWQjFWBeZ/wDnGbT5Ynn8o6nJb3AFVtL/APeRMfASIAy/SrYq8C8x+WNd8p6i\
+l6/ZvZ3a1Kht0da05I4qGHuDiqUYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7\
FXYq7FX/0fOuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KvavyS/KJPNLr5\
p8yRV8vwPS0tG2+tyody3/FanY/ztt0BxV9URxxwxrFEgjiQBURQFVVAoAANgBiq7FXYq7FXYq7F\
XYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FWP+cPJuied9\
Hk0jWoQ6mrW9yoHrQS02eNux8R0bvir4r86eUNU8ka/caFqgq8fx29wook0LfZkX59x+y22KsfxV\
2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV//0vOuKuxV2KuxV2KuxV2KuxV2\
KuxV2KuxV2KuxV2KuxV2KuxV2KuxVOfKegT+afMem6Bbkq99OsTuP2I+rt/sVBOKvvHTdOs9I0+2\
0vT4hDZWkawwRjoEQUGKorFXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq\
7FXYq7FXYq7FXYq7FXYq7FXYq7FXk35/+T4vMHk2TWoI66podbhHA3a2aglQ+wHx/wCx98VfImKu\
xV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv8A/9PzrirsVdirsVdirsVdirsV\
dirsVdirsVdirsVdirsVdirsVdirsVexf8422EN159nupBV7Gwmlh9md0iP/AArnFX1lirsVdirs\
VdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsV\
Q2oWcWoWF1YTisF1DJBIPFJFKn8Dir895k9OWSP+Riv3GmKrMVdirsVdirsVdirsVdirsVdirsVd\
irsVdirsVdirsVdirsVdir//1POuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kux\
V7b/AM4yf8plqn/bNb/k/Fir6nxV2KuxV4b/AM5EeavMflr9AfoDU59O+sfWfX+rvw58PT41+VTi\
rwz/AJWp+Yv/AFMt9/yNOKu/5Wp+Yv8A1Mt9/wAjTirv+VqfmL/1Mt9/yNOKu/5Wp+Yv/Uy33/I0\
4q7/AJWp+Yv/AFMt9/yNOKu/5Wp+Yv8A1Mt9/wAjTirv+VqfmL/1Mt9/yNOKu/5Wp+Yv/Uy33/I0\
4q7/AJWp+Yv/AFMt9/yNOKu/5Wp+Yv8A1Mt9/wAjTirv+VqfmL/1Mt9/yNOKu/5Wp+Yv/Uy33/I0\
4q7/AJWp+Yv/AFMt9/yNOKu/5Wp+Yv8A1Mt9/wAjTirv+VqfmL/1Mt9/yNOKu/5Wp+Yv/Uy33/I0\
4q7/AJWp+Yv/AFMt9/yNOKu/5Wp+Yv8A1Mt9/wAjTirv+VqfmL/1Mt9/yNOKu/5Wp+Yv/Uy33/I0\
4q7/AJWp+Yv/AFMt9/yNOKu/5Wp+Yv8A1Mt9/wAjTirv+VqfmL/1Mt9/yNOKu/5Wp+Yv/Uy33/I0\
4q7/AJWp+Yv/AFMt9/yNOKu/5Wp+Yv8A1Mt9/wAjTirv+VqfmL/1Mt//AMjTir7J8mXVzfeUdDvL\
yVprq4sbeWaZzVnd41JYnxJxVPMVdirTfZPyxV+eV5/vXcf8ZH/4kcVUcVdirsVdirsVdirsVdir\
sVdirsVdirsVdirsVdirsVdirsVdir//1fOuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2\
KuxV2KuxV7b/AM4yf8plqn/bNb/k/Fir6nxV2KuxV86/85R9PLX/AEd/8ysVfOuKuxV2KuxV2Kux\
V2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KvvLyD/yhHl3/tnWv/JpcVZF\
irsVab7J+WKvzyvP967j/jI//EjiqjirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdi\
rsVf/9bzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVe2/wDOMn/KZap/2zW/\
5PxYq+p8VdirsVfOv/OUfTy1/wBHf/MrFXzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVd\
irsVdirsVdirsVdirsVdirsVdir7y8g/8oR5d/7Z1r/yaXFWRYq7FWm+yflir88rz/eu4/4yP/xI\
4qo4q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FX//X864q7FXYq7FXYq7FXYq7\
FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXtv8AzjJ/ymWqf9s1v+T8WKvqfFXYq7FXzr/zlH08tf8A\
R3/zKxV864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq\
+8vIP/KEeXf+2da/8mlxVkWKuxVpvsn5Yq/PK8/3ruP+Mj/8SOKqOKuxV2KuxV2KuxV2KuxV2Kux\
V2KuxV2KuxV2KuxV2KuxV2KuxV//0POuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV\
2KuxV7b/AM4yf8plqn/bNb/k/Fir6nxV2KuxV86/85R9PLX/AEd/8ysVfOuKuxV2KuxV2KuxV2Ku\
xV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KvvLyD/yhHl3/tnWv/JpcVZFirsV\
ab7J+WKvzyvP967j/jI//EjiqjirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVf\
/9HzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVe2/wDOMn/KZap/2zW/5PxY\
q+p8VdirsVfOv/OUfTy1/wBHf/MrFXzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsV\
dirsVdirsVdirsVdirsVdir7y8g/8oR5d/7Z1r/yaXFWRYq7FWm+yflir88rz/eu4/4yP/xI4qo4\
q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FX//S864q7FXYq7FXYq7FXYq7FXYq\
7FXYq7FXYq7FXYq7FXYq7FXYq7FXtv8AzjJ/ymWqf9s1v+T8WKvqfFXYq7FXzr/zlH08tf8AR3/z\
KxV864q7FXYq7FXpf5Zfk7rHn5hqN0507y2jcWvCtZJiDusKnrTux+Ef5WKvpPy/+UvkDy5Ei2mj\
Q3M69bq9AuZmPjV6gfJVUYqyR/Lvl+RDHJpVm0Z2Km3iI+7jirBPNf5FeRfMcLvZWg0XUiD6dzYj\
jHy/y4fsEfLi3+Vir5g88eQ9e8haodO1iLlDJU2l7HUwToO6k9CP2lO4xVi+KuxV2KuxV2KuxV2K\
uxV2KuxV2KuxV2KuxV2KuxV2KuxV95eQf+UI8u/9s61/5NLirIsVdirTfZPyxV+eV5/vXcf8ZH/4\
kcVUcVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir//0/OuKuxV2KuxV2KuxV2K\
uxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV7b/AM4yf8plqn/bNb/k/Fir6nxV2KuxV86/85R9PLX/\
AEd/8ysVfOuKuxV2Kst/LXyc/nnzbZ6IarZCtxqEi9VtoqcqHsWJCD3bFX3BZWVpp1pBYWMK29nb\
IsUEMYoqIooABiqvirsVdirHvOvlDTfO3l+60PUUH7xS1rcUq0M4HwSL8j1HddsVfC+qaddaPqV3\
pV8np3llK9vOng8bFT+rFUJirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir7y8g/8AKEeX\
f+2da/8AJpcVZFirsVab7J+WKvzyvP8Aeu4/4yP/AMSOKqOKuxV2KuxV2KuxV2KuxV2KuxV2KuxV\
2KuxV2KuxV2KuxV2KuxV/9TzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVe2\
/wDOMn/KZap/2zW/5PxYq+p8VdirsVfOv/OUfTy1/wBHf/MrFXzrirsVdir6K/5xd06OnmLViAZR\
9XtUPcKebtT5/D92KvorFXYq7FXYq7FXx3/zkBp0Vh+ZN7LEOIvre3umA6cinpk/SY64q8vxV2Ku\
xV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV95eQf8AlCPLv/bOtf8Ak0uKsixV2KtN9k/LFX55\
Xn+9dx/xkf8A4kcVUcVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir/AP/V864q\
7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXtv8AzjJ/ymWqf9s1v+T8WKvqfFXY\
q7FXzr/zlH08tf8AR3/zKxV864q7FXYq+if+cXdSjB8w6QxAlP1e7Qdyo5Rt9xK/fir6LxV2KuxV\
2KuxV8dfn/qUWofmTexwkMthBBaMw6c1T1GH0F6Yq8wxV2KuxV2KuxV1CKVHXpirsVdirsVdirsV\
dirsVdirsVdirsVfeXkH/lCPLv8A2zrX/k0uKsixV2KtN9k/LFX55Xn+9dx/xkf/AIkcVUcVdirs\
VdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir/9bzrirsVdirsVdirsVdirsVdirsVdir\
sVdirsVdirsVdirsVdirsVe2/wDOMn/KZap/2zW/5PxYq+p8VdirsVfOv/OUfTy1/wBHf/MrFXzr\
irsVdirKvy684S+R/NllrqgvaqTDfRL1e2loHA9xsw/ylGKvuHT9Qs9VsbfUtPmW4sbpFlgmQ1Vk\
YVBGKonFXYq7FWNeevOWneRvL1zrd+waVQUsratGnuGHwIPbux7Lir4Z1G/utVv7rU71zJeXkrzz\
ue7yMWJ+84qhsVdirsVdirIvJPk/UvPHmC20LThx9Q87q4IqkMCkc5G+XQDu1Bir6V/MP8mtK1Ty\
Pa6X5atxFqugxE6cduc6/akjdu7SH4gf5/Y4q+SpEeJ2ikUpIhKujChDA0IIPhiq3FXYq7FXYq7F\
XYq7FXYq7FXYq+8vIP8AyhHl3/tnWv8AyaXFWRYq7FWm+yflir88rz/eu4/4yP8A8SOKqOKuxV2K\
uxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV//9fzrirsVdirsVdirsVdirsVdirsVdir\
sVdirsVdirsVdirsVdirsVe2/wDOMn/KZap/2zW/5PxYq+p8VdirsVfOv/OUfTy1/wBHf/MrFXzr\
irsVdirsVekfln+b+s/l+/1CVDqHlyRuUlizUeJid3hY9Ce6n4W9j8WKvpTy9+bv5f8AmOJGttZh\
tLhhva3zC2lB8P3hCt/sWOKsmk8xeX4ozNLq1mkQFTI1xEFp8y2KsB82fnv5G8uwyJp90Nb1IAiO\
3sjyi5duU1OAH+ryPtir5g86+ede8+aqdT1qUcUqtpaR1EMEZP2UBrue7HdsVY1irsVdirsVVrS1\
ub65hsrOJp7u4dYoYUFWd3NAAPEnFX2j+VP5d235f+X1hlCya7ehZdTuBv8AFTaJT/Klae5q2Ks9\
xV80f85Bflp9RuG89aLDSzuWA1iFBtHMxoJgB2c7P/l7/tYq8CxV2KuxV2KuxV2KuxV2KuxV2Kvv\
LyD/AMoR5d/7Z1r/AMmlxVkWKuxVpvsn5Yq/PK8/3ruP+Mj/APEjiqjirsVdirsVdirsVdirsVdi\
rsVdirsVdirsVdirsVdirsVdirsVf//Q864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq\
7FXYq7FXtv8AzjJ/ymWqf9s1v+T8WKvqfFXYq7FXzr/zlH08tf8AR3/zKxV864q7FXYq7FXYq7FX\
Yq7FXYq7FXYq7FWwCxCqKk7ADrXFX1P+S/5Pf4Z+r+bPMID61NAGtbNl/wB5DIWqTXq5TiP8irDF\
XtmKuxVQvbK11GznsL6JZ7O5Rop4XFVdHFCD9GKvif8AM7yDdeQPMkunkNJpNzWbS7k784Sfsk/z\
J9lv+C/axVhWKuxV2KuxV2KuxV2KuxV2KvvLyD/yhHl3/tnWv/JpcVZFirsVab7J+WKvzyvP967j\
/jI//EjiqjirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVf/9HzrirsVdirsVdi\
rsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVe2/wDOMn/KZap/2zW/5PxYq+p8VdirsVfOv/OU\
fTy1/wBHf/MrFXzrirsVdirsVdirsVdirsVdirsVdirsVe6/kF+WH6Xu087a5DXS7R/9xcDjaa4Q\
/wB4Qeqoeni/+rir6fxV2KuxV2KsS/MXyPZefvLc+kXFI71KzaddEVMVwo2/2LfZYeGKviPU9Nvd\
H1C50vUoWgvrSRoZ4W6q6mh+jwOKoTFXYq7FXYq7FXYq7FXYq+8vIP8AyhHl3/tnWv8AyaXFWRYq\
7FWm+yflir88rz/eu4/4yP8A8SOKqOKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2K\
uxV//9LzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVe2/wDOMn/KZap/2zW/\
5PxYq+p8VdirsVfOv/OUfTy1/wBHf/MrFXzrirsVdirsVdirsVdirsVdirsVdirN/wAr/wAvrv8A\
MDzClnRo9GteMuqXQ24x12RT/O9KD6W7Yq+1bGytNNs4NPsYVgs7ZFighQUVEQUAGKojFXYq7FXY\
q7FXh35//lp+mrBvOmjQ11WwSmpRIN5rZB9undox96f6oxV8uYq7FXYq7FXYq7FXYq7FX3l5B/5Q\
jy7/ANs61/5NLirIsVdirTfZPyxV+eV5/vXcf8ZH/wCJHFVHFXYq7FXYq7FXYq7FXYq7FXYq7FXY\
q7FXYq7FXYq7FXYq7FXYq//T864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FX\
tv8AzjJ/ymWqf9s1v+T8WKvqfFXYq7FXzr/zlH08tf8AR3/zKxV864q7FXYq7FXYq7FXYqrSWl1D\
bw3UsEiWtzy+rzMpCSemaNwYihodjTFVHFXYqmGh6JqPmPVrTRdJiM1/eSCOJB0FerMeyqN2Phir\
7e8h+S9O8ieXrfRLEB5R+8vbqlGmuGHxOfbso7LirJsVdirsVdirsVdirRAYFWFVOxB3BBxV8gfn\
Z+Wx8l65+ldLip5b1R2aAL0t5z8TQnwH7Sf5Pw/s4q8rxV2KuxV2KuxV2KuxV95eQf8AlCPLv/bO\
tf8Ak0uKsixV2KtN9k/LFX55Xn+9dx/xkf8A4kcVUcVdirsVdirsVdirsVdirsVdirsVdirsVdir\
sVdirsVdirsVdir/AP/U864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXtv8A\
zjJ/ymWqf9s1v+T8WKvqfFXYq7FXzr/zlH08tf8AR3/zKxV864q7FXYq7FXYq7FWX/lx5EvvP/mK\
LSoOUWnxUl1K7A2igB3p25N0QfwBxV9da9+XfljXvKsflGe0WHTrWMJYPEAJLd1FFdD4/wA1ft/t\
Yq+O/O/kjWvIesvpOrJWNqvZ3iA+lPFXZlPj/MvVTirGwCSABUnYAYq+uPyP/LIeT9J/T2sQ08ya\
kgPBh8VtbncR+zN1f6F7HFXreKuxV2KuxV2KuxV2KuxVKPM/lzTfNmh3mg6qnO0u048h9qNxusi+\
DKdxir4d82+V9S8na9d6Bqi0ntm/dyAfBLE32JF9mH9MVSTFXYq7FXYq7FXYq+8vIP8AyhHl3/tn\
Wv8AyaXFWRYq7FWm+yflir88rz/eu4/4yP8A8SOKqOKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV\
2KuxV2KuxV2KuxV//9XzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVe2/wDO\
Mn/KZap/2zW/5PxYq+p8VdirsVfOv/OUfTy1/wBHf/MrFXzrirsVdirsVdiqM0rS77WtRttJ0yFr\
i/u5BFBEvUs36gOpPYYq+2vy58i2PkHy7DpNvxkv5aS6ldgUMs5G9K78V6KPD3JxVl2Ksf8AOPk3\
RfPGjS6NrMXJDVre4UD1YJaUDoT38R0YbHFXjn5afkPe6J5tudT81rHPY6TIDpQWjJcyfaWYrvQJ\
/Kf2/wDV3VfQeKuxV2KuxV2KuxV2KuxV2KuxV5l+c35bp550H65p8Y/xJpis9mR1mj6tCfn1T/K/\
1jir45dHjdkdSrqSrKwoQRsQQcVaxV2KuxV2KuxV95eQf+UI8u/9s61/5NLirIsVdirTfZPyxV+e\
V5/vXcf8ZH/4kcVUcVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir//1vOuKuxV\
2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV7b/AM4yf8plqn/bNb/k/Fir6nxV2Kux\
V86/85R9PLX/AEd/8ysVfOuKuxV2KuxV2Kvq38iPyx/w1pw8161DTXtQj/0WJx8VtbPv0PR3G7eC\
/D/Nir2fFXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXzJ/zkF+Wv6Num886LDSwu3A1aFBtFOx\
oJaD9lzs3+X/AK2KvBsVdirsVdirsVfeXkH/AJQjy7/2zrX/AJNLirIsVdirTfZPyxV+eV5/vXcf\
8ZH/AOJHFVHFXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq/wD/1/OuKuxV2Kux\
V2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV7b/AM4yf8plqn/bNb/k/Fir6nxV2KuxV86/\
85R9PLX/AEd/8ysVfOuKuxV2KuxV7P8AkR+WP+JdSHmrWoa6Dp8n+ixOPhublDXoeqJ1bxb4f5sV\
fVuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxVD39haapZXGnX8Sz2V1G0M8LiqsjihBxV8S\
/mX5Du/IHmSXTH5SaZPWbTLk/wC7ICehP8yfZb7++KsNxV2KuxV2KvvLyD/yhHl3/tnWv/JpcVZF\
irsVab7J+WKvzyvP967j/jI//EjiqjirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdi\
rsVf/9DzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVe2/wDOMn/KZap/2zW/\
5PxYq+p8VdirsVfOv/OUfTy1/wBHf/MrFXzrirsVdirLvy68i33n/wAxQ6Tb8o7GOkuo3YG0UAO9\
O3JuiDx9gcVfbWlaXYaJp1tpOmQrb2FpGsUES9Aq/rJ6k9ziqMxV2KuxV2KuxV2KuxV2KuxV2Kux\
V2KuxV2KuxV2KuxViH5keRbPz95bm0qXjHqEVZtNuiP7qcDap/lb7Lf1AxV8R6jp95pN/caZqELQ\
X1pI0M8L9VdDQjFUNirsVdir7y8g/wDKEeXf+2da/wDJpcVZFirsVab7J+WKvzyvP967j/jI/wDx\
I4qo4q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FX//0fOuKuxV2KuxV2KuxV2K\
uxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV7b/AM4yf8plqn/bNb/k/Fir6nxV2KuxV86/85R9PLX/\
AEd/8ysVfOuKuxVF6Xpl9rOo22labC1xf3cixQQr1Zm/UB1J7DFX21+XHkSx8geXYtKgpJqEtJdS\
ugN5ZyN6f5K9FH8TirL8VdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVeFf85Aflp+\
lrJvO2iw11KySmqRIN5rZOklO7Rjr/kf6uKvmDFXYq7FX3l5B/5Qjy7/ANs61/5NLirIsVdirTfZ\
PyxV+eV5/vXcf8ZH/wCJHFVHFXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq//S\
864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXtv8AzjJ/ymWqf9s1v+T8WKvq\
fFXYq7FXzr/zlH08tf8AR3/zKxV864q7FX1X+Q/5Y/4c09fNmtw01zUI/wDQ4XHxW1s4rWh6O/fw\
X4f5sVe04q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FWmVXUo4DKwoyncEHscV\
fHn51flu3kjXf0jpsZHlvVHZ7WnSCY7tCfbun+Tt+ziry/FXYq+8vIP/AChHl3/tnWv/ACaXFWRY\
q7FWm+yflir88rz/AHruP+Mj/wDEjiqjirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsV\
dirsVf/T864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXtv8AzjJ/ymWqf9s1\
v+T8WKvqfFXYq7FXzr/zlH08tf8AR3/zKxV864qzX8sm8l2nmFNV873Rj0+w4y29osTzevOD8PLi\
DRVpUg/a2HSuKvpL/lfv5Zf9XKX/AKRpv+acVd/yv38sv+rlL/0jTf8ANOKu/wCV+/ll/wBXKX/p\
Gm/5pxV3/K/fyy/6uUv/AEjTf804q7/lfv5Zf9XKX/pGm/5pxV3/ACv38sv+rlL/ANI03/NOKu/5\
X7+WX/Vyl/6Rpv8AmnFXf8r9/LL/AKuUv/SNN/zTirv+V+/ll/1cpf8ApGm/5pxV3/K/fyy/6uUv\
/SNN/wA04q7/AJX7+WX/AFcpf+kab/mnFXf8r9/LL/q5S/8ASNN/zTirv+V+/ll/1cpf+kab/mnF\
Xf8AK/fyy/6uUv8A0jTf804q7/lfv5Zf9XKX/pGm/wCacVd/yv38sv8Aq5S/9I03/NOKu/5X7+WX\
/Vyl/wCkab/mnFXf8r9/LL/q5S/9I03/ADTirv8Alfv5Zf8AVyl/6Rpv+acVd/yv38sv+rlL/wBI\
03/NOKu/5X7+WX/Vyl/6Rpv+acVd/wAr9/LL/q5S/wDSNN/zTiqT+afzY/KDzdoV3oOq6hK1rdLQ\
OLWXlHIN0kWq/aU74q+VbqOKG5migmFxAjssc6gqJFBoGAbcVG9Diqlir7y8g/8AKEeXf+2da/8A\
JpcVZFirsVab7J+WKvzyvP8Aeu4/4yP/AMSOKqOKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Ku\
xV2KuxV2KuxV/9TzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVe2/wDOMn/K\
Zap/2zW/5PxYq+p8VdirsVfOv/OUfTy1/wBHf/MrFXzrirsVdirsVdirsVdirsVdirsVdirsVdir\
sVdirsVdirsVdirsVdirsVdirsVdirsVdir7y8g/8oR5d/7Z1r/yaXFWRYq7FWm+yflir88rz/eu\
4/4yP/xI4qo4q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FX//V864q7FXYq7FX\
Yq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXtv8AzjJ/ymWqf9s1v+T8WKvqfFXYq7FXzr/z\
lH08tf8AR3/zKxV864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7F\
XYq7FXYq+8vIP/KEeXf+2da/8mlxVkWKuxVpvsn5Yq/PK8/3ruP+Mj/8SOKqOKuxV2KuxV2KuxV2\
KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV//1vOuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2K\
uxV2KuxV2KuxV7b/AM4yf8plqn/bNb/k/Fir6nxV2KuxV86/85R9PLX/AEd/8ysVfOuKuxV2KuxV\
2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KvvLyD/yhHl3/tnWv/Jp\
cVZFirsVab7J+WKvzyvP967j/jI//EjiqjirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir\
sVdirsVf/9fzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVe2/wDOMn/KZap/\
2zW/5PxYq+p8VdirsVeU/nN+WmufmH+iP0NPbQ/UPX9b6yzrX1eFOPFW/lOKvKf+haPO/wDy36d/\
yMl/6pYq7/oWjzv/AMt+nf8AIyX/AKpYq7/oWjzv/wAt+nf8jJf+qWKu/wChaPO//Lfp3/IyX/ql\
irv+haPO/wDy36d/yMl/6pYq7/oWjzv/AMt+nf8AIyX/AKpYq7/oWjzv/wAt+nf8jJf+qWKu/wCh\
aPO//Lfp3/IyX/qlirv+haPO/wDy36d/yMl/6pYq7/oWjzv/AMt+nf8AIyX/AKpYq7/oWjzv/wAt\
+nf8jJf+qWKu/wChaPO//Lfp3/IyX/qlirv+haPO/wDy36d/yMl/6pYq7/oWjzv/AMt+nf8AIyX/\
AKpYq7/oWjzv/wAt+nf8jJf+qWKu/wChaPO//Lfp3/IyX/qlirv+haPO/wDy36d/yMl/6pYq7/oW\
jzv/AMt+nf8AIyX/AKpYq7/oWjzv/wAt+nf8jJf+qWKu/wChaPO//Lfp3/IyX/qlirv+haPO/wDy\
36d/yMl/6pYq7/oWjzv/AMt+nf8AIyX/AKpYq7/oWjzv/wAt+nf8jJf+qWKu/wChaPO//Lfp3/Iy\
X/qlirv+haPO/wDy36d/yMl/6pYq7/oWjzv/AMt+nf8AIyX/AKpYq7/oWjzv/wAt+nf8jJf+qWKv\
pfyxpk+i+XdK0i5ZXuLG0ht5WjqULxIFJFQDSoxVNcVdirTfZPyxV+eV5/vXP/xkf/iRxVRxV2Ku\
xV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv//Q864q7FXYq7FXYq7FXYq7FXYq7FXY\
q7FXYq7FXYq7FXYq7FXYq7FXq/8AzjzrEemfmHFaykKuqW01orH+cUlUfSY6Yq+vcVdirsVdirsV\
dirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdiqWeY\
tVi0LQdS1iYgR2NtLPv0JRCQPpO2KvgB3Mjs56sSx+k1xVbirsVdirsVdirsVdirsVdirsVdirsV\
dirsVdirsVdirsVdirsVf//R864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FU\
Xpmo3ekaja6pYv6d5ZypPA/g8bBh9G2Kvuvyb5qsPOfl6z1/T2HC4Wk8NamKdftxt7g/eKHFU+xV\
2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2\
KuxV4J/zkb57itNOj8j6fKDeXhSfVOJ+xAp5JGad3ajf6q/5WKvmbFXYq7FXYq7FXYq7FXYq7FXY\
q7FXYq7FXYq7FXYq7FXYq7FXYq7FX//S864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq\
7FXYq7FXYqzn8s/zK1P8u9VM0Ya50S6IGoWFacgNhIldg69v5vst7KvsPy35o0PzbpqaroN2l1av\
TmBtJGxH2ZEO6sPA4qnGKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2K\
uxV2KuxV2KuxV2KuxV2KvL/zR/OLSPI9tLpumOl95pcFY7ZTyjtyf25iPDsn2j7DFXyJqOo3urX1\
xqWpTtc3105lnnkNWZ26nFUNirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir\
/9PzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVTLQ/MOt+W70ahoV9N\
YXY2MkLUDDwZejD2YEYq9f0P/nJnzLZosWu6ZbakF6zRM1rKfnQOv3KMVT4f85SW3fyy/wD0mD/q\
jirv+hpLb/qWZP8ApMH/AFRxV3/Q0lt/1LMn/SYP+qOKu/6Gktv+pZk/6TB/1RxV3/Q0lt/1LMn/\
AEmD/qjirv8AoaS2/wCpZk/6TB/1RxV3/Q0lt/1LMn/SYP8Aqjirv+hpLb/qWZP+kwf9UcVd/wBD\
SW3/AFLMn/SYP+qOKu/6Gktv+pZk/wCkwf8AVHFXf9DSW3/Usyf9Jg/6o4q7/oaS2/6lmT/pMH/V\
HFXf9DSW3/Usyf8ASYP+qOKu/wChpLb/AKlmT/pMH/VHFXf9DSW3/Usyf9Jg/wCqOKu/6Gktv+pZ\
k/6TB/1RxV3/AENJbf8AUsyf9Jg/6o4q7/oaS2/6lmT/AKTB/wBUcVd/0NJbf9SzJ/0mD/qjirv+\
hpLb/qWZP+kwf9UcVd/0NJbf9SzJ/wBJg/6o4q7/AKGktv8AqWZP+kwf9UcVd/0NJbf9SzJ/0mD/\
AKo4q7/oaS2/6lmT/pMH/VHFXf8AQ0lt/wBSzJ/0mD/qjirv+hpLb/qWZP8ApMH/AFRxV3/Q0lt/\
1LMn/SYP+qOKu/6Gktv+pZk/6TB/1RxVZL/zlJHwYQeWSJKfCXvAVr7gQ4qwPzP+fXnzzFE9rbzx\
6PZuKMlgCspB2oZWJYf7HjirzBmZ2Z3Ys7ElmJqST1JJxVrFXYq7FXYq7FXYq7FXYq7FXYq7FXYq\
7FXYq7FXYq7FXYq7FXYq7FXYq//U864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXY\
q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq\
7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq//1fOu\
KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2K\
uxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Ku\
xV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv/9bzrirsVdirsVdirsVdirsVdirsVdirsVdi\
rsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir\
sVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirs\
VdirsVdir//X864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq\
7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7\
FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq//0POuKuxV2KuxV2KuxV2K\
uxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Ku\
xV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kux\
V2KuxV2KuxV2KuxV2KuxV2Kv/9HzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir\
sVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirs\
VdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir//S864q\
7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7\
FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7F\
XYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq//0/OuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Ku\
xV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kux\
V2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV\
2KuxV2Kv/9TzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirs\
VdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsV\
dirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir//V864q7FXYq7FXYq7FXYq7\
FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7F\
XYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FX\
Yq7FXYq7FXYq7FXYq7FXYq//1vOuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kux\
V2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV\
2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv/9fzrirs\
VdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsV\
dirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVd\
irsVdirsVdirsVdirsVdirsVdirsVdirsVdir//Q864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7F\
XYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FX\
Yq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXY\
q7FXYq//0fOuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV\
2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2\
KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv/9LzrirsVdirsVdirsVdirsV\
dirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVd\
irsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdi\
rsVdirsVdirsVdirsVdir//T864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FX\
Yq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXY\
q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq//1POuKuxV\
2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2\
KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2K\
uxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv/9XzrirsVdirsVdirsVdirsVdirsVdirsVdirsVd\
irsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdi\
rsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir\
sVdir//W864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXY\
q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq\
7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq//1/OuKuxV2KuxV2KuxV2KuxV2\
KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2K\
uxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Ku\
xV2KuxV2KuxV2KuxV2Kv/9DzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdi\
rsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir\
sVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir//R864q7FXY\
q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq\
7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7\
FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq//0vOuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2K\
uxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Ku\
xV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kux\
V2Kv/9PzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir\
sVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirs\
VdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdir//U864q7FXYq7FXYq7FXYq7FXYq\
7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7\
FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7F\
XYq7FXYq7FXYq7FXYq//1fOuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Ku\
xV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kux\
V2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv/9bzrirsVdir\
sVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirs\
VdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsV\
dirsVdirsVdirsVdirsVdirsVdirsVdir//X864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7\
FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7F\
XYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FX\
Yq//0POuKuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kux\
V2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV\
2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv/9HzrirsVdirsVdirsVdirsVdirs\
VdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsV\
dirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVd\
irsVdirsVdirsVdir//S864q7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7F\
XYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FX\
Yq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq7FXYq//0/OuKuxV2Kux\
V2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV\
2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2\
KuxV2KuxV2KuxV2KuxV2KuxV2KuxV2Kv/9TzrirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsV\
dirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVd\
irsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdirsVdi\
r//Z'
            "
            height="815"
            id="svg-main-image"
            transform="matrix(0.24 0 0 0.24 0 0)"
            width="614"
          />
        </g>
      </g>
    </g>
    <g>
      <g>
        <path
          clip-rule="evenodd"
          d="M266.4,105.9v9.9h2.1h0.6h1.8h0.6h1.8h0.6h1.8h0.6h1.8h0.6h1.8
      h0.6h1.8h0.6h1.8h0.6h1.8h0.6h1.8h0.6h1.8h0.6h1.8h0.6h1.8h0.6h1.8h0.6h1.8h0.6h1.8h0.6h1.8h0.6h1.8h0.6h1.8h0.6h2.1v-9.9H266.4z
       M313.5,115.3H312v-0.9h-0.6v0.9h-1.8v-0.9H309v0.9h-1.8v-0.9h-0.6v0.9h-1.8V113h-0.6v2.3h-1.8v-0.9h-0.6v0.9h-1.8v-0.9h-0.6v0.9
      h-1.8v-0.9h-0.6v0.9h-1.8V113h-0.6v2.3h-1.8v-0.9h-0.6v0.9h-1.8v-0.9H290v0.9h-1.8v-0.9h-0.6v0.9h-1.8V113h-0.6v2.3h-1.8v-0.9
      h-0.6v0.9H281v-0.9h-0.6v0.9h-1.8v-0.9H278v0.9h-1.8V113h-0.6v2.3h-1.8v-0.9h-0.6v0.9h-1.8v-0.9h-0.6v0.9H269v-0.9h-0.6v0.9h-1.5
      v-8.9h46.6V115.3z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M270.5,111.1c0,0,0.1,0.1,0.1,0.1c0,0.2-0.2,0.3-0.3,0.3
      c-0.2,0-0.4-0.1-0.3-0.3c0-0.1,0-0.1,0.1-0.1v-2.8c0,0,0-0.1,0-0.1c0-0.2,0.2-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.2
      c0,0.1,0,0.1,0,0.1v0.8h0c0.1-0.1,0.1-0.2,0.3-0.3l0.7-0.7c0.1-0.2,0.2-0.2,0.3-0.2c0.1,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.3
      l-1,0.9l1.4,1.7c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.2,0.3-0.4,0.3c-0.2,0-0.3-0.1-0.3-0.2l-1-1.4c-0.1-0.2-0.2-0.3-0.3-0.4h0V111.1z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M275.3,109.5c0,1.2-0.7,2.1-1.6,2.1c-0.8,0-1-0.8-1-1.4
      c0-1.1,0.8-2.4,1.6-2.4C274.7,107.8,275.3,108.3,275.3,109.5 M273.1,110.1c0,0.5,0.2,1.1,0.6,1.1c0.5,0,1-0.7,1-1.7
      c0-0.9-0.2-1.3-0.6-1.3C273.7,108.2,273.1,109.2,273.1,110.1z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M276.4,111.1c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.3,0.2
      c-0.3,0-0.4-0.1-0.4-0.2c0,0,0-0.2,0.1-0.2v-1.3c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3v-1.1c0-0.1,0-0.1,0-0.2
      c0-0.1,0.2-0.2,0.4-0.2c0.1,0,0.3,0.1,0.3,0.2c0,0.1,0,0.1,0,0.2v1.1l1.3-0.1v-1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0.2-0.2,0.3-0.2
      c0.1,0,0.3,0.1,0.3,0.2c0,0.1,0,0.1,0,0.2v2.8c0,0,0,0.1,0,0.2c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.1,0.1-0.2
      v-1.3l-1.3,0.1V111.1z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M280,111.2c0,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.2-0.4,0.2
      c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0-0.1,0-0.2v-2.6l-0.5,0.1c0,0-0.1,0.1-0.2,0.1c-0.2,0-0.3-0.1-0.3-0.2c0-0.2,0.1-0.3,0.2-0.3
      c0.1,0,0.1,0,0.2,0l0.6-0.1c0.1-0.1,0.3-0.1,0.4-0.1l0.6-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.3-0.2,0.3
      c-0.1,0-0.1,0-0.2,0l-0.6,0.1V111.2z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M281.3,110.9v-2.5c-0.1-0.2,0-0.3,0.2-0.3c0.2,0,0.3,0.2,0.3,0.3
      v2.6c0,0.1,0.2,0.3,0.4,0.3c0.6,0,0.9-0.4,0.9-0.6v-2.3c0,0-0.1-0.1-0.1-0.1c0-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0.1,0.2,0.2
      c0,0,0,0.1,0,0.1v2.4c0,0.1-0.1,0.4-0.3,0.5c-0.3,0.3-0.7,0.4-1.2,0.4C281.4,111.6,281.3,111.2,281.3,110.9"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M284.4,110.9v-2.5c-0.1-0.2,0-0.3,0.2-0.3c0.2,0,0.3,0.2,0.3,0.3
      v2.6c0,0.1,0.2,0.3,0.4,0.3c0.6,0,0.9-0.4,0.9-0.6v-2.3c0,0-0.1-0.1-0.1-0.1c0-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0.1,0.2,0.2
      c0,0,0,0.1,0,0.1v2.4c0,0.1-0.1,0.4-0.3,0.5c-0.3,0.3-0.7,0.4-1.2,0.4C284.5,111.6,284.4,111.2,284.4,110.9"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M288,111.1l0.8-0.1c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.3,0.1,0.3,0.2
      c0,0.2-0.1,0.3-0.2,0.3c0,0-0.1,0-0.2,0l-0.9,0.1c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0-0.2,0.1-0.2v-2.8
      c0,0,0-0.1,0-0.1c0-0.2,0.2-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.1,0,0.1-0.1,0.1V111.1z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M290.3,111.1l0.8-0.1c0.1,0,0.2-0.1,0.2-0.1
      c0.1,0,0.3,0.1,0.3,0.2c0,0.2-0.1,0.3-0.2,0.3c0,0-0.1,0-0.2,0l-0.9,0.1c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3-0.1-0.3-0.2
      c0-0.1,0-0.2,0.1-0.2v-2.8c0,0,0-0.1,0-0.1c0-0.2,0.2-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.1,0,0.1-0.1,0.1V111.1z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M292.5,111.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.3-0.3,0.3
      c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.1,0.1-0.2v-2.6c-0.1,0-0.1-0.1-0.1-0.2c0-0.2,0.2-0.3,0.4-0.3c0.2,0,0.3,0.2,0.3,0.3
      c0,0.1,0,0.1-0.1,0.2V111.1z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M295.4,108.5c0,0.1,0,0.2-0.2,0.2c-0.2,0-0.3-0.1-0.3-0.2
      c-0.1-0.1-0.2-0.2-0.4-0.2c-0.4,0-0.9,0.4-0.9,0.9c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.3,0,0.6-0.1c0.3-0.1,0.4-0.1,0.7-0.1
      c0.4,0,0.7,0.2,0.7,0.7c0,0.3-0.1,0.6-0.2,0.8c-0.3,0.5-1.1,0.8-1.6,0.8c-0.6,0-0.9-0.5-0.9-0.7c0-0.1,0.1-0.3,0.3-0.3
      c0.2,0,0.2,0.2,0.3,0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.4,0,0.9-0.3,1.1-0.6c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.3-0.3-0.3
      c-0.2,0-0.3,0-0.6,0.1c-0.2,0.1-0.4,0.1-0.7,0.1c-0.4,0-0.6-0.2-0.6-0.7c0-0.6,0.7-1.3,1.6-1.3C295.1,108,295.4,108.3,295.4,108.5
      "
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M296.9,111.1l1.2-0.1c0,0,0.1-0.1,0.2-0.1c0.2,0,0.3,0.1,0.3,0.2
      c0,0.2-0.2,0.3-0.5,0.3l-1.2,0.1c0,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3-0.1-0.3-0.3c0-0.1,0-0.2,0.1-0.2V110c-0.1-0.1-0.1-0.2-0.1-0.2
      c0-0.1,0.1-0.2,0.1-0.2v-0.9c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.3-0.2
      c0.2,0,0.3,0.1,0.3,0.2l1-0.1c0.1,0,0.1-0.1,0.2-0.1c0.2,0,0.3,0.1,0.3,0.2c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.1,0-0.2,0l-1,0.1v0.9
      l0.8-0.1c0.1,0,0.1-0.1,0.2-0.1c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c0,0-0.1,0-0.1,0l-0.8,0.1V111.1z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M301.2,108.5c0,0.1,0,0.2-0.2,0.2c-0.2,0-0.3-0.1-0.3-0.2
      c-0.1-0.1-0.2-0.2-0.4-0.2c-0.4,0-0.9,0.4-0.9,0.9c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.3,0,0.6-0.1c0.2-0.1,0.4-0.1,0.7-0.1
      c0.4,0,0.7,0.2,0.7,0.7c0,0.3-0.1,0.6-0.2,0.8c-0.4,0.5-1.1,0.8-1.6,0.8c-0.6,0-0.9-0.5-0.9-0.7c0-0.1,0.1-0.3,0.3-0.3
      c0.2,0,0.2,0.2,0.3,0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.4,0,0.9-0.3,1.1-0.6c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.3-0.3-0.3
      c-0.2,0-0.3,0-0.6,0.1c-0.2,0.1-0.4,0.1-0.7,0.1c-0.4,0-0.6-0.2-0.6-0.7c0-0.6,0.7-1.3,1.6-1.3C300.8,108,301.2,108.3,301.2,108.5
      "
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M303.2,111.2c0,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.2-0.4,0.2
      c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0-0.1,0-0.2v-2.6l-0.5,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0-0.3-0.1-0.3-0.2c0-0.2,0.1-0.3,0.2-0.3
      c0.1,0,0.1,0,0.2,0l0.6-0.1c0.1-0.1,0.3-0.1,0.4-0.1l0.6-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.3-0.2,0.3
      c-0.1,0-0.1,0-0.2,0l-0.6,0.1V111.2z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M305,111.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.3-0.3,0.3
      c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.1,0.1-0.2v-2.6c-0.1,0-0.1-0.1-0.1-0.2c0-0.2,0.2-0.3,0.4-0.3c0.2,0,0.3,0.2,0.3,0.3
      c0,0.1,0,0.1-0.1,0.2V111.1z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M305.7,111.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3
      c0,0.2-0.1,0.3-0.3,0.3C305.8,111.6,305.7,111.5,305.7,111.3"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M307.5,111.1c0,0,0.1,0.1,0.1,0.2c0,0.2-0.2,0.2-0.4,0.2
      c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0-0.2,0.1-0.3v-1c-0.1-0.1-0.1-0.2-0.1-0.2c0-0.1,0.1-0.2,0.1-0.3v-0.8c-0.1,0-0.1-0.1-0.1-0.2
      c0-0.1,0-0.2,0.1-0.2c0-0.2,0.2-0.3,0.4-0.3c0.1,0,0.2,0.1,0.2,0.2l0.9-0.2c0.1,0,0.1-0.1,0.2-0.1c0.2,0,0.3,0.1,0.3,0.2
      c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.1,0-0.2,0l-1,0.2v0.9l0.9-0.1c0,0,0.1-0.1,0.3-0.1c0.1,0,0.2,0.2,0.2,0.3c0,0.3-0.1,0.3-0.3,0.3
      c-0.1,0-0.1,0-0.2,0l-0.9,0.1V111.1z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M310,111.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.3-0.3,0.3
      c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.1,0.1-0.2v-2.6c-0.1,0-0.1-0.1-0.1-0.2c0-0.2,0.2-0.3,0.4-0.3c0.2,0,0.3,0.2,0.3,0.3
      c0,0.1,0,0.1-0.1,0.2V111.1z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
      </g>
      <g>
        <path
          clip-rule="evenodd"
          d="M325.4,79.7l-0.3-0.1c-0.2-0.1-0.4-0.1-0.7-0.1
      c-0.2,0-0.3,0-0.4,0.1c1.3,1.1,1.5,3.9,1.5,5.3c0,1.4-0.4,4.2-1.6,5.3l-0.1,0.1c-0.1,0.1,0,0.1,0.1,0.1c0.3,0,0.5-0.1,0.9-0.1
      l0.2-0.1c0.1,0,0.2-0.1,0.3-0.1c1.2-0.5,2.2-2.4,2.2-5.3C327.4,82,326.6,80.2,325.4,79.7 M324,88.5c0.5-1.1,0.8-2.5,0.8-3.7
      c0-1.7-0.3-3.3-0.7-4.3c-0.3-0.7-0.7-1.2-1.2-1.4c-0.1,0-0.4-0.1-0.7-0.1c-0.3,0-0.7-0.1-0.9,0.1c1.4,1.1,1.7,4.2,1.7,5.6
      c0,0.6-0.1,1.5-0.2,2c-0.1,0.4-0.3,1.1-0.4,1c0,0,0,0,0-0.1l0-0.8c0-0.5,0-1,0-1.7c0-0.6-0.1-1.1-0.2-1.6c-0.1-0.7-0.3-1.4-0.5-2
      c-0.4-1.2-1-2.1-1.6-2.4c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0-0.4,0c-0.4,0-0.9,0-1.1,0.2v0.1c1.4,0.8,2.2,3.8,2.4,6.4l0.1,1
      c0,0.4,0,0.7,0,1.2c-0.1,0.8-0.1,1.1-0.2,1.6c0,0.2-0.1,0.4-0.1,0.6l-0.1,0.4l-0.1,0.2c0,0.1,0.1,0.1,0.1,0.1c0.3,0.1,0.9,0,1.2,0
      c0.3,0,0.6-0.1,0.9-0.2c0.5-0.2,0.7-0.4,1-0.7c0.2-0.2,0.4-0.5,0.5-0.8C323.9,88.8,324,88.5,324,88.5z M319.9,89.2
      c-0.2,0-0.4,0-0.6,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.2-0.1-0.1-0.3l0.1-0.3c0.3-1,0.5-2.2,0.5-3.7c0-2.8-0.8-5.3-2-5.6
      c-0.1,0-0.2,0-0.3,0l-0.4,0c-0.4,0-0.7,0.1-1,0.3c0,0,0.1,0.1,0.2,0.1c1.1,0.8,1.6,3.6,1.5,5.2c0,1.2-0.2,3.3-0.9,4.5
      c-0.1,0.2-0.3,0.5-0.5,0.7l-0.2,0.1c-0.1,0.1-0.1,0.1,0,0.1l0.2,0.1c0,0,0.1,0,0.2,0.1c0.7,0.1,1.3,0.2,2.7,0.3
      c0.3,0,0.6-0.1,0.8-0.2c0.2-0.2,0.3-0.4,0.4-1c0,0,0-0.2,0-0.3C320,89.2,320,89.2,319.9,89.2z M314.6,87.6c-0.3,0-0.6-1.1-0.6-2.6
      c0-1.5,0.2-2.6,0.6-2.6c0.3,0,0.5,1.1,0.5,2.6C315.2,86.5,315,87.6,314.6,87.6z M314.7,79.7c-1.1,0-1.9,2.4-1.9,5.2
      c0,2.8,0.8,5.2,1.9,5.2c1.1,0,1.9-2.4,1.9-5.2C316.6,82.1,315.8,79.7,314.7,79.7z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M313.2,92.4c0.1,0,0.3,0,0.4,0c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2h-0.1v0.7h-0.4V92.4z M313.6,93.3L313.6,93.3
      c0.3,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3l-0.1,0V93.3z M315.7,93.3c0,0.7-0.3,1-0.6,1c-0.4,0-0.6-0.5-0.6-1
      c0-0.5,0.2-1,0.7-1C315.6,92.3,315.7,92.9,315.7,93.3z M314.8,93.3c0,0.4,0.1,0.7,0.2,0.7c0.2,0,0.2-0.3,0.2-0.7
      c0-0.3-0.1-0.7-0.2-0.7C314.9,92.7,314.8,92.9,314.8,93.3z M315.9,93.9c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.3-0.2
      c0-0.1-0.1-0.2-0.3-0.3c-0.3-0.2-0.4-0.4-0.4-0.5c0-0.3,0.2-0.6,0.6-0.6c0.1,0,0.2,0,0.3,0.1l-0.1,0.3c-0.1,0-0.1-0.1-0.2-0.1
      c-0.2,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.3,0.3c0.3,0.2,0.4,0.4,0.4,0.6c0,0.4-0.3,0.6-0.7,0.6c-0.2,0-0.3,0-0.4-0.1L315.9,93.9z
       M317.1,92.4h0.4v2h-0.4V92.4z M318,92.7h-0.3v-0.3h1.1v0.3h-0.3v1.6H318V92.7z M319,92.4h0.4v2H319V92.4z M319.7,92.4h0.4v2h-0.4
      V92.4z M320.7,94.3l-0.4-2h0.4l0.1,0.8c0,0.2,0.1,0.5,0.1,0.7l0.1-0.7l0.1-0.8h0.4l-0.4,2H320.7z M321.7,92.4h0.4v2h-0.4V92.4z
       M322.4,93.9c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.3-0.3c-0.3-0.2-0.4-0.4-0.4-0.5c0-0.3,0.2-0.6,0.6-0.6
      c0.1,0,0.2,0,0.3,0.1l-0.1,0.3c-0.1,0-0.1-0.1-0.3-0.1c-0.2,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.3,0.3c0.3,0.2,0.4,0.4,0.4,0.6
      c0,0.4-0.3,0.6-0.7,0.6c-0.2,0-0.3,0-0.4-0.1L322.4,93.9z M324.4,93.5H324V94h0.5v0.3h-0.9v-2h0.9v0.3H324v0.5h0.4V93.5z
       M324.7,93.9c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.3-0.3c-0.3-0.2-0.4-0.4-0.4-0.5c0-0.3,0.2-0.6,0.6-0.6
      c0.1,0,0.3,0,0.3,0.1l-0.1,0.3c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.3,0.3c0.3,0.2,0.4,0.4,0.4,0.6
      c0,0.4-0.3,0.6-0.7,0.6c-0.2,0-0.3,0-0.4-0.1L324.7,93.9z M326.1,92.7h-0.3v-0.3h1.1v0.3h-0.3v1.6h-0.4V92.7z M327,92.4h0.4v2H327
      V92.4z M313.2,96.8c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.3-0.3c-0.3-0.2-0.4-0.4-0.4-0.5
      c0-0.3,0.2-0.6,0.6-0.6c0.1,0,0.2,0,0.3,0.1l-0.1,0.3c-0.1,0-0.1-0.1-0.3-0.1c-0.2,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.3,0.3
      c0.3,0.2,0.4,0.4,0.4,0.6c0,0.4-0.3,0.6-0.7,0.6c-0.2,0-0.3,0-0.4-0.1L313.2,96.8z M314.7,95.3v1.3c0,0.3,0.1,0.4,0.2,0.4
      c0.1,0,0.2-0.1,0.2-0.4v-1.3h0.4v1.2c0,0.5-0.2,0.8-0.6,0.8c-0.4,0-0.6-0.3-0.6-0.8v-1.2H314.7z M317,96.2c0,0.7-0.3,1-0.6,1
      c-0.4,0-0.6-0.5-0.6-1c0-0.5,0.2-1,0.6-1C316.8,95.2,317,95.8,317,96.2z M316.1,96.3c0,0.4,0.1,0.7,0.2,0.7c0.2,0,0.2-0.3,0.2-0.7
      c0-0.3-0.1-0.7-0.2-0.7C316.2,95.6,316.1,95.8,316.1,96.3z M318.4,96.5c0-0.2,0-0.4,0-0.7l-0.1,0.6l-0.2,0.7h-0.3l-0.2-0.7
      c0-0.2-0.1-0.4-0.1-0.6l0,0.7l0,0.7h-0.3l0.1-2h0.4l0.2,0.7c0.1,0.2,0.1,0.5,0.1,0.7c0-0.2,0.1-0.4,0.1-0.7l0.2-0.7h0.4l0.1,2
      h-0.3L318.4,96.5z M319.4,96.8l-0.1,0.5h-0.4l0.4-2h0.5l0.4,2h-0.4l-0.1-0.5H319.4z M319.7,96.5l-0.1-0.4l-0.1-0.4l-0.1,0.4
      l-0.1,0.4H319.7z M320.4,95.3h0.4v1.6h0.5v0.3h-0.9V95.3z M321.8,96.8l-0.1,0.5h-0.4l0.4-2h0.5l0.4,2h-0.4l-0.1-0.5H321.8z
       M322.2,96.5l-0.1-0.4l-0.1-0.4l-0.1,0.4l-0.1,0.4H322.2z M322.9,95.3h0.4v2h-0.4V95.3z M323.6,97.2v-2h0.3l0.3,0.8l0.2,0.6
      c0-0.2,0-0.5,0-0.9v-0.4h0.3v2h-0.3l-0.3-0.8c-0.1-0.2-0.2-0.4-0.2-0.6c0,0.2,0,0.5,0,0.9v0.4H323.6z M325.9,96.4h-0.4v0.5h0.5
      v0.3h-0.9v-2h0.9v0.3h-0.5v0.5h0.4V96.4z M326.2,97.2v-2h0.3l0.3,0.8l0.2,0.6c0-0.2,0-0.5,0-0.9v-0.4h0.3v2h-0.3l-0.3-0.8
      c-0.1-0.2-0.2-0.4-0.2-0.6c0,0.2,0,0.5,0,0.9v0.4H326.2z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M346.4,83.9c-0.3,0.2-0.5,0.3-0.6,0.3c0,0,0.2-0.2,0.4-0.5
      c0.3-0.2,0.5-0.3,0.6-0.3C346.9,83.5,346.7,83.7,346.4,83.9 M346.9,83.6L346.9,83.6L346.9,83.6l0-0.1l0,0l0,0l0,0l0,0h0l0,0l0,0h0
      l0,0l0,0l-0.1,0l-0.1,0.1l0,0l0,0l0,0l0,0l0,0l0,0l-0.1,0.1l-0.2,0.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
      l0,0l0,0l0,0l0,0l0,0l0.7,0.7l0.8-0.7L346.9,83.6z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M347.3,86.4L347.3,86.4L347.3,86.4l0,0.1l0,0l0,0.1l0,0l0,0l0,0
      l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
      l0,0l-0.2-0.2l-0.2-0.2l-0.2-0.1l-3.6,3.1l0,0l0.1,0l0.1,0l0.1,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0.1l0,0v0v0
      v0v0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0.1l-0.1,0.2l-0.1,0.2l0,0.1l0,0l0,0l0,0
      l0,0l0,0l0,0.1l0,0.1l0,0l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1v0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1l0,0.1
      l0,0.1l0,0.1l0,0.1l0,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1l0.1,0.1c0.2,0,0.5,0.1,0.7,0.1c0.2,0,0.5,0,0.7,0
      c0.9,0,1.7-0.2,2.5-0.4l0-0.1l-0.1-0.2l-0.1-0.1l0-0.1l0-0.1l0,0l0,0l0,0l0,0l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1l0.1,0
      l0.1,0l0-0.1l0,0l0,0l0.1,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0-0.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
      l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0-0.1l0,0l0-0.1v0v0v0v0l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0,0l0-0.1l0,0l0,0
      l0,0l0,0l0,0l0-0.1l0-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.1-0.1l-0.2-0.2l-0.2-0.2l-0.1-0.1l-0.1-0.1l0-0.1l-0.1-0.1l-0.1-0.1l0,0
      l0-0.1l0-0.1l0.1,0l0.1,0l0.2,0.1l0.2,0.1l0,0l0,0h0h0h0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
      l0,0l0,0l0,0l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0.1-0.2l0-0.1l0.1-0.3l0.1-0.3l0-0.2l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1
      l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1l0,0l0-0.1l0-0.1l0-0.1L347.3,86.4z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M341.6,89.3L341.6,89.3l-0.1-0.1l-0.1,0l-0.1,0l-0.1,0l0,0
      l-0.1,0l-0.1,0l0,0l0,0l0,0v0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
      l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0h0h0l0,0h0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0.1l0,0.1l2.5-2.2l0,0
      l-0.1-0.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l-0.1,0l-0.1,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0-0.1
      l0-0.1l0-0.1l0-0.1l0-0.1l0-0.1v-0.1v-0.1v-0.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0.1,0l0.1,0h0.1l0.1,0h0l0,0
      l0.1,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0v0v-0.2l0-0.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0v0l0,0l0,0
      l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0v0v0v0l0,0v0l0,0l0,0h0h0h0h0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
      l0,0l0,0l0,0l0,0l0,0v0v0l0,0l0,0l-0.2-0.2l0,0l0,0l0,0l0,0l0-0.1l0,0l0,0l0-0.1l0,0v0v0v0v0l0,0l0,0v0l0.2-0.1
      c-0.1-0.4-0.3-0.8-0.7-1c-0.2-0.1-0.4-0.2-0.5-0.2l0,0l0,0l-0.1,0l0,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0h-0.1
      h-0.1l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0l-0.1,0l0,0l-0.1,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0.1l0,0l0,0l0,0l0,0.1
      l0,0.1l0,0l0,0.1l0,0.1l0,0.1l0,0c0,0.9-0.3,2.4-0.9,3c0.3,0.1,0.6,0.1,0.9,0.1c0.1,0.4,0,0.2-0.2,0.5c-0.1,0.2-0.1,0.4-0.3,0.6
      l-0.4,0.5l-0.4,0.5l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.1,0.1l-0.3,0.5l-0.1,0.1l0,0l0,0l0,0.1l0,0l0,0l0,0
      l0,0l0,0.1l0,0l0,0l0,0l0,0v0v0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0.2,0.1l0.2,0.1l0.1,0l0,0
      L341.6,89.3L341.6,89.3z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M351.5,88.7c-0.4,4.2-4.1,7.2-8.2,6.8c-1.7-0.2-3.3-0.9-4.5-2
      l11.4-9.8C351.1,85.1,351.6,86.9,351.5,88.7 M336.4,87.3c0.4-4.2,4.1-7.2,8.2-6.8c1.9,0.2,3.6,1,4.8,2.3L338,92.7
      C336.8,91.2,336.2,89.3,336.4,87.3z M344.8,78.8c-5.1-0.5-9.6,3.3-10.1,8.4c-0.5,5.1,3.3,9.6,8.4,10.1c5.1,0.5,9.6-3.3,10.1-8.4
      C353.6,83.8,349.9,79.3,344.8,78.8z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M392.4,88.7c-0.4,4.2-4.1,7.2-8.2,6.8c-4.2-0.4-7.2-4.1-6.8-8.2
      c0.4-4.2,4.1-7.2,8.2-6.8C389.7,80.9,392.8,84.5,392.4,88.7 M385.7,78.8c-5.1-0.5-9.6,3.3-10.1,8.4c-0.5,5.1,3.3,9.6,8.4,10.1
      c5.1,0.5,9.6-3.3,10.1-8.4C394.6,83.8,390.8,79.3,385.7,78.8z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <polygon
          clip-rule="evenodd"
          fill="#FFFFFF"
          fill-rule="evenodd"
          points="381.1,86.5 379.9,86.7 379.4,84.9 381.6,84.3
      383.4,84.3 383.4,91.6 381.1,91.6     "
        />
        <path
          clip-rule="evenodd"
          d="M387.9,86.6L387.9,86.6c0-0.4-0.3-0.7-0.8-0.7
      c-0.5,0-0.8,0.3-0.8,0.7v0c0,0.4,0.3,0.7,0.8,0.7C387.6,87.3,387.9,87,387.9,86.6 M388,89.4L388,89.4c0-0.4-0.3-0.7-0.8-0.7
      c-0.5,0-0.8,0.3-0.8,0.7v0c0,0.4,0.3,0.7,0.8,0.7C387.7,90.1,388,89.7,388,89.4z M384,89.6L384,89.6c0-0.9,0.5-1.4,1.3-1.8
      c-0.5-0.3-1.1-0.8-1.1-1.6v0c0-1.2,1.2-2,2.9-2c1.7,0,2.9,0.8,2.9,2v0c0,0.8-0.5,1.3-1.1,1.6c0.7,0.3,1.3,0.8,1.3,1.7v0
      c0,1.3-1.3,2.1-3.2,2.1C385.3,91.8,384,90.9,384,89.6z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M369.3,87.8c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.6
      c0-0.3,0.3-0.6,0.6-0.6C369,87.2,369.3,87.5,369.3,87.8 M368.5,86.5C368.5,86.5,368.5,86.5,368.5,86.5
      C368.5,86.5,368.5,86.5,368.5,86.5l-0.8,0.1l-2.9,2.5l5.4,0c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0.2-2.2-1-2.5c-0.1-0.3-0.2-0.7-0.3-1
      l-0.6,0.5L368.5,86.5z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M360.1,88.4c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6
      c0.3,0,0.6,0.3,0.6,0.6C360.7,88.1,360.4,88.4,360.1,88.4 M360.5,86.5C360.5,86.5,360.5,86.5,360.5,86.5
      C360.4,86.5,360.4,86.5,360.5,86.5c0-0.2,0.7-2.6,1.2-2.8c0.5-0.2,2.6-0.2,2.7-0.2h0.5c1,0,2.1,0,2.3,0.2c0.1,0.1,0.3,0.4,0.4,0.7
      l0.5-0.5c-0.4-0.6-0.7-1-0.9-1c-0.2,0-1.5-0.1-2.9-0.1c-1.4,0-2.8,0.1-2.9,0.1c-0.3,0-1.6,1.9-1.9,3.5c-1.2,0.2-1,2.4-1,2.5
      c0,0.1,0.1,0.1,0.1,0.1l3.8,0l2.9-2.5H360.5z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M358.8,89.3c-0.1,0-0.1,0.1-0.1,0.1v0.9c0,0.1,0.1,0.1,0.1,0.1
      h0.5v0.8c0,0.1,0,0.2,0.1,0.3l2.7-2.3H358.8z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M368.2,90.4v0.8c0,0.3,0.3,0.6,0.6,0.6h0.4
      c0.3,0,0.6-0.3,0.6-0.6v-0.8h0.2c0.1,0,0.1-0.1,0.1-0.1v-0.9c0-0.1-0.1-0.1-0.1-0.1h-5.6l-1.3,1.1H368.2z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M371.9,88.7c-0.4,4.2-4.1,7.2-8.2,6.8c-1.7-0.2-3.3-0.9-4.5-2
      l11.4-9.8C371.6,85.1,372.1,86.9,371.9,88.7 M356.9,87.3c0.4-4.2,4.1-7.2,8.2-6.8c1.9,0.2,3.6,1,4.8,2.3l-11.4,9.9
      C357.3,91.2,356.7,89.3,356.9,87.3z M365.2,78.8c-5.1-0.5-9.6,3.3-10.1,8.4c-0.5,5.1,3.3,9.6,8.4,10.1c5.1,0.5,9.6-3.3,10.1-8.4
      C374.1,83.8,370.3,79.3,365.2,78.8z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M270.1,90.6c0-0.2-0.2-0.3-0.3-0.3h-0.3v0.9h0.2v-0.4h0.1
      l0.2,0.4h0.2l-0.2-0.4C270.1,90.9,270.1,90.8,270.1,90.6 M269.8,90.7h-0.1v-0.2h0.1c0.1,0,0.2,0,0.2,0.1
      C269.9,90.7,269.8,90.7,269.8,90.7z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M269.8,89.9c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9
      c0.5,0,0.9-0.4,0.9-0.9C270.7,90.3,270.3,89.9,269.8,89.9 M269.8,91.5c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
      c0.4,0,0.7,0.3,0.7,0.7C270.5,91.2,270.2,91.5,269.8,91.5z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M280.9,85.1v-5.3h-8.3v-0.6l-1.1-0.4v13.7
      c-2,0.2-3.6,1.2-3.6,2.4c0,1.3,1.8,2.4,4.1,2.4c2.3,0,4.1-1.1,4.1-2.4c0-1.2-1.5-2.2-3.6-2.4v-7.4H280.9z M276.2,79.9h4.5v1.8
      h-4.5V79.9z M276.2,83.1h4.5v1.8h-4.5V83.1z M272.6,79.9h2.2v1.8h-2.2V79.9z M272.6,83.1h2.2v1.8h-2.2V83.1z M275,94.9
      c0,0.8-1.3,1.5-3,1.5c-1.6,0-3-0.7-3-1.5c0-0.8,1.3-1.5,3-1.5C273.7,93.4,275,94,275,94.9z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M305.2,88c0-1.3-0.3-2.5-0.9-3.7c-0.5-0.9-1.1-1.8-1.9-2.5
      c-0.9-0.7-1.9-1.3-3-1.6c-0.7-0.2-1.3-0.3-2-0.3c-2.8-0.1-5,1-6.7,3.2c-1,1.3-1.6,2.8-1.6,4.5c-0.1,2.6,0.9,4.8,2.8,6.5
      c1.4,1.2,3.1,1.9,5,1.9c2.6,0.1,4.8-0.9,6.5-2.8C304.5,91.8,305.1,90,305.2,88 M296.6,78.8h0.9l0,0l0.4,0c0.2,0,0.3,0,0.4,0
      c0.3,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0.1,0.7,0.3,1,0.4c0.4,0.2,0.8,0.4,1.2,0.7c0.3,0.2,0.7,0.5,1,0.8
      c0.2,0.2,0.5,0.4,0.6,0.6c0.3,0.4,0.6,0.7,0.8,1.1c0.2,0.2,0.3,0.5,0.5,0.8c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.2,0.2,0.4,0.2,0.7
      c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0.1,0.3,0.1,0.5l0.1,0.4c0,0.2,0.1,0.4,0.1,0.5c0,0.2,0.1,0.5,0.1,0.7c0,0.4,0,0.8,0,1.2
      c0,0.3-0.1,0.6-0.1,0.9c-0.1,0.3-0.1,0.7-0.2,1c-0.1,0.4-0.3,0.8-0.4,1.2c-0.2,0.4-0.4,0.7-0.6,1.1c-0.1,0.2-0.3,0.4-0.4,0.6
      c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.2-0.5,0.4-0.7,0.6c-0.2,0.2-0.4,0.4-0.7,0.5c-0.2,0.2-0.5,0.3-0.7,0.4c-0.3,0.2-0.6,0.3-0.9,0.5
      c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0.2-1,0.3c-0.2,0.1-0.5,0.1-0.7,0.1c-0.3,0-0.7,0.1-1,0.1c-0.3,0-0.5,0-0.8,0
      c-0.3,0-0.5,0-0.8-0.1c-0.3,0-0.6-0.1-0.9-0.2c-0.2,0-0.4-0.1-0.6-0.2c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.2-0.7-0.3-1-0.5
      c-0.3-0.1-0.5-0.3-0.7-0.5c-0.3-0.2-0.5-0.4-0.8-0.6c-0.2-0.1-0.3-0.3-0.4-0.4c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1-0.2-0.3-0.3-0.4-0.5
      c-0.2-0.2-0.3-0.5-0.5-0.8c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.2-0.2-0.4-0.3-0.7c0-0.1-0.1-0.3-0.1-0.4
      L288,90l-0.1-0.4c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0-0.4,0-0.6l0,0v-1l0-0.4c0-0.2,0-0.3,0.1-0.5c0-0.2,0.1-0.4,0.1-0.6
      c0.1-0.2,0.1-0.5,0.2-0.7c0.1-0.3,0.2-0.7,0.4-1c0.2-0.4,0.3-0.7,0.5-1c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.3,0.4-0.5,0.7-0.8
      c0.1-0.2,0.3-0.3,0.4-0.4c0.2-0.2,0.3-0.3,0.5-0.5c0.2-0.2,0.5-0.4,0.7-0.5c0.5-0.3,1-0.6,1.5-0.8c0.3-0.1,0.7-0.3,1-0.4
      c0.2-0.1,0.4-0.1,0.6-0.1l0.3-0.1c0.2,0,0.4-0.1,0.6-0.1C296.1,78.8,296.3,78.8,296.6,78.8L296.6,78.8z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M298.2,94.7c1.3-0.2,2.5-0.8,3.4-1.7c1.4-1.3,2.1-2.9,2.1-4.8
      c-1.5-0.1-2.8,0.3-3.9,1.1c-1.2,0.8-1.9,2-2.4,3.3l0,0c0-1.4-0.1-2.7,0-4.1c0-0.2,0.1-0.4,0.2-0.6c0.1-0.3,0.4-0.5,0.6-0.7
      c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.4-0.4,0.5-0.7c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3,0.1-0.5h-0.9c-0.1,0-0.1,0-0.1-0.1v-1.6
      c0-0.1,0-0.1,0-0.2c-0.1-0.2-0.2-0.2-0.4-0.2c-0.2,0-0.3,0.2-0.3,0.3c0,0.6,0,1.1,0,1.7c0,0.1,0,0.1-0.1,0.1h-1.2h-0.2V85v-1.7
      c0-0.1-0.1-0.3-0.2-0.3c-0.1,0-0.3,0-0.4,0.1c0,0.1-0.1,0.1-0.1,0.2V85c0,0.1,0,0.1-0.1,0.1h-0.5h-0.4c0,0.1,0,0.2,0,0.4
      c0,0.2,0.1,0.4,0.2,0.5c0.1,0.3,0.3,0.6,0.5,0.8c0.2,0.2,0.5,0.4,0.7,0.5c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.3,0.2,0.6,0.2,0.9
      c0,1.2,0,2.4,0,3.6v0.1l0,0c-0.4-1.4-1.2-2.5-2.4-3.3c-1.2-0.9-2.5-1.2-3.9-1.1c0,1.1,0.3,2.2,0.8,3.1c0.7,1.2,1.7,2.2,2.9,2.8
      C295.4,94.8,296.8,95,298.2,94.7"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
      </g>
      <path
        clip-rule="evenodd"
        d="M266.6,190.9v-0.8h0.7c0.5,0,0.9-0.4,0.9-0.9
    c0-0.5-0.4-0.9-0.9-0.9h-1.3v2.6H266.6z M266.6,189h0.7c0.1,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.3-0.3,0.3h-0.7V189z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M268.7,190.5h0.8l0.2,0.4h0.7l-1-2.6h-0.7l-0.9,2.6h0.7
    L268.7,190.5z M269.3,189.8h-0.4l0.2-0.6L269.3,189.8z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M271.1,190.9v-0.9h0.3l0.5,0.9h0.8l-0.6-0.9
    c0.3-0.1,0.5-0.4,0.5-0.8c0-0.5-0.4-0.9-0.9-0.9h-1.4v2.6H271.1z M271.1,189h0.7c0.1,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.3,0.2h-0.7
    V189z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M273.5,190.5h0.8l0.2,0.4h0.7l-1-2.6h-0.7l-0.9,2.6h0.7
    L273.5,190.5z M274.1,189.8h-0.4l0.2-0.6L274.1,189.8z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M276.1,191c0.6,0,1-0.4,1-1c0-0.1,0-0.2,0-0.3l-1.3-0.5
    c0-0.2,0.2-0.3,0.4-0.3c0.2,0,0.3,0.1,0.4,0.3l0.6-0.1c-0.1-0.4-0.5-0.8-1-0.8c-0.6,0-1,0.5-1,1l0,0.2l1.4,0.5
    c0,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.3-0.1-0.4-0.3l-0.6,0.1C275.2,190.6,275.5,191,276.1,191"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="278.3,190.9 278.3,189 279,189 279,188.3 277,188.3
    277,189 277.7,189 277.7,190.9   "
      />
      <path
        clip-rule="evenodd"
        d="M279.4,190.5h0.8l0.2,0.4h0.7l-1-2.6h-0.7l-0.9,2.6h0.7
    L279.4,190.5z M280,189.8h-0.4l0.2-0.6L280,189.8z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="284,190.9 284,190.3 282.6,190.3 282.6,189.9
    283.7,189.9 283.7,189.3 282.6,189.3 282.6,189 284,189 284,188.3 281.9,188.3 281.9,190.9   "
      />
      <path
        clip-rule="evenodd"
        d="M284.8,190.9v-1.2l1.4,1.3v-2.6h-0.6v1.2
    c-0.1-0.1-1.3-1.2-1.4-1.3v2.6H284.8z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M287.2,190.9v-1.2l1.4,1.3v-2.6h-0.6v1.2
    c-0.1-0.1-1.3-1.2-1.4-1.3v2.6H287.2z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="290.9,190.9 290.9,190.3 289.5,190.3 289.5,189.9
    290.6,189.9 290.6,189.3 289.5,189.3 289.5,189 290.9,189 290.9,188.3 288.9,188.3 288.9,190.9   "
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="291.8,190.9 291.8,189.7 293.2,191 293.2,188.3
    292.5,188.3 292.5,189.5 291.1,188.3 291.1,190.9   "
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="294.6,191 295.6,188.3 295,188.3 294.1,191   "
      />
      <path
        clip-rule="evenodd"
        d="M298.6,189c0-0.4-0.3-0.6-0.7-0.6h-1.5v2.6h1.5
    c0.5,0,0.8-0.3,0.8-0.8c0-0.4-0.2-0.6-0.4-0.6C298.5,189.4,298.6,189.2,298.6,189 M297.1,189.9h0.7c0.1,0,0.2,0.1,0.2,0.2
    c0,0.1-0.1,0.2-0.2,0.2h-0.7V189.9z M297.1,189h0.7c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2h-0.7V189z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M299.5,190.5h0.8l0.2,0.4h0.7l-1-2.6h-0.7l-0.9,2.6h0.7
    L299.5,190.5z M298.8,188.3c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3C298.9,188,298.8,188.2,298.8,188.3
    z M300.5,188.3c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3C300.6,188,300.5,188.2,300.5,188.3z
     M300.1,189.8h-0.4l0.2-0.6L300.1,189.8z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M302.1,191c0.6,0,1-0.4,1-1c0-0.1,0-0.2,0-0.3l-1.3-0.5
    c0-0.2,0.2-0.3,0.4-0.3c0.2,0,0.3,0.1,0.4,0.3l0.6-0.1c-0.1-0.4-0.5-0.8-1-0.8c-0.6,0-1,0.5-1,1l0,0.2l1.4,0.5
    c0,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.3-0.1-0.4-0.3l-0.6,0.1C301.3,190.6,301.6,191,302.1,191"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="304.4,190.9 304.4,189 305,189 305,188.3 303.1,188.3
    303.1,189 303.7,189 303.7,190.9   "
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="306.5,190.9 306.5,190 307.5,190 307.5,189.3
    306.5,189.3 306.5,189 307.9,189 307.9,188.3 305.8,188.3 305.8,190.9   "
      />
      <path
        clip-rule="evenodd"
        d="M309.1,191c0.7,0,1.3-0.6,1.3-1.4c0-0.8-0.6-1.4-1.3-1.4
    c-0.7,0-1.3,0.6-1.3,1.4C307.8,190.4,308.4,191,309.1,191 M307.8,188.2c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3
    c0-0.2-0.1-0.3-0.3-0.3C308,187.9,307.8,188,307.8,188.2z M309.8,188.2c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3
    c0-0.2-0.1-0.3-0.3-0.3C310,187.9,309.8,188,309.8,188.2z M309.1,188.9c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7
    c-0.4,0-0.7-0.3-0.7-0.7C308.4,189.2,308.7,188.9,309.1,188.9z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M311.3,190.9v-0.9h0.3l0.5,0.9h0.8l-0.6-0.9
    c0.3-0.1,0.5-0.4,0.5-0.8c0-0.5-0.4-0.9-0.9-0.9h-1.4v2.6H311.3z M311.3,189h0.7c0.1,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.3,0.2h-0.7
    V189z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="315.1,190.9 315.1,190.3 313.7,190.3 313.7,189.9
    314.8,189.9 314.8,189.3 313.7,189.3 313.7,189 315.1,189 315.1,188.3 313.1,188.3 313.1,190.9   "
      />
      <path
        clip-rule="evenodd"
        d="M315.8,190.3h-0.6v0.6h0.6V190.3z M315.8,189.1h-0.6v0.6h0.6
    V189.1z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <g>
        <path
          clip-rule="evenodd"
          d="M290,169.3c0.3,0.4,0.6,0.8,0.8,1.3c0.2,0.4,0.4,0.9,0.6,1.3
      c0.2,0.4,0.3,0.9,0.3,1.3c0.1,0.5,0.1,0.9,0.1,1.4c0,0.5,0,0.9-0.1,1.4c-0.1,0.5-0.2,0.9-0.4,1.4c-0.2,0.5-0.4,0.9-0.6,1.4
      c-0.3,0.4-0.6,0.9-0.9,1.3c-0.3,0.3-0.6,0.6-0.9,1c-0.3,0.3-0.6,0.6-1,0.9c-0.4,0.3-0.7,0.6-1.1,0.8c-0.4,0.3-0.8,0.5-1.2,0.7
      c-0.4,0.2-0.9,0.4-1.3,0.6c-0.5,0.2-0.9,0.4-1.4,0.5c-0.5,0.2-1,0.3-1.5,0.4c-0.5,0.1-1,0.2-1.6,0.3l-0.4,0.1l-0.4,0.1l-0.4,0
      l-0.4,0l-0.4,0l-0.4,0l-0.4,0l-0.4,0c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8-0.1-1.2-0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.1-0.8-0.1-1.1-0.2
      c-0.4-0.1-0.7-0.2-1.1-0.3c-0.4-0.1-0.7-0.2-1.1-0.3c-0.4-0.1-0.7-0.3-1-0.4c-0.3-0.1-0.7-0.3-1-0.5l-0.1,0l-0.1,0l-0.1,0.1
      l-0.1,0.1l-0.9,1l-0.9,1l-1.4-2.9l-1.4-2.9l4.2,0l4.2,0l-0.9,0.9l-0.9,0.9l-0.1,0.1l-0.1,0.1l0.1,0l0.1,0c0.3,0.1,0.5,0.3,0.8,0.4
      c0.3,0.1,0.6,0.2,0.9,0.3l0.9,0.3c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0.1,0.6,0.1,0.9,0.2c0.3,0.1,0.6,0.1,1,0.1c0.3,0,0.6,0.1,1,0.1
      c0.3,0,0.6,0,1,0h0.4l0.4,0l0.4,0l0.4,0l0.4,0l0.4,0l0.4,0l0.4-0.1c0.4-0.1,0.9-0.2,1.3-0.3c0.4-0.1,0.8-0.2,1.2-0.3
      c0.4-0.1,0.8-0.3,1.2-0.4c0.4-0.2,0.7-0.3,1.1-0.5c0.4-0.2,0.7-0.4,1-0.6c0.3-0.2,0.6-0.4,0.9-0.7c0.3-0.2,0.6-0.5,0.8-0.7
      c0.3-0.2,0.5-0.5,0.7-0.8c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.3-0.6
      c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.1-0.7c0-0.2,0-0.4,0.1-0.7c0-0.2,0-0.4,0-0.7c0-0.2,0-0.4-0.1-0.6
      c0-0.2-0.1-0.4-0.1-0.6c0-0.2-0.1-0.4-0.2-0.6l-0.2-0.6l-0.3-0.6l-0.3-0.5l-0.4-0.5l-0.4-0.5l1.4,0H290z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M263.4,178.6l1.5,0l-0.3-0.4l-0.2-0.4l-0.2-0.4l-0.2-0.4
      l-0.2-0.4l-0.1-0.4l-0.1-0.4l-0.1-0.4c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.3,0-0.6,0-0.9c0-0.3,0.1-0.6,0.1-0.9c0.1-0.3,0.1-0.6,0.2-0.9
      c0.1-0.3,0.2-0.6,0.4-0.8c0.1-0.3,0.3-0.5,0.5-0.8c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.3,0.4-0.5,0.7-0.7c0.2-0.2,0.5-0.5,0.8-0.7
      c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.3,1-0.5c0.3-0.2,0.7-0.3,1-0.4c0.4-0.1,0.7-0.2,1.1-0.4
      c0.4-0.1,0.8-0.2,1.1-0.3c0.4-0.1,0.8-0.1,1.2-0.2l0.3,0l0.3,0l0.3,0l0.3,0l0.3,0l0.3,0l0.3,0l0.3,0l0.9,0l0.9,0.1
      c0.3,0,0.6,0.1,0.9,0.1c0.3,0,0.6,0.1,0.9,0.1l0.8,0.2c0.3,0.1,0.6,0.1,0.8,0.2c0.3,0.1,0.5,0.2,0.8,0.3l0.8,0.3l0,0l0,0l0,0l0,0
      l0,0l0,0l0,0l0,0l0,0l0,0l0.1,0l0.1,0l0,0.1l0,0l-0.8,0.9l-0.8,0.9l3.9,0l3.9,0l-1.7-2.3l-1.7-2.4l-0.7,0.7l-0.7,0.7l0,0l0,0.1
      l-0.1,0l-0.1,0l0,0l0,0l0,0l0,0h0l0,0l0,0l0,0h0l0,0c-0.3-0.1-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.2-1-0.3c-0.3-0.1-0.6-0.2-1-0.3
      c-0.3-0.1-0.7-0.2-1-0.2l-1-0.2l-1-0.1l-1-0.1c-0.3,0-0.7,0-1.1,0l-0.4,0l-0.4,0l-0.4,0l-0.4,0l-0.4,0l-0.4,0l-0.4,0l-0.4,0
      c-0.5,0.1-0.9,0.1-1.4,0.2c-0.5,0.1-0.9,0.2-1.4,0.3c-0.4,0.1-0.9,0.3-1.3,0.4c-0.4,0.2-0.8,0.3-1.2,0.5c-0.4,0.2-0.8,0.4-1.2,0.6
      c-0.4,0.2-0.7,0.4-1.1,0.7c-0.4,0.2-0.7,0.5-1,0.7c-0.3,0.3-0.6,0.5-0.9,0.8c-0.3,0.3-0.6,0.6-0.8,0.9c-0.3,0.3-0.5,0.6-0.7,0.9
      c-0.2,0.3-0.4,0.6-0.6,1c-0.2,0.3-0.3,0.7-0.5,1c-0.1,0.3-0.2,0.7-0.3,1c-0.1,0.3-0.1,0.7-0.2,1.1c0,0.4,0,0.7,0,1.1
      c0,0.4,0.1,0.7,0.1,1.1l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.1,0.3l0.2,0.3l0.2,0.3H263.4z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M270.4,167.5c0.1,0,0.2,0,0.3,0l0.3,0c0.1,0,0.2,0,0.4,0
      c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3
      c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0l-0.1,0l-0.1,0.5l-0.1,0.5h-0.2H270l0.2-1.2L270.4,167.5z M270.6,168.6
      l0.1,0l0.1,0c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1l-0.1,0l-0.1,0
      l-0.1,0.4L270.6,168.6z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M273.2,169.9l0-0.2l0-0.2h0h0c-0.1,0.2-0.2,0.3-0.3,0.4
      c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.6-0.4,1-0.4
      c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0.1l-0.1,0.5l-0.1,0.5l-0.1,0.4c0,0.1,0,0.3,0,0.4h-0.2H273.2z M273.4,168.4L273.4,168.4
      l-0.2,0c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.1,0,0.2,0.1,0.2c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2-0.1,0.3-0.2
      c0.1-0.1,0.2-0.3,0.2-0.5l0-0.2L273.4,168.4z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M274.1,169.9l0.1-0.6l0.1-0.6l0.1-0.3l0-0.3h0.2h0.2l0,0.2l0,0.2
      h0c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.4l0,0.1l0,0.2l-0.1,0.5l-0.1,0.5
      h-0.2h-0.2l0.1-0.5l0.1-0.5l0-0.1l0-0.1c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.3-0.2,0.5
      l-0.1,0.4l-0.1,0.4h-0.2H274.1z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M277.2,167.6l0,0.2l0,0.2h0.2h0.2l0,0.2l0,0.2h-0.2h-0.2
      l-0.1,0.4l-0.1,0.4l0,0.1l0,0.1c0,0.1,0,0.1,0,0.1c0,0,0.1,0,0.1,0h0.1l0.1,0l0,0.2l0,0.2c0,0-0.1,0-0.1,0l-0.2,0
      c-0.2,0-0.3,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3l0-0.2l0-0.2l0.1-0.4l0.1-0.4h-0.1h-0.1l0-0.2l0-0.2h0.1h0.1l0-0.2l0-0.2l0.2-0.1
      L277.2,167.6z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M278.6,167.6l0,0.2l0,0.2h0.2h0.2l0,0.2l0,0.2h-0.2h-0.2
      l-0.1,0.4l-0.1,0.4l0,0.1l0,0.1c0,0.1,0,0.1,0,0.1c0,0,0.1,0,0.1,0h0.1l0.1,0l0,0.2l0,0.2l-0.1,0l-0.2,0c-0.2,0-0.3,0-0.3-0.1
      c-0.1-0.1-0.1-0.2-0.1-0.3l0-0.2l0-0.2l0.1-0.4l0.1-0.4h-0.1h-0.1l0-0.2l0-0.2h0.1h0.1l0-0.2l0-0.2l0.2-0.1L278.6,167.6z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M278.9,169.9l0.2-0.9l0.2-0.9h0.2h0.2l-0.2,0.9l-0.2,0.9h-0.2
      H278.9z M279.6,167.8c-0.1,0-0.1,0-0.2-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0.1-0.2c0,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0.1
      c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1-0.1,0.2C279.7,167.8,279.7,167.8,279.6,167.8L279.6,167.8z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M274.2,178.6c0.1,0,0.2,0,0.3,0l0.3,0c0.1,0,0.2,0,0.4,0
      c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.2,0.3
      c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0l-0.1,0l-0.1,0.5l-0.1,0.5H274h-0.2l0.2-1.2L274.2,178.6z M274.4,179.7
      l0.1,0l0.1,0c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1l-0.1,0l-0.1,0
      l-0.1,0.4L274.4,179.7z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M277,181.1l0-0.2l0-0.2h0h0c-0.1,0.2-0.2,0.3-0.3,0.3
      c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.3,0.1-0.6,0.3-0.9c0.2-0.2,0.6-0.4,1-0.4
      c0.1,0,0.2,0,0.3,0l0.3,0.1l-0.1,0.5l-0.1,0.5l-0.1,0.4l0,0.4h-0.2H277z M277.2,179.6L277.2,179.6l-0.2,0c-0.2,0-0.4,0.1-0.5,0.3
      c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.1,0,0.2,0.1,0.2c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.3,0.2-0.5l0-0.2
      L277.2,179.6z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M277.9,181.1l0.1-0.6l0.1-0.6l0.1-0.3l0-0.3h0.2h0.2l0,0.2l0,0.2
      h0c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.4l0,0.1l0,0.2l-0.1,0.5l-0.1,0.5
      h-0.2h-0.2l0.1-0.5l0.1-0.5l0-0.1l0-0.1c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.3-0.2,0.5
      l-0.1,0.4l-0.1,0.4h-0.2H277.9z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M281.1,178.8l0,0.2l0,0.2h0.2h0.2l0,0.2l0,0.2h-0.2h-0.2
      l-0.1,0.4l-0.1,0.4l0,0.1l0,0.1c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0l0.1,0l0.1,0l0,0.2l0,0.2l-0.1,0l-0.2,0c-0.2,0-0.3,0-0.3-0.1
      c-0.1-0.1-0.1-0.2-0.1-0.3l0-0.2l0-0.2l0.1-0.4l0.1-0.4h-0.1h-0.1l0-0.2l0-0.2h0.1h0.1l0-0.2l0-0.2l0.2-0.1L281.1,178.8z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M270.1,173.6c0,0.9-0.2,1.8-0.6,2.6c-0.4,0.7-1.1,1.3-1.9,1.3
      c-0.6,0-1-0.2-1.2-0.6c-0.3-0.4-0.4-0.9-0.4-1.4c0-0.9,0.2-1.8,0.7-2.5c0.4-0.7,1.1-1.2,1.9-1.2c0.6,0,1,0.2,1.2,0.6
      C270,172.6,270.1,173.1,270.1,173.6 M267.1,175.4c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.5,0.3c0.4,0,0.7-0.5,0.9-1.1
      c0.2-0.6,0.3-1.3,0.3-1.7c0-0.2,0-0.4-0.1-0.6c-0.1-0.2-0.2-0.3-0.4-0.3c-0.4,0-0.7,0.5-0.9,1.1
      C267.2,174.3,267.1,175,267.1,175.4z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M269.7,178.3c0.2-0.4,0.4-0.8,0.5-1.2c0.2-0.4,0.3-0.8,0.4-1.2
      l0.7,0l0.7,0c-0.2,0.4-0.4,0.9-0.7,1.3c-0.2,0.4-0.5,0.8-0.7,1.1l-0.4,0L269.7,178.3z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M285.8,177.1c-0.1,0.1-0.3,0.1-0.6,0.2c-0.2,0-0.5,0.1-0.8,0.1
      c-0.7,0-1.2-0.2-1.6-0.6c-0.4-0.4-0.6-0.9-0.6-1.5H282h-0.3l0.1-0.3l0.1-0.3h0.3h0.3l0-0.2l0-0.2h-0.3h-0.3l0.1-0.3l0.1-0.3h0.3
      h0.3c0.2-0.6,0.6-1.1,1.1-1.4c0.5-0.4,1.1-0.6,1.7-0.6c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.1,0.5,0.2l-0.2,0.5l-0.2,0.5
      c-0.1,0-0.2-0.1-0.4-0.1c-0.2,0-0.3-0.1-0.6-0.1c-0.3,0-0.7,0.1-0.9,0.3c-0.3,0.2-0.5,0.4-0.6,0.7h1h1l-0.1,0.3l-0.1,0.3h-1h-1
      l0,0.2l0,0.2h1h1l-0.1,0.3l-0.1,0.3h-1h-1c0,0.3,0.2,0.6,0.4,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.2,0,0.4,0,0.6-0.1
      c0.2,0,0.3-0.1,0.4-0.1l0.1,0.5L285.8,177.1z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <polygon
          clip-rule="evenodd"
          fill="#FFFFFF"
          fill-rule="evenodd"
          points="274.7,172.9 274.7,172.9 273.6,173.4 273.6,172.4
      275.1,171.7 276.1,171.7 275.1,177.3 273.9,177.3     "
        />
        <path
          clip-rule="evenodd"
          d="M281.6,173.5c0,1.7-0.8,3.9-2.6,3.9c-1.2,0-1.6-0.9-1.6-2
      c0-1.8,0.9-3.8,2.6-3.8C281.2,171.6,281.6,172.5,281.6,173.5 M278.5,175.4c0,0.4,0.1,1,0.6,1c0.9,0,1.2-2.1,1.2-2.9
      c0-0.4,0-1-0.5-1C279,172.6,278.5,174.6,278.5,175.4z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
      </g>
      <g>
        <path
          d="M267.9,58c0,0,0.1,0,0.1,0s0.1,0.1,0.1,0.1l1.2,3.6h-0.5l-1-3.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1h-0.2
      c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2l-1,3.1h-0.5l1.2-3.6c0,0,0-0.1,0.1-0.1s0.1,0,0.1,0H267.9z M268.6,60.2v0.4h-1.9v-0.4H268.6z"
          fill="#FFFFFF"
        />
        <path
          d="M270,58c0.1,0,0.1,0,0.1,0.1v0.3c0,0.1,0,0.1-0.1,0.1h-0.2c-0.1,0-0.1,0-0.1-0.1v-0.3c0-0.1,0-0.1,0.1-0.1
      H270z M270.1,59.1v2.6h-0.5v-2.6H270.1z"
          fill="#FFFFFF"
        />
        <path
          d="M271.3,59.1l0,0.5l0,0.1v2h-0.5v-2.6H271.3z M272.5,59.1c0.5,0,0.7,0.2,0.7,0.7v2h-0.5v-1.8
      c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.2-0.6,0.3l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4
      C272.1,59.1,272.3,59.1,272.5,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M275,59.1c0.4,0,0.7,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.2-0.5,0.2
      H274v-0.4h1.4c0.1,0,0.2,0,0.2-0.1s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1
      c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.3-0.1,0.6c0,0.3,0,0.5,0.1,0.6s0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5,0
      c0.2,0,0.3,0,0.5,0l0,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0s-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4
      c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.4-0.4C274.5,59.1,274.7,59.1,275,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M277.6,59.1c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0s0.2,0,0.4,0l0,0.3c-0.2,0-0.3,0-0.5,0
      c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.4,0s-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0,0.2,0.1,0.3,0.1l0.8,0.2
      c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.4,0.1-0.6,0.1
      c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4,0-0.6-0.1l0-0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0c0.2,0,0.4,0,0.5,0
      c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1l-0.8-0.2c-0.1,0-0.3-0.1-0.3-0.1
      c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.2S277.4,59.1,277.6,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M280.5,59.1c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.5,0.1,0.8c0,0.3,0,0.6-0.1,0.8
      s-0.2,0.4-0.4,0.4s-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8
      c0.1-0.2,0.2-0.3,0.4-0.4C279.9,59.1,280.2,59.1,280.5,59.1z M280.5,59.5c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.2,0.3
      c0,0.1-0.1,0.3-0.1,0.6c0,0.3,0,0.5,0.1,0.6c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5-0.1
      c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0.1-0.3,0.1-0.6c0-0.3,0-0.5-0.1-0.6c0-0.1-0.1-0.2-0.2-0.3C280.8,59.5,280.7,59.5,280.5,59.5z"
          fill="#FFFFFF"
        />
        <path
          d="M283.2,59.1c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0s0.2,0,0.4,0l0,0.3c-0.2,0-0.3,0-0.5,0
      c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.4,0s-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0,0.2,0.1,0.3,0.1l0.8,0.2
      c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.4,0.1-0.6,0.1
      c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4,0-0.6-0.1l0-0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0c0.2,0,0.4,0,0.5,0
      c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1l-0.8-0.2c-0.1,0-0.3-0.1-0.3-0.1
      c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.2S283,59.1,283.2,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M286.9,60.2l0,0.3l-1.3,0c-0.1,0-0.2,0-0.3,0.1c0,0.1-0.1,0.2-0.1,0.3V61c0,0.1,0,0.2,0.1,0.3
      c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.4-0.3v0.3c0,0.1-0.1,0.1-0.2,0.2
      c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1s-0.2-0.1-0.3-0.2
      c-0.1-0.1-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H286.9z M286.2,59.1c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.9h-0.4l-0.1-0.6l0-0.1v-1.2c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l0-0.3c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3,0,0.4,0C285.9,59.1,286.1,59.1,286.2,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M289.3,59.1v0.4h-1.8v-0.3l0.5,0H289.3z M288.5,58.4v2.7c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.3,0.1h0.4
      l0.1,0.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2s-0.2-0.3-0.2-0.5v-2.7H288.5z"
          fill="#FFFFFF"
        />
        <path
          d="M290,59.4c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1v0.3c0,0.1,0,0.1,0,0.1c0,0-0.1,0-0.1,0h-0.2
      c-0.1,0-0.1,0-0.1,0s0-0.1,0-0.1v-0.3c0-0.1,0-0.1,0-0.1s0.1,0,0.1,0H290z M290,61.1c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1v0.3
      c0,0.1,0,0.1,0,0.1c0,0-0.1,0-0.1,0h-0.2c-0.1,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1v-0.3c0-0.1,0-0.1,0-0.1c0,0,0.1,0,0.1,0H290z"
          fill="#FFFFFF"
        />
        <path
          d="M294.2,59.1l-1,2.5c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0h-0.4c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1l-1-2.5h0.5
      l0.6,1.8c0,0.1,0.1,0.2,0.1,0.2s0.1,0.2,0.1,0.2h0.1c0-0.1,0.1-0.2,0.1-0.2s0.1-0.2,0.1-0.2l0.6-1.8H294.2z"
          fill="#FFFFFF"
        />
        <path
          d="M295.7,59.1c0.4,0,0.7,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.2-0.5,0.2
      h-1.5v-0.4h1.4c0.1,0,0.2,0,0.2-0.1s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1
      c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.3-0.1,0.6c0,0.3,0,0.5,0.1,0.6s0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5,0
      c0.2,0,0.3,0,0.5,0l0,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0s-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4
      c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.4-0.4C295.2,59.1,295.4,59.1,295.7,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M298.3,59.1c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0s0.2,0,0.4,0l0,0.3c-0.2,0-0.3,0-0.5,0
      c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.4,0s-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0,0.2,0.1,0.3,0.1l0.8,0.2
      c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.4,0.1-0.6,0.1
      c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4,0-0.6-0.1l0-0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0c0.2,0,0.4,0,0.5,0
      c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1l-0.8-0.2c-0.1,0-0.3-0.1-0.3-0.1
      c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.2S298,59.1,298.3,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M300.4,58c0.1,0,0.1,0,0.1,0.1v0.3c0,0.1,0,0.1-0.1,0.1h-0.2c-0.1,0-0.1,0-0.1-0.1v-0.3
      c0-0.1,0-0.1,0.1-0.1H300.4z M300.5,59.1v2.6h-0.5v-2.6H300.5z"
          fill="#FFFFFF"
        />
        <path
          d="M301.5,61.1c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1v0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.3
      c0,0.1-0.1,0.2-0.1,0.3l-0.2-0.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c-0.1,0-0.1-0.1-0.1-0.2v-0.3c0-0.1,0-0.1,0-0.1
      c0,0,0.1,0,0.1,0H301.5z"
          fill="#FFFFFF"
        />
        <path
          d="M304.7,59c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.5,0.1,0.8c0,0.3,0,0.6-0.1,0.8
      c-0.1,0.2-0.2,0.4-0.4,0.4c-0.2,0.1-0.5,0.1-0.8,0.1c-0.3,0-0.6,0-0.8-0.1s-0.4-0.2-0.4-0.4s-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8
      s0.2-0.4,0.4-0.4S304.3,59,304.7,59z M304.7,59.6c-0.1,0-0.2,0-0.3,0.1s-0.1,0.1-0.2,0.2c0,0.1,0,0.3,0,0.5c0,0.2,0,0.4,0,0.5
      s0.1,0.2,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0-0.3,0-0.5c0-0.2,0-0.4,0-0.5c0-0.1-0.1-0.2-0.2-0.2
      S304.8,59.6,304.7,59.6z"
          fill="#FFFFFF"
        />
        <path
          d="M307.3,58l0,0.9c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2-0.1,0.3l0.1,0.1v2.1h-0.8V58H307.3z M308.3,59
      c0.5,0,0.8,0.2,0.8,0.7v2h-0.8V60c0-0.1,0-0.2-0.1-0.2c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.3,0.1-0.5,0.2l-0.1-0.4
      c0.2-0.2,0.4-0.3,0.6-0.4C307.9,59.1,308.1,59,308.3,59z"
          fill="#FFFFFF"
        />
        <path
          d="M310.2,59.1l0.1,0.5l0.1,0.1v2.1h-0.8v-2.7H310.2z M311.5,59l-0.1,0.7h-0.2c-0.1,0-0.3,0-0.4,0.1
      s-0.3,0.1-0.5,0.2l-0.1-0.4c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.6-0.1H311.5z"
          fill="#FFFFFF"
        />
        <path
          d="M313.9,60.1l0,0.4l-1.2,0c-0.1,0-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2V61c0,0.1,0,0.2,0.1,0.2
      c0,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3v0.3c0,0.1-0.1,0.1-0.1,0.2
      c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.3
      c-0.1-0.1-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.6-0.2H313.9z M313.2,59c0.2,0,0.4,0,0.6,0.1
      c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.6v1.8h-0.6l-0.1-0.6l-0.1-0.1V60c0-0.1,0-0.2-0.1-0.3s-0.2-0.1-0.3-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l-0.1-0.5c0.1,0,0.3,0,0.4-0.1c0.2,0,0.3,0,0.5,0C312.9,59,313.1,59,313.2,59z"
          fill="#FFFFFF"
        />
        <path
          d="M315.2,59.1l0,0.5l0,0.1v2h-0.5v-2.6H315.2z M316.3,59.1c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v2
      h-0.4v-1.8c0-0.2,0-0.3-0.1-0.4s-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.2-0.4,0.3l0-0.3
      c0.2-0.2,0.4-0.3,0.6-0.4S316.2,59.1,316.3,59.1z M318.1,59.1c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v2h-0.5v-1.8
      c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.4,0.3l0-0.3
      c0.2-0.2,0.4-0.3,0.6-0.4C317.7,59.1,317.9,59.1,318.1,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M321.4,60.2l0,0.3l-1.3,0c-0.1,0-0.2,0-0.3,0.1c0,0.1-0.1,0.2-0.1,0.3V61c0,0.1,0,0.2,0.1,0.3
      c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.4-0.3v0.3c0,0.1-0.1,0.1-0.2,0.2
      c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1s-0.2-0.1-0.3-0.2
      c-0.1-0.1-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H321.4z M320.7,59.1c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.9h-0.4l-0.1-0.6l0-0.1v-1.2c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l0-0.3c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3,0,0.4,0C320.5,59.1,320.6,59.1,320.7,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M322.8,58l0,3.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.3l0.1,0.4c0,0-0.1,0-0.1,0s-0.1,0-0.2,0
      c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5V58H322.8z"
          fill="#FFFFFF"
        />
        <path
          d="M324.3,58l0,3.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.3l0.1,0.4c0,0-0.1,0-0.1,0s-0.1,0-0.2,0
      c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5V58H324.3z"
          fill="#FFFFFF"
        />
        <path
          d="M327.3,60.2l0,0.3l-1.3,0c-0.1,0-0.2,0-0.3,0.1c0,0.1-0.1,0.2-0.1,0.3V61c0,0.1,0,0.2,0.1,0.3
      c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.4-0.3v0.3c0,0.1-0.1,0.1-0.2,0.2
      c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1s-0.2-0.1-0.3-0.2
      c-0.1-0.1-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H327.3z M326.6,59.1c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.9h-0.4l-0.1-0.6l0-0.1v-1.2c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l0-0.3c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3,0,0.4,0C326.3,59.1,326.4,59.1,326.6,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M329.1,59.1c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0s0.2,0,0.4,0l0,0.3c-0.2,0-0.3,0-0.5,0
      c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.4,0s-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0,0.2,0.1,0.3,0.1l0.8,0.2
      c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.4,0.1-0.6,0.1
      c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4,0-0.6-0.1l0-0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0c0.2,0,0.4,0,0.5,0
      c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1l-0.8-0.2c-0.1,0-0.3-0.1-0.3-0.1
      c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.2S328.8,59.1,329.1,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M331.1,61.1c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1v0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.3
      c0,0.1-0.1,0.2-0.1,0.3l-0.2-0.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c-0.1,0-0.1-0.1-0.1-0.2v-0.3c0-0.1,0-0.1,0-0.1
      c0,0,0.1,0,0.1,0H331.1z"
          fill="#FFFFFF"
        />
        <path
          d="M334.2,59c0.4,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.5,0.4s0.1,0.5,0.1,0.8c0,0.3,0,0.6-0.1,0.8
      s-0.2,0.4-0.5,0.4c-0.2,0.1-0.5,0.1-0.8,0.1s-0.6,0-0.8-0.1c-0.2-0.1-0.4-0.2-0.5-0.4s-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8
      s0.2-0.4,0.5-0.4C333.6,59.1,333.9,59,334.2,59z M334.2,59.7c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0,0.3,0,0.5
      c0,0.2,0,0.3,0,0.5c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.1,0.1,0.3,0.1s0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.1-0.2s0-0.3,0-0.5
      c0-0.2,0-0.4,0-0.5s-0.1-0.2-0.1-0.2C334.4,59.7,334.4,59.7,334.2,59.7z"
          fill="#FFFFFF"
        />
        <path
          d="M337,58l0,0.9c0,0.2,0,0.3,0,0.4c0,0.1,0,0.3-0.1,0.3l0.1,0.1v2.1h-1V58H337z M338,59
      c0.5,0,0.8,0.2,0.8,0.8v2h-1v-1.7c0-0.1,0-0.1-0.1-0.2s-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.4,0.1l-0.1-0.5
      c0.2-0.2,0.4-0.3,0.6-0.4C337.6,59.1,337.8,59,338,59z"
          fill="#FFFFFF"
        />
        <path
          d="M340,59.1l0.1,0.5l0.1,0.1v2.1h-1v-2.7H340z M341.3,59l-0.1,0.9H341c-0.1,0-0.2,0-0.4,0
      c-0.1,0-0.3,0.1-0.5,0.1l-0.1-0.5c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.5-0.1H341.3z"
          fill="#FFFFFF"
        />
        <path
          d="M343.7,60.1l0,0.5h-1.1c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2v0.1c0,0.1,0,0.1,0.1,0.2
      c0,0,0.1,0.1,0.2,0.1s0.2,0,0.3-0.1s0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2v0.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0.3
      c-0.1,0.1-0.2,0.2-0.3,0.2s-0.3,0.1-0.5,0.1c-0.2,0-0.3,0-0.4-0.1s-0.2-0.1-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.4v-0.3
      c0-0.2,0.1-0.4,0.2-0.5c0.2-0.1,0.4-0.2,0.6-0.2H343.7z M343,59c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3
      c0.1,0.2,0.1,0.4,0.1,0.6v1.7h-0.8l-0.1-0.6l-0.1-0.1v-1c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1-0.1-0.3-0.1c-0.1,0-0.3,0-0.5,0
      c-0.2,0-0.4,0-0.6,0l-0.1-0.6c0.1,0,0.3,0,0.5-0.1c0.2,0,0.4,0,0.5,0S342.8,59,343,59z"
          fill="#FFFFFF"
        />
        <path
          d="M346.1,59.1v0.4h-1.8v-0.3l0.5,0H346.1z M345.3,58.4v2.7c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.3,0.1h0.4
      l0.1,0.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2s-0.2-0.3-0.2-0.5v-2.7H345.3z"
          fill="#FFFFFF"
        />
        <path
          d="M348.5,60.2l0,0.3l-1.3,0c-0.1,0-0.2,0-0.3,0.1c0,0.1-0.1,0.2-0.1,0.3V61c0,0.1,0,0.2,0.1,0.3
      c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.4-0.3v0.3c0,0.1-0.1,0.1-0.2,0.2
      c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1s-0.2-0.1-0.3-0.2
      c-0.1-0.1-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H348.5z M347.8,59.1c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.9h-0.4l-0.1-0.6l0-0.1v-1.2c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l0-0.3c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3,0,0.4,0C347.6,59.1,347.7,59.1,347.8,59.1z
       M347.2,58c0.1,0,0.1,0,0.1,0.1v0.3c0,0.1,0,0.1-0.1,0.1h-0.2c-0.1,0-0.1,0-0.1-0.1v-0.3c0-0.1,0-0.1,0.1-0.1H347.2z M348.2,58
      c0.1,0,0.1,0,0.1,0.1v0.3c0,0.1,0,0.1-0.1,0.1h-0.2c-0.1,0-0.1,0-0.1-0.1v-0.3c0-0.1,0-0.1,0.1-0.1H348.2z"
          fill="#FFFFFF"
        />
        <path
          d="M349.8,59.1l0.1,0.5l0,0.1v2h-0.5v-2.6H349.8z M351.1,59.1l-0.1,0.4h-0.2c-0.2,0-0.3,0-0.5,0.1
      c-0.2,0.1-0.3,0.2-0.6,0.3l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.6-0.1H351.1z"
          fill="#FFFFFF"
        />
        <path
          d="M352,58l0,1.7c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3v0.9h-0.5V58H352z
       M352.4,60.1v0.3h-0.6v-0.3H352.4z M353.7,59.1l-0.8,1c0,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1v0c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.1
      l0.9,1.3h-0.5l-1-1.5l1-1.1H353.7z"
          fill="#FFFFFF"
        />
        <path
          d="M354.6,58l0,1.7c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3v0.9h-0.5V58H354.6z
       M355.1,60.1v0.3h-0.6v-0.3H355.1z M356.3,59.1l-0.8,1c0,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1v0c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.1
      l0.9,1.3h-0.5l-1-1.5l1-1.1H356.3z"
          fill="#FFFFFF"
        />
        <path
          d="M357.8,59.1c0.4,0,0.7,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.2-0.5,0.2
      h-1.5v-0.4h1.4c0.1,0,0.2,0,0.2-0.1s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1
      c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.3-0.1,0.6c0,0.3,0,0.5,0.1,0.6s0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5,0
      c0.2,0,0.3,0,0.5,0l0,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0s-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4
      c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.4-0.4C357.3,59.1,357.5,59.1,357.8,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M360,58l0,3.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.3l0.1,0.4c0,0-0.1,0-0.1,0s-0.1,0-0.2,0
      c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5V58H360z"
          fill="#FFFFFF"
        />
        <path
          d="M361,59.1l0.7,2c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2h0.1l-0.2,0.4h-0.1c0,0-0.1,0-0.1,0
      c0,0-0.1-0.1-0.1-0.1l-1-2.5H361z M363.2,59.1l-1.1,2.8c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.3s-0.2,0.2-0.3,0.2
      c-0.1,0.1-0.3,0.1-0.4,0.1l0-0.3c0.2-0.1,0.4-0.2,0.5-0.3s0.2-0.3,0.3-0.4l0.1-0.3c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.2,0.1-0.2
      l0.7-2H363.2z"
          fill="#FFFFFF"
        />
        <path
          d="M364.5,59.1c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0s0.2,0,0.4,0l0,0.3c-0.2,0-0.3,0-0.5,0
      c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.4,0s-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0,0.2,0.1,0.3,0.1l0.8,0.2
      c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.4,0.1-0.6,0.1
      c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4,0-0.6-0.1l0-0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0c0.2,0,0.4,0,0.5,0
      c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1l-0.8-0.2c-0.1,0-0.3-0.1-0.3-0.1
      c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.2S364.3,59.1,364.5,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M366.5,61.1c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1v0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.3
      c0,0.1-0.1,0.2-0.1,0.3l-0.2-0.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c-0.1,0-0.1-0.1-0.1-0.2v-0.3c0-0.1,0-0.1,0-0.1
      c0,0,0.1,0,0.1,0H366.5z"
          fill="#FFFFFF"
        />
        <path
          d="M368.9,58l0,1c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3l0,0.1v2h-0.5V58H368.9z M370.1,59.1c0.5,0,0.7,0.2,0.7,0.7v2
      h-0.5v-1.8c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.5,0.1c-0.2,0.1-0.4,0.2-0.6,0.3l0-0.3
      c0.2-0.2,0.4-0.3,0.6-0.4C369.7,59.1,369.9,59.1,370.1,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M371.9,59.1V61c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.4-0.1s0.3-0.2,0.5-0.3l0.1,0.3
      c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.1-0.6,0.1c-0.5,0-0.7-0.2-0.7-0.7v-2H371.9z M373.7,59.1v2.6h-0.4l0-0.5l0-0.1v-2.1
      H373.7z"
          fill="#FFFFFF"
        />
        <path
          d="M374.9,59.1l0,0.5l0,0.1v2h-0.5v-2.6H374.9z M376,59.1c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v2
      h-0.4v-1.8c0-0.2,0-0.3-0.1-0.4s-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.2-0.4,0.3l0-0.3
      c0.2-0.2,0.4-0.3,0.6-0.4S375.8,59.1,376,59.1z M377.7,59.1c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v2h-0.5v-1.8
      c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.4,0.3l0-0.3
      c0.2-0.2,0.4-0.3,0.6-0.4C377.3,59.1,377.5,59.1,377.7,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M381,60.2l0,0.3l-1.3,0c-0.1,0-0.2,0-0.3,0.1c0,0.1-0.1,0.2-0.1,0.3V61c0,0.1,0,0.2,0.1,0.3
      c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.4-0.3v0.3c0,0.1-0.1,0.1-0.2,0.2
      c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1s-0.2-0.1-0.3-0.2
      c-0.1-0.1-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H381z M380.3,59.1c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.9h-0.4l-0.1-0.6l0-0.1v-1.2c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l0-0.3c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3,0,0.4,0C380.1,59.1,380.2,59.1,380.3,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M382.4,58l0,3.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.3l0.1,0.4c0,0-0.1,0-0.1,0s-0.1,0-0.2,0
      c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5V58H382.4z"
          fill="#FFFFFF"
        />
        <path
          d="M385.4,60.2l0,0.3l-1.3,0c-0.1,0-0.2,0-0.3,0.1c0,0.1-0.1,0.2-0.1,0.3V61c0,0.1,0,0.2,0.1,0.3
      c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.4-0.3v0.3c0,0.1-0.1,0.1-0.2,0.2
      c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1s-0.2-0.1-0.3-0.2
      c-0.1-0.1-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H385.4z M384.7,59.1c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.9h-0.4l-0.1-0.6l0-0.1v-1.2c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l0-0.3c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3,0,0.4,0C384.4,59.1,384.6,59.1,384.7,59.1z"
          fill="#FFFFFF"
        />
        <path
          d="M386.6,61.1c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1v0.3c0,0.1,0,0.1,0,0.1c0,0-0.1,0-0.1,0h-0.2
      c-0.1,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1v-0.3c0-0.1,0-0.1,0-0.1c0,0,0.1,0,0.1,0H386.6z"
          fill="#FFFFFF"
        />
        <path d="M266.9,64.3v3.7h-0.5v-3.7H266.9z" fill="#FFFFFF" />
        <path
          d="M268.1,65.4l0,0.5l0,0.1v2h-0.5v-2.6H268.1z M269.3,65.4c0.5,0,0.7,0.2,0.7,0.7v2h-0.5v-1.8
      c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.2-0.6,0.3l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4
      C268.9,65.4,269.1,65.4,269.3,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M271.1,66.9l0.3,0c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2s0.1,0.1,0.2,0.1l1,0.2
      c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.7,0.1
      c-0.2,0-0.4,0-0.6,0c-0.2,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.2,0.1-0.3s0.1-0.1,0.2-0.2
      s0.2-0.1,0.3-0.2l0.3-0.2l0.2,0.1l-0.3,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2
      c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.3,0,0.5,0c0.2,0,0.4,0,0.5,0s0.2-0.1,0.2-0.1c0-0.1,0.1-0.2,0.1-0.3
      c0-0.1,0-0.2,0-0.2s-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.3-0.1l-0.9-0.1c-0.1,0-0.2,0-0.3-0.1s-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.2
      c0-0.1,0-0.2,0.1-0.2S271,66.9,271.1,66.9z M271.8,65.4c0.3,0,0.5,0,0.7,0.1s0.3,0.1,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.5
      c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.3
      c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.3C271.3,65.4,271.5,65.4,271.8,65.4z M271.8,65.7
      c-0.3,0-0.4,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.4,0,0.6-0.1
      c0.1-0.1,0.2-0.2,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4C272.2,65.8,272.1,65.7,271.8,65.7z M273.2,65.4l0,0.3l-0.7,0.1l-0.2-0.3H273.2z"
          fill="#FFFFFF"
        />
        <path
          d="M274,65.4l0.1,0.5l0,0.1v2h-0.5v-2.6H274z M275.3,65.4l-0.1,0.4h-0.2c-0.2,0-0.3,0-0.5,0.1
      c-0.2,0.1-0.3,0.2-0.6,0.3l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.6-0.1H275.3z"
          fill="#FFFFFF"
        />
        <path
          d="M276.7,65.4c0.4,0,0.7,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.2-0.5,0.2
      h-1.5v-0.4h1.4c0.1,0,0.2,0,0.2-0.1s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1
      c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.3-0.1,0.6c0,0.3,0,0.5,0.1,0.6s0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5,0
      c0.2,0,0.3,0,0.5,0l0,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0s-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4
      c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.4-0.4C276.2,65.4,276.4,65.4,276.7,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M279.3,65.4c0.2,0,0.3,0,0.5,0.1s0.3,0.1,0.5,0.2l0,0.3c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0
      c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.1-0.2,0.3s-0.1,0.3-0.1,0.6s0,0.5,0.1,0.6c0,0.1,0.1,0.2,0.2,0.3s0.2,0.1,0.3,0.1
      c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2l0,0.3c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.6,0.1
      c-0.3,0-0.6-0.1-0.7-0.4s-0.2-0.6-0.2-1c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.3-0.4C278.9,65.4,279.1,65.4,279.3,65.4z
       M280.7,64.3v3.8h-0.4l-0.1-0.5l0,0v-1.8l0-0.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3v-0.8H280.7z"
          fill="#FFFFFF"
        />
        <path
          d="M281.8,64.3c0.1,0,0.1,0,0.1,0.1v0.3c0,0.1,0,0.1-0.1,0.1h-0.2c-0.1,0-0.1,0-0.1-0.1v-0.3
      c0-0.1,0-0.1,0.1-0.1H281.8z M281.9,65.4v2.6h-0.5v-2.6H281.9z"
          fill="#FFFFFF"
        />
        <path
          d="M283.7,65.4c0.4,0,0.7,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.2-0.5,0.2
      h-1.5v-0.4h1.4c0.1,0,0.2,0,0.2-0.1s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1
      c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.3-0.1,0.6c0,0.3,0,0.5,0.1,0.6s0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5,0
      c0.2,0,0.3,0,0.5,0l0,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0s-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4
      c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.4-0.4C283.2,65.4,283.5,65.4,283.7,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M285.8,65.4l0,0.5l0,0.1v2h-0.5v-2.6H285.8z M287,65.4c0.5,0,0.7,0.2,0.7,0.7v2h-0.5v-1.8
      c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.2-0.6,0.3l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4
      C286.6,65.4,286.8,65.4,287,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M289.4,65.4c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0s0.2,0,0.4,0l0,0.3c-0.2,0-0.3,0-0.5,0
      c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.4,0s-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0,0.2,0.1,0.3,0.1l0.8,0.2
      c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.4,0.1-0.6,0.1
      c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4,0-0.6-0.1l0-0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0c0.2,0,0.4,0,0.5,0
      c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1l-0.8-0.2c-0.1,0-0.3-0.1-0.3-0.1
      c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.2S289.1,65.4,289.4,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M292.2,65.4c0.4,0,0.7,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.2-0.5,0.2
      h-1.5v-0.4h1.4c0.1,0,0.2,0,0.2-0.1s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1
      c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.3-0.1,0.6c0,0.3,0,0.5,0.1,0.6s0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5,0
      c0.2,0,0.3,0,0.5,0l0,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0s-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4
      c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.4-0.4C291.7,65.4,291.9,65.4,292.2,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M294.3,65.4l0.1,0.5l0,0.1v2h-0.5v-2.6H294.3z M295.6,65.4l-0.1,0.4h-0.2c-0.2,0-0.3,0-0.5,0.1
      c-0.2,0.1-0.3,0.2-0.6,0.3l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.6-0.1H295.6z"
          fill="#FFFFFF"
        />
        <path
          d="M296.2,65.7c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1v0.3c0,0.1,0,0.1,0,0.1c0,0-0.1,0-0.1,0h-0.2
      c-0.1,0-0.1,0-0.1,0s0-0.1,0-0.1v-0.3c0-0.1,0-0.1,0-0.1s0.1,0,0.1,0H296.2z M296.2,67.4c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1v0.3
      c0,0.1,0,0.1,0,0.1c0,0-0.1,0-0.1,0h-0.2c-0.1,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1v-0.3c0-0.1,0-0.1,0-0.1c0,0,0.1,0,0.1,0H296.2z"
          fill="#FFFFFF"
        />
        <path
          d="M300.4,65.4l-1,2.5c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0h-0.4c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1l-1-2.5h0.5
      l0.6,1.8c0,0.1,0.1,0.2,0.1,0.2s0.1,0.2,0.1,0.2h0.1c0-0.1,0.1-0.2,0.1-0.2s0.1-0.2,0.1-0.2l0.6-1.8H300.4z"
          fill="#FFFFFF"
        />
        <path
          d="M302.8,66.5l0,0.3l-1.3,0c-0.1,0-0.2,0-0.3,0.1c0,0.1-0.1,0.2-0.1,0.3v0.2c0,0.1,0,0.2,0.1,0.3
      c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.4-0.3v0.3c0,0.1-0.1,0.1-0.2,0.2
      c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1s-0.2-0.1-0.3-0.2
      c-0.1-0.1-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H302.8z M302.1,65.4c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.9h-0.4l-0.1-0.6l0-0.1v-1.2c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l0-0.3c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3,0,0.4,0C301.8,65.4,301.9,65.4,302.1,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M305.2,65.4v0.4h-1.8v-0.3l0.5,0H305.2z M304.3,64.7v2.7c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.3,0.1h0.4
      l0.1,0.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2s-0.2-0.3-0.2-0.5v-2.7H304.3z"
          fill="#FFFFFF"
        />
        <path
          d="M307.1,65.4v0.4h-1.8v-0.3l0.5,0H307.1z M306.3,64.7v2.7c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.3,0.1h0.4
      l0.1,0.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2s-0.2-0.3-0.2-0.5v-2.7H306.3z"
          fill="#FFFFFF"
        />
        <path
          d="M308.7,65.4c0.4,0,0.7,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.2-0.5,0.2
      h-1.5v-0.4h1.4c0.1,0,0.2,0,0.2-0.1s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1
      c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.3-0.1,0.6c0,0.3,0,0.5,0.1,0.6s0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5,0
      c0.2,0,0.3,0,0.5,0l0,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0s-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4
      c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.4-0.4C308.2,65.4,308.4,65.4,308.7,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M310.8,65.4l0,0.5l0,0.1v2h-0.5v-2.6H310.8z M312,65.4c0.5,0,0.7,0.2,0.7,0.7v2h-0.5v-1.8
      c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.2-0.6,0.3l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4
      C311.6,65.4,311.8,65.4,312,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M313.7,67.4c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1V68c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.3
      c0,0.1-0.1,0.2-0.1,0.3l-0.2-0.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c-0.1,0-0.1-0.1-0.1-0.2v-0.3c0-0.1,0-0.1,0-0.1
      c0,0,0.1,0,0.1,0H313.7z"
          fill="#FFFFFF"
        />
        <path
          d="M316.3,64.3l0,1.6c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2-0.1,0.4c0,0.1,0,0.2,0.1,0.3s0,0.2,0,0.3v0.8h-0.8v-3.8
      H316.3z M316.7,66.3v0.5h-0.6v-0.5H316.7z M318.1,65.4l-0.7,0.9c0,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1v0c0,0,0.1,0.1,0.1,0.1
      s0.1,0.1,0.1,0.2l0.8,1.2h-0.9l-0.9-1.5l0.8-1.2H318.1z"
          fill="#FFFFFF"
        />
        <path
          d="M319.6,65.3c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.5,0.1,0.8c0,0.3,0,0.6-0.1,0.8
      c-0.1,0.2-0.2,0.4-0.4,0.4c-0.2,0.1-0.5,0.1-0.8,0.1c-0.3,0-0.6,0-0.8-0.1s-0.4-0.2-0.4-0.4s-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8
      s0.2-0.4,0.4-0.4S319.3,65.3,319.6,65.3z M319.6,65.9c-0.1,0-0.2,0-0.3,0.1s-0.1,0.1-0.2,0.2c0,0.1,0,0.3,0,0.5c0,0.2,0,0.4,0,0.5
      s0.1,0.2,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0-0.3,0-0.5c0-0.2,0-0.4,0-0.5c0-0.1-0.1-0.2-0.2-0.2
      S319.7,65.9,319.6,65.9z"
          fill="#FFFFFF"
        />
        <path
          d="M322,65.4l0.1,0.5l0.1,0.1v2.1h-0.8v-2.7H322z M323.3,65.3l-0.1,0.7H323c-0.1,0-0.3,0-0.4,0.1
      s-0.3,0.1-0.5,0.2l-0.1-0.4c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.6-0.1H323.3z"
          fill="#FFFFFF"
        />
        <path
          d="M324.3,65.4l0.1,0.5l0.1,0.1v2.1h-0.8v-2.7H324.3z M325.5,65.3c0.5,0,0.8,0.2,0.8,0.7v2h-0.8v-1.7
      c0-0.1,0-0.2-0.1-0.2s-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.3,0.1-0.5,0.2l0-0.4c0.2-0.2,0.4-0.3,0.6-0.4
      C325.1,65.4,325.3,65.3,325.5,65.3z"
          fill="#FFFFFF"
        />
        <path
          d="M327.2,65.4l0,0.5l0,0.1v2h-0.5v-2.6H327.2z M328.3,65.4c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v2
      h-0.4v-1.8c0-0.2,0-0.3-0.1-0.4s-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.2-0.4,0.3l0-0.3
      c0.2-0.2,0.4-0.3,0.6-0.4S328.1,65.4,328.3,65.4z M330,65.4c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v2h-0.5v-1.8
      c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.4,0.3l0-0.3
      c0.2-0.2,0.4-0.3,0.6-0.4C329.7,65.4,329.9,65.4,330,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M333.4,66.5l0,0.3l-1.3,0c-0.1,0-0.2,0-0.3,0.1c0,0.1-0.1,0.2-0.1,0.3v0.2c0,0.1,0,0.2,0.1,0.3
      c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.4-0.3v0.3c0,0.1-0.1,0.1-0.2,0.2
      c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1s-0.2-0.1-0.3-0.2
      c-0.1-0.1-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H333.4z M332.7,65.4c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.9h-0.4l-0.1-0.6l0-0.1v-1.2c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l0-0.3c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3,0,0.4,0C332.4,65.4,332.6,65.4,332.7,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M334.8,64.3l0,3.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.3l0.1,0.4c0,0-0.1,0-0.1,0s-0.1,0-0.2,0
      c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-3.2H334.8z"
          fill="#FFFFFF"
        />
        <path
          d="M337.3,65.4v0.4h-1.8v-0.3l0.5,0H337.3z M336.4,64.7v2.7c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.3,0.1h0.4
      l0.1,0.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2s-0.2-0.3-0.2-0.5v-2.7H336.4z"
          fill="#FFFFFF"
        />
        <path
          d="M338,67.4c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1V68c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.3
      c0,0.1-0.1,0.2-0.1,0.3l-0.2-0.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c-0.1,0-0.1-0.1-0.1-0.2v-0.3c0-0.1,0-0.1,0-0.1
      c0,0,0.1,0,0.1,0H338z"
          fill="#FFFFFF"
        />
        <path
          d="M340.6,64.3l0,1.6c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2-0.1,0.4c0,0.1,0,0.2,0.1,0.3s0,0.2,0,0.3v0.8h-0.8v-3.8
      H340.6z M341,66.3v0.5h-0.6v-0.5H341z M342.4,65.4l-0.7,0.9c0,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1v0c0,0,0.1,0.1,0.1,0.1
      s0.1,0.1,0.1,0.2l0.8,1.2h-0.9l-0.9-1.5l0.8-1.2H342.4z"
          fill="#FFFFFF"
        />
        <path
          d="M343.9,65.3c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.5,0.1,0.8c0,0.3,0,0.6-0.1,0.8
      c-0.1,0.2-0.2,0.4-0.4,0.4c-0.2,0.1-0.5,0.1-0.8,0.1c-0.3,0-0.6,0-0.8-0.1s-0.4-0.2-0.4-0.4s-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8
      s0.2-0.4,0.4-0.4S343.6,65.3,343.9,65.3z M343.9,65.9c-0.1,0-0.2,0-0.3,0.1s-0.1,0.1-0.2,0.2c0,0.1,0,0.3,0,0.5c0,0.2,0,0.4,0,0.5
      s0.1,0.2,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3-0.1s0.1-0.1,0.2-0.2s0-0.3,0-0.5c0-0.2,0-0.4,0-0.5c0-0.1-0.1-0.2-0.2-0.2
      S344.1,65.9,343.9,65.9z"
          fill="#FFFFFF"
        />
        <path
          d="M346.4,65.4l0.1,0.5l0.1,0.1v2.1h-0.8v-2.7H346.4z M347.7,65.3l-0.1,0.7h-0.2c-0.1,0-0.3,0-0.4,0.1
      s-0.3,0.1-0.5,0.2l-0.1-0.4c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.6-0.1H347.7z"
          fill="#FFFFFF"
        />
        <path
          d="M348.6,65.4l0.1,0.5l0.1,0.1v2.1h-0.8v-2.7H348.6z M349.8,65.3c0.5,0,0.8,0.2,0.8,0.7v2h-0.8v-1.7
      c0-0.1,0-0.2-0.1-0.2s-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.3,0.1-0.5,0.2l0-0.4c0.2-0.2,0.4-0.3,0.6-0.4
      C349.4,65.4,349.6,65.3,349.8,65.3z"
          fill="#FFFFFF"
        />
        <path
          d="M352,65.4c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0s0.2,0,0.4,0l0,0.3c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.5,0
      c-0.2,0-0.3,0-0.4,0s-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0,0.2,0.1,0.3,0.1l0.8,0.2
      c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.4,0.1-0.6,0.1
      c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4,0-0.6-0.1l0-0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0c0.2,0,0.4,0,0.5,0
      c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1l-0.8-0.2c-0.1,0-0.3-0.1-0.3-0.1
      c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.2S351.8,65.4,352,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M355.3,65.4v0.4h-1.8v-0.3l0.5,0H355.3z M354.5,64.7v2.7c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.3,0.1h0.4
      l0.1,0.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2s-0.2-0.3-0.2-0.5v-2.7H354.5z"
          fill="#FFFFFF"
        />
        <path
          d="M357.7,66.5l0,0.3l-1.3,0c-0.1,0-0.2,0-0.3,0.1c0,0.1-0.1,0.2-0.1,0.3v0.2c0,0.1,0,0.2,0.1,0.3
      c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.4-0.3v0.3c0,0.1-0.1,0.1-0.2,0.2
      c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1s-0.2-0.1-0.3-0.2
      c-0.1-0.1-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H357.7z M357,65.4c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.9h-0.4l-0.1-0.6l0-0.1v-1.2c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l0-0.3c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3,0,0.4,0C356.7,65.4,356.9,65.4,357,65.4z
       M356.4,64.3c0.1,0,0.1,0,0.1,0.1v0.3c0,0.1,0,0.1-0.1,0.1h-0.2c-0.1,0-0.1,0-0.1-0.1v-0.3c0-0.1,0-0.1,0.1-0.1H356.4z
       M357.4,64.3c0.1,0,0.1,0,0.1,0.1v0.3c0,0.1,0,0.1-0.1,0.1h-0.2c-0.1,0-0.1,0-0.1-0.1v-0.3c0-0.1,0-0.1,0.1-0.1H357.4z"
          fill="#FFFFFF"
        />
        <path
          d="M359,65.4l0.1,0.5l0,0.1v2h-0.5v-2.6H359z M360.3,65.4l-0.1,0.4H360c-0.2,0-0.3,0-0.5,0.1
      c-0.2,0.1-0.3,0.2-0.6,0.3l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.6-0.1H360.3z"
          fill="#FFFFFF"
        />
        <path
          d="M361.1,64.3l0,1.7c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3v0.9h-0.5v-3.8
      H361.1z M361.6,66.4v0.3H361v-0.3H361.6z M362.8,65.4l-0.8,1c0,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1v0c0,0,0.1,0,0.1,0.1
      s0.1,0.1,0.1,0.1l0.9,1.3h-0.5l-1-1.5l1-1.1H362.8z"
          fill="#FFFFFF"
        />
        <path
          d="M364.3,65.4c0.4,0,0.7,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.2-0.5,0.2
      h-1.5v-0.4h1.4c0.1,0,0.2,0,0.2-0.1s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1
      c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.3-0.1,0.6c0,0.3,0,0.5,0.1,0.6s0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5,0
      c0.2,0,0.3,0,0.5,0l0,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0s-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4
      c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.4-0.4C363.8,65.4,364,65.4,364.3,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M366.5,64.3l0,3.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.3l0.1,0.4c0,0-0.1,0-0.1,0s-0.1,0-0.2,0
      c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-3.2H366.5z"
          fill="#FFFFFF"
        />
        <path
          d="M368.4,65.4c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0s0.2,0,0.4,0l0,0.3c-0.2,0-0.3,0-0.5,0
      c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.4,0s-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0,0.2,0.1,0.3,0.1l0.8,0.2
      c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.4,0.1-0.6,0.1
      c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4,0-0.6-0.1l0-0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0c0.2,0,0.4,0,0.5,0
      c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1l-0.8-0.2c-0.1,0-0.3-0.1-0.3-0.1
      c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.2S368.1,65.4,368.4,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M371.2,65.4c0.4,0,0.7,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.2-0.5,0.2
      h-1.5v-0.4h1.4c0.1,0,0.2,0,0.2-0.1s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1
      c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.3-0.1,0.6c0,0.3,0,0.5,0.1,0.6s0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5,0
      c0.2,0,0.3,0,0.5,0l0,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0s-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4
      c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.4-0.4C370.7,65.4,370.9,65.4,371.2,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M373.2,67.4c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1V68c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.3
      c0,0.1-0.1,0.2-0.1,0.3l-0.2-0.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c-0.1,0-0.1-0.1-0.1-0.2v-0.3c0-0.1,0-0.1,0-0.1
      c0,0,0.1,0,0.1,0H373.2z"
          fill="#FFFFFF"
        />
        <path
          d="M375.6,64.3l0,1c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3l0,0.1v2h-0.5v-3.8H375.6z M376.8,65.4
      c0.5,0,0.7,0.2,0.7,0.7v2H377v-1.8c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.5,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
      l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4C376.4,65.4,376.6,65.4,376.8,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M378.6,65.4v1.8c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.4-0.1s0.3-0.2,0.5-0.3
      l0.1,0.3c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.1-0.6,0.1c-0.5,0-0.7-0.2-0.7-0.7v-2H378.6z M380.4,65.4v2.6h-0.4l0-0.5l0-0.1
      v-2.1H380.4z"
          fill="#FFFFFF"
        />
        <path
          d="M381.5,65.4l0,0.5l0,0.1v2h-0.5v-2.6H381.5z M382.6,65.4c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v2
      h-0.4v-1.8c0-0.2,0-0.3-0.1-0.4s-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.2-0.4,0.3l0-0.3
      c0.2-0.2,0.4-0.3,0.6-0.4S382.4,65.4,382.6,65.4z M384.4,65.4c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v2h-0.5v-1.8
      c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.4,0.3l0-0.3
      c0.2-0.2,0.4-0.3,0.6-0.4C384,65.4,384.2,65.4,384.4,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M386.2,64.3l0,3.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.3l0.1,0.4c0,0-0.1,0-0.1,0s-0.1,0-0.2,0
      c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-3.2H386.2z"
          fill="#FFFFFF"
        />
        <path
          d="M388.4,65.4c0.4,0,0.7,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.2-0.5,0.2
      h-1.5v-0.4h1.4c0.1,0,0.2,0,0.2-0.1s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1
      c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.3-0.1,0.6c0,0.3,0,0.5,0.1,0.6s0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5,0
      c0.2,0,0.3,0,0.5,0l0,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0s-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4
      c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.4-0.4C387.8,65.4,388.1,65.4,388.4,65.4z"
          fill="#FFFFFF"
        />
        <path
          d="M390.3,67.4c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1v0.3c0,0.1,0,0.1,0,0.1c0,0-0.1,0-0.1,0h-0.2
      c-0.1,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1v-0.3c0-0.1,0-0.1,0-0.1c0,0,0.1,0,0.1,0H390.3z"
          fill="#FFFFFF"
        />
        <path
          d="M267.2,70.6c0.2,0,0.4,0,0.5,0s0.4,0,0.5,0c0.2,0,0.3,0,0.5,0l0,0.4h-1.5c-0.1,0-0.2,0-0.2,0.1
      c-0.1,0.1-0.1,0.1-0.1,0.3v2.2c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.1h1.5l0,0.4c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.5,0
      s-0.4,0-0.5,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-2.4c0-0.2,0.1-0.4,0.2-0.5C266.8,70.7,266.9,70.6,267.2,70.6z
       M266.5,72.2h2v0.4h-2V72.2z"
          fill="#FFFFFF"
        />
        <path
          d="M269.7,71.7l0,0.5l0,0.1v2h-0.5v-2.6H269.7z M270.9,71.7c0.5,0,0.7,0.2,0.7,0.7v2h-0.5v-1.8
      c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.2-0.6,0.3l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4
      C270.5,71.7,270.7,71.7,270.9,71.7z"
          fill="#FFFFFF"
        />
        <path
          d="M273.4,71.7c0.4,0,0.7,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.2-0.5,0.2
      h-1.5v-0.4h1.4c0.1,0,0.2,0,0.2-0.1s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1
      c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.3-0.1,0.6c0,0.3,0,0.5,0.1,0.6s0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5,0
      c0.2,0,0.3,0,0.5,0l0,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0s-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4
      c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.4-0.4C272.9,71.7,273.2,71.7,273.4,71.7z"
          fill="#FFFFFF"
        />
        <path
          d="M275.5,71.7l0.1,0.5l0,0.1v2h-0.5v-2.6H275.5z M276.8,71.7l-0.1,0.4h-0.2c-0.2,0-0.3,0-0.5,0.1
      c-0.2,0.1-0.3,0.2-0.6,0.3l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.6-0.1H276.8z"
          fill="#FFFFFF"
        />
        <path
          d="M277.5,73.2l0.3,0c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2s0.1,0.1,0.2,0.1l1,0.2
      c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.7,0.1
      c-0.2,0-0.4,0-0.6,0c-0.2,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.2,0.1-0.3s0.1-0.1,0.2-0.2
      s0.2-0.1,0.3-0.2l0.3-0.2l0.2,0.1l-0.3,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2
      c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.3,0,0.5,0c0.2,0,0.4,0,0.5,0s0.2-0.1,0.2-0.1c0-0.1,0.1-0.2,0.1-0.3
      c0-0.1,0-0.2,0-0.2s-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.3-0.1l-0.9-0.1c-0.1,0-0.2,0-0.3-0.1s-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.2
      c0-0.1,0-0.2,0.1-0.2S277.4,73.2,277.5,73.2z M278.2,71.7c0.3,0,0.5,0,0.7,0.1s0.3,0.1,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.5
      c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.3
      c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.3C277.7,71.7,278,71.7,278.2,71.7z M278.2,72
      c-0.3,0-0.4,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.4,0,0.6-0.1
      c0.1-0.1,0.2-0.2,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4C278.7,72.1,278.5,72,278.2,72z M279.7,71.7l0,0.3l-0.7,0.1l-0.2-0.3H279.7z"
          fill="#FFFFFF"
        />
        <path
          d="M280.5,70.6c0.1,0,0.1,0,0.1,0.1v0.3c0,0.1,0,0.1-0.1,0.1h-0.2c-0.1,0-0.1,0-0.1-0.1v-0.3
      c0-0.1,0-0.1,0.1-0.1H280.5z M280.6,71.7v2.6h-0.5v-2.6H280.6z"
          fill="#FFFFFF"
        />
        <path
          d="M283.3,72.8l0,0.3l-1.3,0c-0.1,0-0.2,0-0.3,0.1c0,0.1-0.1,0.2-0.1,0.3v0.2c0,0.1,0,0.2,0.1,0.3
      c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.4-0.3v0.3c0,0.1-0.1,0.1-0.2,0.2
      c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1s-0.2-0.1-0.3-0.2
      c-0.1-0.1-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H283.3z M282.6,71.7c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.9h-0.4l-0.1-0.6l0-0.1v-1.2c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l0-0.3c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3,0,0.4,0C282.3,71.7,282.4,71.7,282.6,71.7z"
          fill="#FFFFFF"
        />
        <path
          d="M285.1,71.7c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0s0.2,0,0.4,0l0,0.3c-0.2,0-0.3,0-0.5,0
      c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.4,0s-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0,0.2,0.1,0.3,0.1l0.8,0.2
      c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.4,0.1-0.6,0.1
      c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4,0-0.6-0.1l0-0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0c0.2,0,0.4,0,0.5,0
      c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1l-0.8-0.2c-0.1,0-0.3-0.1-0.3-0.1
      c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.2S284.8,71.7,285.1,71.7z"
          fill="#FFFFFF"
        />
        <path
          d="M287.2,70.6c0.1,0,0.1,0,0.1,0.1v0.3c0,0.1,0,0.1-0.1,0.1H287c-0.1,0-0.1,0-0.1-0.1v-0.3
      c0-0.1,0-0.1,0.1-0.1H287.2z M287.3,71.7v2.6h-0.5v-2.6H287.3z"
          fill="#FFFFFF"
        />
        <path
          d="M288.9,71.7c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0s0.2,0,0.4,0l0,0.3c-0.2,0-0.3,0-0.5,0
      c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.4,0s-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0,0.2,0.1,0.3,0.1l0.8,0.2
      c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.4,0.1-0.6,0.1
      c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4,0-0.6-0.1l0-0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0c0.2,0,0.4,0,0.5,0
      c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1l-0.8-0.2c-0.1,0-0.3-0.1-0.3-0.1
      c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.2S288.7,71.7,288.9,71.7z"
          fill="#FFFFFF"
        />
        <path
          d="M292.6,72.8l0,0.3l-1.3,0c-0.1,0-0.2,0-0.3,0.1c0,0.1-0.1,0.2-0.1,0.3v0.2c0,0.1,0,0.2,0.1,0.3
      c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.4-0.3v0.3c0,0.1-0.1,0.1-0.2,0.2
      c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1s-0.2-0.1-0.3-0.2
      c-0.1-0.1-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H292.6z M291.9,71.7c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.9h-0.4l-0.1-0.6l0-0.1v-1.2c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l0-0.3c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3,0,0.4,0C291.7,71.7,291.8,71.7,291.9,71.7z
       M291.3,70.6c0.1,0,0.1,0,0.1,0.1v0.3c0,0.1,0,0.1-0.1,0.1h-0.2c-0.1,0-0.1,0-0.1-0.1v-0.3c0-0.1,0-0.1,0.1-0.1H291.3z
       M292.3,70.6c0.1,0,0.1,0,0.1,0.1v0.3c0,0.1,0,0.1-0.1,0.1h-0.2c-0.1,0-0.1,0-0.1-0.1v-0.3c0-0.1,0-0.1,0.1-0.1H292.3z"
          fill="#FFFFFF"
        />
        <path
          d="M294,70.6l0,3.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.3l0.1,0.4c0,0-0.1,0-0.1,0s-0.1,0-0.2,0
      c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-3.2H294z"
          fill="#FFFFFF"
        />
        <path
          d="M296.5,71.7v0.4h-1.8v-0.3l0.5,0H296.5z M295.7,71v2.7c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.3,0.1h0.4
      l0.1,0.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2s-0.2-0.3-0.2-0.5V71H295.7z"
          fill="#FFFFFF"
        />
        <path
          d="M298.1,71.7c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.5,0.1,0.8c0,0.3,0,0.6-0.1,0.8
      s-0.2,0.4-0.4,0.4s-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8
      c0.1-0.2,0.2-0.3,0.4-0.4C297.5,71.7,297.8,71.7,298.1,71.7z M298.1,72.1c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.2,0.3
      c0,0.1-0.1,0.3-0.1,0.6c0,0.3,0,0.5,0.1,0.6c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5-0.1
      c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0.1-0.3,0.1-0.6c0-0.3,0-0.5-0.1-0.6c0-0.1-0.1-0.2-0.2-0.3C298.4,72.1,298.3,72.1,298.1,72.1z
       M297.7,70.7c0.1,0,0.1,0,0.1,0.1v0.3c0,0.1,0,0.1-0.1,0.1h-0.2c-0.1,0-0.1,0-0.1-0.1v-0.3c0-0.1,0-0.1,0.1-0.1H297.7z
       M298.6,70.7c0.1,0,0.1,0,0.1,0.1v0.3c0,0.1,0,0.1-0.1,0.1h-0.2c-0.1,0-0.1,0-0.1-0.1v-0.3c0-0.1,0-0.1,0.1-0.1H298.6z"
          fill="#FFFFFF"
        />
        <path d="M302,70.6l-2.1,4.1h-0.4l2.1-4.1H302z" fill="#FFFFFF" />
        <path
          d="M303.2,70.6c0.2,0,0.4,0,0.5,0s0.4,0,0.5,0c0.2,0,0.3,0,0.5,0l0,0.4h-1.5c-0.1,0-0.2,0-0.2,0.1
      c-0.1,0.1-0.1,0.1-0.1,0.3v2.2c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.1h1.5l0,0.4c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.5,0
      s-0.4,0-0.5,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-2.4c0-0.2,0.1-0.4,0.2-0.5C302.8,70.7,303,70.6,303.2,70.6z
       M302.6,72.2h2v0.4h-2V72.2z"
          fill="#FFFFFF"
        />
        <path
          d="M305.8,71.7l0,0.5l0,0.1v2h-0.5v-2.6H305.8z M307,71.7c0.5,0,0.7,0.2,0.7,0.7v2h-0.5v-1.8
      c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.2-0.6,0.3l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4
      C306.6,71.7,306.8,71.7,307,71.7z"
          fill="#FFFFFF"
        />
        <path
          d="M309.5,71.7c0.4,0,0.7,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.2-0.5,0.2
      h-1.5v-0.4h1.4c0.1,0,0.2,0,0.2-0.1s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1
      c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.3-0.1,0.6c0,0.3,0,0.5,0.1,0.6s0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5,0
      c0.2,0,0.3,0,0.5,0l0,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0s-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4
      c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.4-0.4C309,71.7,309.2,71.7,309.5,71.7z"
          fill="#FFFFFF"
        />
        <path
          d="M311.6,71.7l0.1,0.5l0,0.1v2h-0.5v-2.6H311.6z M312.9,71.7l-0.1,0.4h-0.2c-0.2,0-0.3,0-0.5,0.1
      c-0.2,0.1-0.3,0.2-0.6,0.3l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.6-0.1H312.9z"
          fill="#FFFFFF"
        />
        <path
          d="M313.6,73.2l0.3,0c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2s0.1,0.1,0.2,0.1l1,0.2
      c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.7,0.1
      c-0.2,0-0.4,0-0.6,0c-0.2,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.2,0.1-0.3s0.1-0.1,0.2-0.2
      s0.2-0.1,0.3-0.2l0.3-0.2l0.2,0.1l-0.3,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2
      c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.3,0,0.5,0c0.2,0,0.4,0,0.5,0s0.2-0.1,0.2-0.1c0-0.1,0.1-0.2,0.1-0.3
      c0-0.1,0-0.2,0-0.2s-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.3-0.1l-0.9-0.1c-0.1,0-0.2,0-0.3-0.1s-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.2
      c0-0.1,0-0.2,0.1-0.2S313.5,73.2,313.6,73.2z M314.3,71.7c0.3,0,0.5,0,0.7,0.1s0.3,0.1,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.5
      c0,0.2,0,0.3-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.3
      c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.3C313.8,71.7,314,71.7,314.3,71.7z M314.3,72
      c-0.3,0-0.4,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.4,0,0.6-0.1
      c0.1-0.1,0.2-0.2,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4C314.7,72.1,314.6,72,314.3,72z M315.7,71.7l0,0.3l-0.7,0.1l-0.2-0.3H315.7z"
          fill="#FFFFFF"
        />
        <path
          d="M316.5,70.6c0.1,0,0.1,0,0.1,0.1v0.3c0,0.1,0,0.1-0.1,0.1h-0.2c-0.1,0-0.1,0-0.1-0.1v-0.3
      c0-0.1,0-0.1,0.1-0.1H316.5z M316.6,71.7v2.6h-0.5v-2.6H316.6z"
          fill="#FFFFFF"
        />
        <path
          d="M317.8,70.6c0.1,0,0.1,0,0.1,0.1v0.3c0,0.1,0,0.1-0.1,0.1h-0.2c-0.1,0-0.1,0-0.1-0.1v-0.3
      c0-0.1,0-0.1,0.1-0.1H317.8z M317.9,71.7v2.6h-0.5v-2.6H317.9z"
          fill="#FFFFFF"
        />
        <path
          d="M319,71.7l0,0.5l0,0.1v2h-0.5v-2.6H319z M320.2,71.7c0.5,0,0.7,0.2,0.7,0.7v2h-0.5v-1.8
      c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.2-0.6,0.3l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4
      C319.8,71.7,320,71.7,320.2,71.7z"
          fill="#FFFFFF"
        />
        <path
          d="M322,71.7l0,0.5l0,0.1v2h-0.5v-2.6H322z M323.3,71.7c0.5,0,0.7,0.2,0.7,0.7v2h-0.5v-1.8
      c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.2-0.6,0.3l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4
      C322.9,71.7,323.1,71.7,323.3,71.7z"
          fill="#FFFFFF"
        />
        <path
          d="M325.8,71.7c0.4,0,0.7,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.3,0.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.2-0.5,0.2
      h-1.5v-0.4h1.4c0.1,0,0.2,0,0.2-0.1s0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1
      c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.3-0.1,0.6c0,0.3,0,0.5,0.1,0.6s0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.5,0
      c0.2,0,0.3,0,0.5,0l0,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0s-0.2,0-0.3,0c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4
      c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.4-0.4C325.3,71.7,325.5,71.7,325.8,71.7z"
          fill="#FFFFFF"
        />
        <path
          d="M327.9,70.6l0,1c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3l0,0.1v2h-0.5v-3.8H327.9z M329.1,71.7
      c0.5,0,0.7,0.2,0.7,0.7v2h-0.5v-1.8c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.5,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
      l0-0.3c0.2-0.2,0.4-0.3,0.6-0.4C328.7,71.7,328.9,71.7,329.1,71.7z"
          fill="#FFFFFF"
        />
        <path
          d="M332.4,72.8l0,0.3l-1.3,0c-0.1,0-0.2,0-0.3,0.1c0,0.1-0.1,0.2-0.1,0.3v0.2c0,0.1,0,0.2,0.1,0.3
      c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.4-0.3v0.3c0,0.1-0.1,0.1-0.2,0.2
      c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1s-0.2-0.1-0.3-0.2
      c-0.1-0.1-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H332.4z M331.7,71.7c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.9h-0.4l-0.1-0.6l0-0.1v-1.2c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l0-0.3c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3,0,0.4,0C331.5,71.7,331.6,71.7,331.7,71.7z
       M331.6,70.3c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.2
      c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3
      c0.1-0.1,0.1-0.2,0.2-0.2C331.4,70.3,331.5,70.3,331.6,70.3z M331.6,70.6c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2
      c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.2
      C331.7,70.6,331.7,70.6,331.6,70.6z"
          fill="#FFFFFF"
        />
        <path
          d="M333.8,70.6l0,3.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.3l0.1,0.4c0,0-0.1,0-0.1,0s-0.1,0-0.2,0
      c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-3.2H333.8z"
          fill="#FFFFFF"
        />
        <path
          d="M335.3,70.6l0,3.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.3l0.1,0.4c0,0-0.1,0-0.1,0s-0.1,0-0.2,0
      c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-3.2H335.3z"
          fill="#FFFFFF"
        />
        <path
          d="M338.6,70.1l0.1,0.2c-0.4,0.2-0.6,0.5-0.8,0.9s-0.2,0.9-0.2,1.5c0,0.4,0,0.8,0.1,1.1s0.2,0.6,0.3,0.8
      s0.3,0.4,0.6,0.5l-0.1,0.2c-0.5-0.2-0.8-0.5-1.1-0.9c-0.2-0.4-0.3-1-0.3-1.7c0-0.7,0.1-1.3,0.3-1.7
      C337.8,70.6,338.1,70.3,338.6,70.1z"
          fill="#FFFFFF"
        />
        <path
          d="M340.3,70.6v3.7h-0.5v-3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l-0.9,0.3l-0.1-0.4l1-0.4H340.3z"
          fill="#FFFFFF"
        />
        <path
          d="M342.4,70.6c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.3,0.3,0.4,0.6s0.1,0.7,0.1,1.1c0,0.5,0,0.8-0.1,1.1
      s-0.2,0.5-0.4,0.6c-0.2,0.1-0.4,0.2-0.8,0.2c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.3-0.3-0.4-0.6s-0.1-0.7-0.1-1.1
      c0-0.5,0-0.8,0.1-1.1s0.2-0.5,0.4-0.6C341.8,70.6,342.1,70.6,342.4,70.6z M342.4,71c-0.2,0-0.3,0-0.4,0.1s-0.2,0.1-0.3,0.3
      c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.2,0,0.4,0,0.7c0,0.3,0,0.5,0,0.7c0,0.2,0.1,0.4,0.1,0.5c0.1,0.1,0.1,0.2,0.3,0.3
      c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.5s0-0.4,0-0.7c0-0.3,0-0.5,0-0.7
      s-0.1-0.3-0.1-0.5c-0.1-0.1-0.1-0.2-0.3-0.3S342.5,71,342.4,71z"
          fill="#FFFFFF"
        />
        <path
          d="M345.5,70.6c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.3,0.3,0.4,0.6s0.1,0.7,0.1,1.1c0,0.5,0,0.8-0.1,1.1
      s-0.2,0.5-0.4,0.6c-0.2,0.1-0.4,0.2-0.8,0.2c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.3-0.3-0.4-0.6s-0.1-0.7-0.1-1.1
      c0-0.5,0-0.8,0.1-1.1s0.2-0.5,0.4-0.6C345,70.6,345.2,70.6,345.5,70.6z M345.5,71c-0.2,0-0.3,0-0.4,0.1s-0.2,0.1-0.3,0.3
      c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.2,0,0.4,0,0.7c0,0.3,0,0.5,0,0.7c0,0.2,0.1,0.4,0.1,0.5c0.1,0.1,0.1,0.2,0.3,0.3
      c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.5s0-0.4,0-0.7c0-0.3,0-0.5,0-0.7
      s-0.1-0.3-0.1-0.5c-0.1-0.1-0.1-0.2-0.3-0.3S345.7,71,345.5,71z"
          fill="#FFFFFF"
        />
        <path
          d="M349,71.7l0,0.5l0,0.1v2h-0.5v-2.6H349z M350.1,71.7c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v2
      h-0.4v-1.8c0-0.2,0-0.3-0.1-0.4s-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.2-0.4,0.3l0-0.3
      c0.2-0.2,0.4-0.3,0.6-0.4S349.9,71.7,350.1,71.7z M351.8,71.7c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v2H352v-1.8
      c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.4,0.3l0-0.3
      c0.2-0.2,0.4-0.3,0.6-0.4C351.4,71.7,351.6,71.7,351.8,71.7z"
          fill="#FFFFFF"
        />
        <path
          d="M353.7,70.6l0,3.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.3l0.1,0.4c0,0-0.1,0-0.1,0s-0.1,0-0.2,0
      c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-3.2H353.7z"
          fill="#FFFFFF"
        />
        <path
          d="M354.4,70.1c0.5,0.2,0.9,0.5,1.1,0.9c0.2,0.4,0.3,1,0.3,1.7c0,0.7-0.1,1.2-0.3,1.7
      c-0.2,0.4-0.6,0.7-1.1,0.9l-0.1-0.2c0.2-0.1,0.4-0.3,0.6-0.5s0.3-0.5,0.3-0.8s0.1-0.7,0.1-1.1c0-0.6-0.1-1.1-0.2-1.5
      c-0.2-0.4-0.4-0.7-0.8-0.9L354.4,70.1z"
          fill="#FFFFFF"
        />
        <path
          d="M356.7,72c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1v0.3c0,0.1,0,0.1,0,0.1c0,0-0.1,0-0.1,0h-0.2
      c-0.1,0-0.1,0-0.1,0s0-0.1,0-0.1v-0.3c0-0.1,0-0.1,0-0.1s0.1,0,0.1,0H356.7z M356.7,73.7c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1v0.3
      c0,0.1,0,0.1,0,0.1c0,0-0.1,0-0.1,0h-0.2c-0.1,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1v-0.3c0-0.1,0-0.1,0-0.1c0,0,0.1,0,0.1,0H356.7z"
          fill="#FFFFFF"
        />
        <path
          d="M359.8,70.6v3.7h-0.5v-3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l-0.9,0.3l-0.1-0.4l1-0.4H359.8z"
          fill="#FFFFFF"
        />
        <path
          d="M362.1,70.6c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0.1l0,0.3c-0.1,0-0.2,0-0.2,0s-0.1,0-0.2,0
      c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.3-0.2,0.5c0,0.2,0,0.5,0,0.9c0,0.3,0,0.5,0,0.7
      s0,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.3s0-0.3,0-0.5
      c0-0.2,0-0.4-0.1-0.5s-0.1-0.2-0.2-0.3s-0.2-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.4,0.3
      l0-0.2c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.4-0.2s0.3-0.1,0.5-0.1c0.3,0,0.5,0,0.6,0.1s0.3,0.2,0.3,0.4
      c0.1,0.2,0.1,0.4,0.1,0.7c0,0.2,0,0.4,0,0.5c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.6,0.1
      c-0.3,0-0.5,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.3-0.2-0.6c0-0.2-0.1-0.5-0.1-0.9c0-0.4,0-0.8,0.1-1.1
      s0.1-0.5,0.3-0.6c0.1-0.1,0.3-0.2,0.5-0.3C361.6,70.6,361.8,70.6,362.1,70.6z"
          fill="#FFFFFF"
        />
        <path
          d="M364.7,70.6c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0.1,0.4,0.2s0.1,0.3,0.1,0.5c0,0.2,0,0.3-0.1,0.4
      c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.3-0.4,0.5l-1.1,1.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0h1.3v0.5h-2.4v-0.3
      c0,0,0-0.1,0-0.1s0-0.1,0.1-0.1l1.1-1.2c0.2-0.3,0.4-0.5,0.5-0.7c0.1-0.2,0.2-0.4,0.2-0.6c0-0.1,0-0.2-0.1-0.3s-0.1-0.1-0.2-0.1
      c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.4,0.1l0-0.4c0.2,0,0.3-0.1,0.4-0.1c0.1,0,0.2,0,0.4,0
      C364.5,70.6,364.6,70.6,364.7,70.6z"
          fill="#FFFFFF"
        />
        <path
          d="M368.2,70.6l0,1.7c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3v0.9h-0.5v-3.8
      H368.2z M368.6,72.7v0.3H368v-0.3H368.6z M369.8,71.7l-0.8,1c0,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1v0c0,0,0.1,0,0.1,0.1
      s0.1,0.1,0.1,0.1l0.9,1.3h-0.5l-1-1.5l1-1.1H369.8z"
          fill="#FFFFFF"
        />
        <path
          d="M371.3,70.6v2.9c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.3,0.2-0.5,0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
      s-0.1,0-0.1,0l0.1-0.3h0.3c0.3,0,0.4-0.1,0.4-0.4v-3H371.3z"
          fill="#FFFFFF"
        />
        <path d="M375.4,70.6l-2.1,4.1h-0.4l2.1-4.1H375.4z" fill="#FFFFFF" />
        <path
          d="M377.9,70.6c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.3,0.1,0.4,0.3s0.1,0.3,0.1,0.5c0,0.2,0,0.3,0,0.4
      s-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.2v0c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.1,0.4,0.1,0.6c0,0.3,0,0.5-0.1,0.6
      c-0.1,0.2-0.2,0.3-0.4,0.4s-0.4,0.1-0.7,0.1c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6-0.1l0-0.4c0.1,0,0.2,0,0.4,0s0.2,0,0.3,0
      c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.5-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.3-0.1-0.4s-0.1-0.2-0.2-0.2
      s-0.3-0.1-0.5-0.1h-0.7v-0.4h0.7c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3
      c0-0.2,0-0.3-0.1-0.4c0-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.4,0l0-0.4
      c0.2,0,0.4-0.1,0.5-0.1C377.6,70.6,377.7,70.6,377.9,70.6z"
          fill="#FFFFFF"
        />
        <path
          d="M380.9,70.6c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.2,0.6c0,0.2,0,0.5,0,0.9
      c0,0.4,0,0.8-0.1,1c-0.1,0.3-0.1,0.5-0.3,0.6s-0.3,0.2-0.4,0.3s-0.4,0.1-0.7,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.5-0.1l0-0.4
      c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0s0.2,0,0.3,0c0.2,0,0.3,0,0.5-0.1s0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.3,0.2-0.5s0-0.5,0-0.8
      c0-0.3,0-0.5,0-0.7c0-0.2,0-0.3-0.1-0.5c0-0.1-0.1-0.2-0.2-0.2s-0.2-0.1-0.4-0.1c-0.2,0-0.4,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.2,0.3
      c0,0.1-0.1,0.3-0.1,0.5c0,0.2,0,0.3,0.1,0.5c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0
      c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.4-0.3l0,0.2c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.4,0.2s-0.3,0.1-0.4,0.1
      c-0.3,0-0.5-0.1-0.6-0.2s-0.3-0.2-0.3-0.4s-0.1-0.4-0.1-0.6c0-0.3,0-0.5,0.1-0.7c0.1-0.2,0.2-0.3,0.4-0.4
      C380.4,70.6,380.6,70.6,380.9,70.6z"
          fill="#FFFFFF"
        />
        <path
          d="M384.4,70.6l0,1.7c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3v0.9h-0.5v-3.8
      H384.4z M384.8,72.7v0.3h-0.6v-0.3H384.8z M386.1,71.7l-0.8,1c0,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1v0c0,0,0.1,0,0.1,0.1
      s0.1,0.1,0.1,0.1l0.9,1.3h-0.5l-1-1.5l1-1.1H386.1z"
          fill="#FFFFFF"
        />
        <path
          d="M387.6,71.7c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0.1l-0.1,0.3c-0.1,0-0.2,0-0.4,0
      c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0-0.5,0.1s-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.6s0,0.5,0.1,0.6c0.1,0.1,0.1,0.2,0.3,0.3
      s0.3,0.1,0.5,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0l0,0.3c-0.1,0-0.3,0.1-0.4,0.1s-0.3,0-0.5,0
      c-0.3,0-0.5,0-0.7-0.1s-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.5-0.1-0.8s0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.4-0.4
      C387,71.7,387.3,71.7,387.6,71.7z"
          fill="#FFFFFF"
        />
        <path
          d="M390.9,72.8l0,0.3l-1.3,0c-0.1,0-0.2,0-0.3,0.1c0,0.1-0.1,0.2-0.1,0.3v0.2c0,0.1,0,0.2,0.1,0.3
      c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.4-0.3v0.3c0,0.1-0.1,0.1-0.2,0.2
      c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1s-0.2-0.1-0.3-0.2
      c-0.1-0.1-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H390.9z M390.2,71.7c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.9h-0.4l-0.1-0.6l0-0.1v-1.2c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l0-0.3c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3,0,0.4,0C390,71.7,390.1,71.7,390.2,71.7z"
          fill="#FFFFFF"
        />
        <path
          d="M392.3,70.6l0,3.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h0.3l0.1,0.4c0,0-0.1,0-0.1,0s-0.1,0-0.2,0
      c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-3.2H392.3z"
          fill="#FFFFFF"
        />
      </g>
      <g>
        <path
          d="M324,41.1c0.5,0,0.8,0.1,1.1,0.2c0.3,0.2,0.5,0.4,0.6,0.8c0.1,0.4,0.2,0.8,0.2,1.4c0,0.6-0.1,1.1-0.2,1.4
      c-0.1,0.4-0.3,0.6-0.6,0.8c-0.3,0.2-0.6,0.2-1.1,0.2s-0.8-0.1-1.1-0.2c-0.3-0.2-0.5-0.4-0.6-0.8s-0.2-0.8-0.2-1.4
      c0-0.6,0.1-1.1,0.2-1.4c0.1-0.4,0.3-0.6,0.6-0.8C323.2,41.1,323.6,41.1,324,41.1z M324,41.9c-0.2,0-0.3,0-0.4,0.1
      c-0.1,0.1-0.2,0.1-0.2,0.3s-0.1,0.3-0.1,0.5c0,0.2,0,0.5,0,0.8c0,0.3,0,0.6,0,0.8c0,0.2,0.1,0.4,0.1,0.5c0.1,0.1,0.1,0.2,0.2,0.3
      c0.1,0,0.2,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.2-0.3c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2,0-0.5,0-0.8
      c0-0.3,0-0.6,0-0.8c0-0.2-0.1-0.4-0.1-0.5s-0.1-0.2-0.2-0.3C324.3,41.9,324.2,41.9,324,41.9z"
          fill="#FFFFFF"
        />
        <path
          d="M327.1,44.8c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.3v0.4c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.1,0.4
      c0,0.1-0.1,0.2-0.2,0.4l-0.4-0.1c0-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.3,0-0.4c-0.2,0-0.3-0.1-0.3-0.3l0-0.4c0-0.1,0-0.2,0.1-0.3
      c0.1-0.1,0.1-0.1,0.3-0.1H327.1z"
          fill="#FFFFFF"
        />
        <path
          d="M329.7,41.1c0.4,0,0.7,0,0.9,0.1s0.4,0.2,0.5,0.3c0.1,0.2,0.2,0.4,0.2,0.7c0,0.2,0,0.4-0.1,0.6
      s-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2v0c0.3,0.1,0.5,0.2,0.7,0.4s0.2,0.5,0.2,0.8c0,0.3,0,0.6-0.2,0.8s-0.3,0.4-0.5,0.5
      c-0.2,0.1-0.6,0.1-1,0.1c-0.3,0-0.6,0-0.8,0c-0.3,0-0.5-0.1-0.8-0.1l0-0.8c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0
      c0.2,0,0.4,0,0.5-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.2s-0.3-0.1-0.5-0.1
      l-0.9,0v-0.7h0.9c0.2,0,0.3,0,0.4-0.1s0.2-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3-0.1-0.3
      c0-0.1-0.1-0.1-0.2-0.2s-0.3,0-0.4,0c-0.2,0-0.5,0-0.7,0s-0.4,0-0.6,0l0-0.7c0.3,0,0.5-0.1,0.8-0.1
      C329.2,41.1,329.4,41.1,329.7,41.1z"
          fill="#FFFFFF"
        />
        <path
          d="M333.6,41.1c0.4,0,0.7,0,0.9,0.1s0.4,0.2,0.5,0.3c0.1,0.2,0.2,0.4,0.2,0.7c0,0.2,0,0.4-0.1,0.6
      s-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2v0c0.3,0.1,0.5,0.2,0.7,0.4s0.2,0.5,0.2,0.8c0,0.3,0,0.6-0.2,0.8s-0.3,0.4-0.5,0.5
      c-0.2,0.1-0.6,0.1-1,0.1c-0.3,0-0.6,0-0.8,0c-0.3,0-0.5-0.1-0.8-0.1l0-0.8c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0
      c0.2,0,0.4,0,0.5-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.2s-0.3-0.1-0.5-0.1
      l-0.9,0v-0.7h0.9c0.2,0,0.3,0,0.4-0.1s0.2-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3-0.1-0.3
      c0-0.1-0.1-0.1-0.2-0.2s-0.3,0-0.4,0c-0.2,0-0.5,0-0.7,0s-0.4,0-0.6,0l0-0.7c0.3,0,0.5-0.1,0.8-0.1
      C333.1,41.1,333.3,41.1,333.6,41.1z"
          fill="#FFFFFF"
        />
        <path
          d="M337.1,41.2v3.5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h1.7l0,0.8c-0.3,0-0.7,0.1-1,0.1
      c-0.4,0-0.7,0-1.1,0c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-3.8H337.1z"
          fill="#FFFFFF"
        />
        <path
          d="M267.8,49.3c0.4,0,0.7,0,1,0.1s0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.3,0.8c0.1,0.3,0.1,0.7,0.1,1.2
      c0,0.5,0,0.9-0.1,1.2c-0.1,0.3-0.2,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.6,0.4c-0.3,0.1-0.6,0.1-1,0.1c-0.4,0-0.7,0-1-0.1
      c-0.3-0.1-0.5-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.3-0.8c-0.1-0.3-0.1-0.7-0.1-1.2c0-0.5,0-0.9,0.1-1.2s0.2-0.6,0.3-0.8
      c0.2-0.2,0.4-0.3,0.6-0.4C267.1,49.3,267.4,49.3,267.8,49.3z M267.8,50.1c-0.2,0-0.4,0-0.6,0.1c-0.1,0.1-0.2,0.3-0.3,0.5
      c-0.1,0.2-0.1,0.6-0.1,1c0,0.4,0,0.7,0.1,1c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.3,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1
      c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.6,0.1-1c0-0.4,0-0.8-0.1-1c-0.1-0.2-0.2-0.4-0.3-0.5C268.2,50.2,268,50.1,267.8,50.1z"
          fill="#FFFFFF"
        />
        <path
          d="M271.7,49.4v3.5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h1.7l0,0.8c-0.3,0-0.7,0.1-1,0.1
      c-0.4,0-0.7,0-1.1,0c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-3.8H271.7z"
          fill="#FFFFFF"
        />
        <path
          d="M278,49.4v2.8c0,0.5-0.1,0.9-0.2,1.2c-0.1,0.3-0.3,0.5-0.6,0.6c-0.3,0.1-0.6,0.2-1.1,0.2
      c-0.5,0-0.9-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.6-0.6c-0.1-0.3-0.2-0.7-0.2-1.2v-2.8h1v2.8c0,0.3,0,0.5,0.1,0.7
      c0.1,0.2,0.1,0.3,0.3,0.4s0.3,0.1,0.5,0.1s0.4,0,0.5-0.1s0.2-0.2,0.3-0.4c0.1-0.2,0.1-0.4,0.1-0.7v-2.8H278z"
          fill="#FFFFFF"
        />
        <path d="M282.5,49.4v0.8h-3.9v-0.8H282.5z M281,49.4v4.7h-1v-4.7H281z" fill="#FFFFFF" />
        <path
          d="M286.5,49.4c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2l1.4,4.5h-1.1l-1-3.5c0-0.1,0-0.2-0.1-0.2
      c0-0.1,0-0.2,0-0.2h-0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2-0.1,0.2l-1,3.5h-1.1l1.4-4.5c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1-0.1,0.2-0.1
      H286.5z M287.2,52.1v0.8h-2.5v-0.8H287.2z"
          fill="#FFFFFF"
        />
        <path
          d="M289.7,49.4v3.5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h1.7l0,0.8c-0.3,0-0.7,0.1-1,0.1
      c-0.4,0-0.7,0-1.1,0c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-3.8H289.7z"
          fill="#FFFFFF"
        />
        <path
          d="M293.4,49.4v1.3c0,0.2,0,0.3,0,0.5c0,0.2-0.1,0.3-0.1,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.3,0,0.4v1.6h-1
      v-4.7H293.4z M294,51.3V52h-0.9v-0.7H294z M296.1,49.4l-1.2,1.9c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2v0
      c0.1,0,0.1,0.1,0.2,0.2s0.1,0.2,0.2,0.2l1.3,2H295l-1.5-2.5l1.4-2.3H296.1z"
          fill="#FFFFFF"
        />
        <path
          d="M297.2,53.1c0.1,0,0.2,0,0.3,0.1s0.1,0.1,0.1,0.3v0.3c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.3,0.1h-0.3
      c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.1-0.1-0.3v-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.3-0.1H297.2z"
          fill="#FFFFFF"
        />
        <path
          d="M301.8,49.4l-1.1,2.7c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.1h2.7V53l-0.6,0.1h-2.9c-0.1,0-0.2,0-0.3-0.1
      c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.2,0-0.3l1.2-3H301.8z M303,50.3v3.8h-1v-3.1l0.2-0.8H303z"
          fill="#FFFFFF"
        />
        <path
          d="M304.7,53.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.3v0.4c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.1,0.4
      c0,0.1-0.1,0.2-0.2,0.4l-0.4-0.1c0-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.3,0-0.4c-0.2,0-0.3-0.1-0.3-0.3l0-0.4c0-0.1,0-0.2,0.1-0.3
      c0.1-0.1,0.1-0.1,0.3-0.1H304.7z"
          fill="#FFFFFF"
        />
        <path
          d="M308.2,49.4c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.2,0.2s0,0.2,0,0.3l-1.4,4.2h-1l1.2-3.4
      c0-0.1,0.1-0.2,0.1-0.2s0.1-0.1,0.1-0.2h-2.2l0-0.9H308.2z"
          fill="#FFFFFF"
        />
        <path d="M314.2,49.4v0.8h-3.9v-0.8H314.2z M312.7,49.4v4.7h-1v-4.7H312.7z" fill="#FFFFFF" />
        <path d="M315.8,49.4v4.7h-1v-4.7H315.8z" fill="#FFFFFF" />
        <path
          d="M317.8,49.4v3.5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h1.7l0,0.8c-0.3,0-0.7,0.1-1,0.1
      c-0.4,0-0.7,0-1.1,0c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-3.8H317.8z"
          fill="#FFFFFF"
        />
        <path
          d="M320.9,53.1c0.1,0,0.2,0,0.3,0.1s0.1,0.1,0.1,0.3v0.3c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.3,0.1h-0.3
      c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.1-0.1-0.3v-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.3-0.1H320.9z"
          fill="#FFFFFF"
        />
        <path d="M324.1,52v0.6h-2V52H324.1z" fill="#FFFFFF" />
        <path
          d="M325.9,49.3c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.5,0.1,0.8c0,0.3,0,0.6-0.1,0.8
      c-0.1,0.2-0.2,0.4-0.4,0.4c-0.2,0.1-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.5-0.1-0.8
      c0-0.4,0-0.6,0.1-0.8c0.1-0.2,0.2-0.4,0.4-0.4C325.3,49.3,325.6,49.3,325.9,49.3z M325.9,49.8c-0.1,0-0.2,0-0.3,0.1
      s-0.1,0.1-0.1,0.2s0,0.3,0,0.5c0,0.2,0,0.4,0,0.5s0.1,0.2,0.1,0.2s0.1,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.1-0.2
      c0-0.1,0-0.3,0-0.5c0-0.2,0-0.4,0-0.5c0-0.1-0.1-0.2-0.1-0.2C326.1,49.9,326,49.8,325.9,49.8z M329.3,49.4l-2.8,4.7h-0.6l2.8-4.7
      H329.3z M329.3,51.4c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.5,0.1,0.8c0,0.3,0,0.6-0.1,0.8s-0.2,0.4-0.4,0.4
      c-0.2,0.1-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.4,0-0.6,0.1-0.8
      c0.1-0.2,0.2-0.3,0.4-0.4C328.8,51.4,329,51.4,329.3,51.4z M329.3,51.9c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.1,0.2
      c0,0.1,0,0.3,0,0.5c0,0.2,0,0.4,0,0.5c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.1,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.1-0.2
      s0-0.3,0-0.5c0-0.2,0-0.4,0-0.5c0-0.1-0.1-0.2-0.1-0.2C329.5,52,329.4,51.9,329.3,51.9z"
          fill="#FFFFFF"
        />
        <path d="M333.4,48.8v6.5h-0.7v-6.5H333.4z" fill="#FFFFFF" />
        <path
          d="M337.8,49.3c0.4,0,0.7,0,1,0.1s0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.3,0.8c0.1,0.3,0.1,0.7,0.1,1.2
      c0,0.5,0,0.9-0.1,1.2c-0.1,0.3-0.2,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.6,0.4c-0.3,0.1-0.6,0.1-1,0.1c-0.4,0-0.7,0-1-0.1
      c-0.3-0.1-0.5-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.3-0.8c-0.1-0.3-0.1-0.7-0.1-1.2c0-0.5,0-0.9,0.1-1.2s0.2-0.6,0.3-0.8
      c0.2-0.2,0.4-0.3,0.6-0.4C337.1,49.3,337.4,49.3,337.8,49.3z M337.3,48c0.1,0,0.2,0.1,0.2,0.2v0.4c0,0.1-0.1,0.2-0.2,0.2H337
      c-0.1,0-0.2-0.1-0.2-0.2v-0.4c0-0.1,0.1-0.2,0.2-0.2H337.3z M337.8,50.1c-0.2,0-0.4,0-0.6,0.1c-0.1,0.1-0.2,0.3-0.3,0.5
      c-0.1,0.2-0.1,0.6-0.1,1c0,0.4,0,0.7,0.1,1c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.3,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1
      c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.6,0.1-1c0-0.4,0-0.8-0.1-1c-0.1-0.2-0.2-0.4-0.3-0.5C338.2,50.2,338,50.1,337.8,50.1z
       M338.6,48c0.1,0,0.2,0.1,0.2,0.2v0.4c0,0.1-0.1,0.2-0.2,0.2h-0.3c-0.1,0-0.2-0.1-0.2-0.2v-0.4c0-0.1,0.1-0.2,0.2-0.2H338.6z"
          fill="#FFFFFF"
        />
        <path
          d="M341.7,49.4v3.5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h1.7l0,0.8c-0.3,0-0.7,0.1-1,0.1
      c-0.4,0-0.7,0-1.1,0c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-3.8H341.7z"
          fill="#FFFFFF"
        />
        <path d="M347.5,49.4l-2.7,5.2H344l2.7-5.2H347.5z" fill="#FFFFFF" />
        <path
          d="M349.9,49.3c0.4,0,0.7,0,1,0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.4,0.2,0.8c0,0.3-0.1,0.6-0.2,0.8
      c-0.1,0.2-0.3,0.3-0.6,0.3v0c0.4,0,0.6,0.2,0.8,0.4c0.1,0.2,0.2,0.5,0.2,0.9c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.3-0.5,0.4
      c-0.2,0.1-0.5,0.1-0.9,0.1c-0.3,0-0.6,0-0.8,0c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0l0.1-0.7c0.1,0,0.3,0,0.6,0
      c0.3,0,0.7,0,1.1,0c0.2,0,0.3,0,0.5-0.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.2
      c-0.1,0-0.3-0.1-0.5-0.1h-1.7v-0.7h1.7c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.3-0.1-0.4
      c0-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.3,0-0.4,0c-0.3,0-0.5,0-0.7,0s-0.4,0-0.5,0c-0.2,0-0.3,0-0.4,0l-0.1-0.7c0.2,0,0.4,0,0.5,0
      c0.2,0,0.4,0,0.6,0C349.4,49.3,349.6,49.3,349.9,49.3z M349.1,49.4v4.7h-1v-4.7H349.1z"
          fill="#FFFFFF"
        />
        <path
          d="M353.4,49.4c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.7,0l0,0.8h-1.8c-0.1,0-0.2,0-0.3,0.1
      c-0.1,0.1-0.1,0.1-0.1,0.3V53c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.3,0.1h1.8l0,0.8c-0.2,0-0.5,0-0.7,0c-0.3,0-0.5,0-0.8,0
      c-0.3,0-0.5,0-0.8,0c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-2.9c0-0.3,0.1-0.5,0.3-0.7
      C352.9,49.4,353.1,49.4,353.4,49.4z M352.6,51.3h2.8V52h-2.8V51.3z"
          fill="#FFFFFF"
        />
        <path
          d="M357.4,49.4c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.7,0l0,0.8h-1.8c-0.1,0-0.2,0-0.3,0.1
      c-0.1,0.1-0.1,0.1-0.1,0.3V53c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.3,0.1h1.8l0,0.8c-0.2,0-0.5,0-0.7,0c-0.3,0-0.5,0-0.8,0
      c-0.3,0-0.5,0-0.8,0c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-2.9c0-0.3,0.1-0.5,0.3-0.7
      C356.9,49.4,357.1,49.4,357.4,49.4z M356.5,51.3h2.8V52h-2.8V51.3z"
          fill="#FFFFFF"
        />
        <path
          d="M362.2,49.3c0.4,0,0.8,0,1,0.1c0.3,0.1,0.4,0.2,0.6,0.4s0.2,0.5,0.2,0.8c0,0.2,0,0.4-0.1,0.6
      c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.5,0.2v0c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.4
      l0.4,1.5h-1.1l-0.4-1.4c0-0.2-0.1-0.3-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.3,0-0.6,0-0.8,0c-0.2,0-0.4,0-0.5,0s-0.3,0-0.4,0
      l0-0.8h1.7c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.2
      s-0.3-0.1-0.4-0.1c-0.4,0-0.8,0-1.1,0c-0.3,0-0.5,0-0.6,0l-0.1-0.7c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.6,0
      C361.7,49.3,361.9,49.3,362.2,49.3z M361.4,49.4v4.7h-1v-4.7H361.4z"
          fill="#FFFFFF"
        />
        <path
          d="M368.3,49.4l-1.1,2.7c0,0.1,0,0.1,0,0.2c0,0,0.1,0.1,0.1,0.1h2.7V53l-0.6,0.1h-2.9c-0.1,0-0.2,0-0.3-0.1
      c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.2,0-0.3l1.2-3H368.3z M369.4,50.3v3.8h-1v-3.1l0.2-0.8H369.4z"
          fill="#FFFFFF"
        />
        <path
          d="M371.1,53.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.3v0.4c0,0.2,0,0.3,0,0.5c0,0.1-0.1,0.3-0.1,0.4
      c0,0.1-0.1,0.2-0.2,0.4l-0.4-0.1c0-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.3,0-0.4c-0.2,0-0.3-0.1-0.3-0.3l0-0.4c0-0.1,0-0.2,0.1-0.3
      c0.1-0.1,0.1-0.1,0.3-0.1H371.1z"
          fill="#FFFFFF"
        />
        <path
          d="M374.7,49.4c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.2,0.2s0,0.2,0,0.3l-1.4,4.2h-1l1.2-3.4
      c0-0.1,0.1-0.2,0.1-0.2s0.1-0.1,0.1-0.2h-2.2l0-0.9H374.7z"
          fill="#FFFFFF"
        />
        <path
          d="M380.9,49.4l-1.3,4.4c0,0.1-0.1,0.2-0.1,0.2s-0.1,0.1-0.2,0.1h-1c-0.1,0-0.2,0-0.2-0.1
      c-0.1-0.1-0.1-0.1-0.1-0.2l-1.3-4.4h1.1l0.9,3.4c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.3h0.2c0-0.1,0-0.2,0.1-0.3
      c0-0.1,0-0.2,0.1-0.3l0.9-3.4H380.9z"
          fill="#FFFFFF"
        />
        <path
          d="M383.3,49.3c0.4,0,0.7,0,1,0.1s0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.3,0.8c0.1,0.3,0.1,0.7,0.1,1.2
      c0,0.5,0,0.9-0.1,1.2c-0.1,0.3-0.2,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.6,0.4c-0.3,0.1-0.6,0.1-1,0.1c-0.4,0-0.7,0-1-0.1
      c-0.3-0.1-0.5-0.2-0.6-0.4c-0.2-0.2-0.3-0.4-0.3-0.8c-0.1-0.3-0.1-0.7-0.1-1.2c0-0.5,0-0.9,0.1-1.2s0.2-0.6,0.3-0.8
      c0.2-0.2,0.4-0.3,0.6-0.4C382.6,49.3,382.9,49.3,383.3,49.3z M383.3,50.1c-0.2,0-0.4,0-0.6,0.1c-0.1,0.1-0.2,0.3-0.3,0.5
      c-0.1,0.2-0.1,0.6-0.1,1c0,0.4,0,0.7,0.1,1c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.3,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1
      c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.6,0.1-1c0-0.4,0-0.8-0.1-1c-0.1-0.2-0.2-0.4-0.3-0.5C383.7,50.2,383.5,50.1,383.3,50.1z"
          fill="#FFFFFF"
        />
        <path
          d="M387.1,49.4v3.5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1h1.7l0,0.8c-0.3,0-0.7,0.1-1,0.1
      c-0.4,0-0.7,0-1.1,0c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-3.8H387.1z"
          fill="#FFFFFF"
        />
        <path
          d="M390.7,49.3c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.5,0.1,0.8c0,0.3,0,0.6-0.1,0.8
      c-0.1,0.2-0.2,0.4-0.4,0.4c-0.2,0.1-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.5-0.1-0.8
      c0-0.4,0-0.6,0.1-0.8c0.1-0.2,0.2-0.4,0.4-0.4C390.2,49.3,390.4,49.3,390.7,49.3z M390.7,49.8c-0.1,0-0.2,0-0.3,0.1
      s-0.1,0.1-0.1,0.2s0,0.3,0,0.5c0,0.2,0,0.4,0,0.5s0.1,0.2,0.1,0.2s0.1,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.1-0.2
      c0-0.1,0-0.3,0-0.5c0-0.2,0-0.4,0-0.5c0-0.1-0.1-0.2-0.1-0.2C390.9,49.9,390.8,49.8,390.7,49.8z M394.1,49.4l-2.8,4.7h-0.6
      l2.8-4.7H394.1z M394.1,51.4c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.5,0.1,0.8c0,0.3,0,0.6-0.1,0.8s-0.2,0.4-0.4,0.4
      c-0.2,0.1-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.4,0-0.6,0.1-0.8
      c0.1-0.2,0.2-0.3,0.4-0.4C393.6,51.4,393.8,51.4,394.1,51.4z M394.1,51.9c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.1,0.2
      c0,0.1,0,0.3,0,0.5c0,0.2,0,0.4,0,0.5c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.1,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.1-0.2
      s0-0.3,0-0.5c0-0.2,0-0.4,0-0.5c0-0.1-0.1-0.2-0.1-0.2C394.3,52,394.2,51.9,394.1,51.9z"
          fill="#FFFFFF"
        />
      </g>
      <g>
        <path
          d="M268.9,119.8l-0.9,3.2c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.2,0.1H267c-0.1,0-0.1,0-0.2-0.1
      c0,0-0.1-0.1-0.1-0.2l-0.9-3.2h0.8l0.6,2.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2h0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l0.6-2.5
      H268.9z"
          fill="#FFFFFF"
        />
        <path
          d="M271,121.8l0,0.4l-1.1,0c-0.1,0-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2v0.1c0,0.1,0,0.1,0.1,0.2
      c0,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2v0.3c0,0.1-0.1,0.1-0.1,0.2
      c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
      s-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H271z M270.3,120.8c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.3,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.6h-0.6l-0.1-0.5l0-0.1v-1c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l-0.1-0.5c0.1,0,0.3,0,0.4-0.1c0.2,0,0.3,0,0.5,0C270,120.8,270.2,120.8,270.3,120.8z"
          fill="#FFFFFF"
        />
        <path
          d="M272.5,119.8l0,2.7c0,0.1,0,0.1,0.1,0.2s0.1,0.1,0.2,0.1h0.2l0.1,0.5c0,0-0.1,0-0.2,0.1
      c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-2.9H272.5z"
          fill="#FFFFFF"
        />
        <path
          d="M273.9,120.8l0.1,0.5l0.1,0.1v1.9h-0.7v-2.5H273.9z M274.9,120.8c0.2,0,0.4,0.1,0.5,0.2s0.2,0.3,0.2,0.5
      v1.8h-0.7v-1.6c0-0.1,0-0.2-0.1-0.2c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.2l0-0.4
      c0.2-0.2,0.3-0.3,0.5-0.4C274.5,120.8,274.7,120.8,274.9,120.8z M276.4,120.8c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v1.8
      h-0.7v-1.6c0-0.1,0-0.2-0.1-0.2s-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1l0-0.4
      c0.2-0.2,0.3-0.3,0.5-0.4C276.1,120.8,276.2,120.8,276.4,120.8z"
          fill="#FFFFFF"
        />
        <path
          d="M278.2,119.7c0.1,0,0.2,0.1,0.2,0.2v0.3c0,0.2-0.1,0.2-0.2,0.2h-0.3c-0.1,0-0.2-0.1-0.2-0.2V120
      c0-0.2,0.1-0.2,0.2-0.2H278.2z M278.4,120.8v2.5h-0.7v-2.5H278.4z"
          fill="#FFFFFF"
        />
        <path
          d="M279.8,120.8c0.1,0,0.2,0,0.4,0s0.3,0,0.4,0c0.1,0,0.3,0,0.4,0l0,0.5c-0.2,0-0.3,0-0.5,0
      c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.1,0s0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1l0.6,0.2
      c0.2,0.1,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.4,0.1-0.6,0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.7-0.1l0.1-0.5c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.4,0
      c0.1,0,0.2,0,0.2-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.1s-0.1-0.1-0.3-0.1l-0.6-0.2c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.1-0.2-0.2
      s-0.1-0.2-0.1-0.3c0-0.2,0-0.3,0.1-0.4s0.2-0.2,0.3-0.2C279.4,120.8,279.6,120.8,279.8,120.8z"
          fill="#FFFFFF"
        />
        <path
          d="M283.1,120.8v0.5h-1.9v-0.5l0.5,0H283.1z M282.4,120.1v2.4c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0.1,0.2,0.1h0.3
      l0.1,0.5c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2s-0.2-0.3-0.2-0.5l0-2.5H282.4z"
          fill="#FFFFFF"
        />
        <path
          d="M285.4,121.8l0,0.4l-1.1,0c-0.1,0-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2v0.1c0,0.1,0,0.1,0.1,0.2
      c0,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2v0.3c0,0.1-0.1,0.1-0.1,0.2
      c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
      s-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H285.4z M284.7,120.8c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.3,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.6h-0.6l-0.1-0.5l0-0.1v-1c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l-0.1-0.5c0.1,0,0.3,0,0.4-0.1c0.2,0,0.3,0,0.5,0C284.4,120.8,284.6,120.8,284.7,120.8z"
          fill="#FFFFFF"
        />
        <path
          d="M286.9,120.8v2.4c0,0.2,0,0.3-0.1,0.4s-0.1,0.3-0.2,0.4s-0.2,0.2-0.3,0.3l-0.4-0.3
      c0.1-0.1,0.2-0.2,0.2-0.4s0.1-0.3,0.1-0.4v-2.4H286.9z M286.7,119.7c0.1,0,0.2,0.1,0.2,0.2v0.3c0,0.1-0.1,0.2-0.2,0.2h-0.3
      c-0.1,0-0.2-0.1-0.2-0.2v-0.3c0-0.1,0.1-0.2,0.2-0.2H286.7z"
          fill="#FFFFFF"
        />
        <path
          d="M289.4,121.8l0,0.4l-1.1,0c-0.1,0-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2v0.1c0,0.1,0,0.1,0.1,0.2
      c0,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2v0.3c0,0.1-0.1,0.1-0.1,0.2
      c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
      s-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H289.4z M288.7,120.8c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.3,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.6h-0.6l-0.1-0.5l0-0.1v-1c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l-0.1-0.5c0.1,0,0.3,0,0.4-0.1c0.2,0,0.3,0,0.5,0C288.5,120.8,288.6,120.8,288.7,120.8z"
          fill="#FFFFFF"
        />
        <path d="M292.5,119.8l-2,3.8H290l2-3.8H292.5z" fill="#FFFFFF" />
        <path d="M295.5,119.8v0.6h-2.8v-0.6H295.5z M294.4,119.8v3.5h-0.8v-3.5H294.4z" fill="#FFFFFF" />
        <path
          d="M296.3,119.7c0.1,0,0.2,0.1,0.2,0.2v0.3c0,0.2-0.1,0.2-0.2,0.2H296c-0.1,0-0.2-0.1-0.2-0.2V120
      c0-0.2,0.1-0.2,0.2-0.2H296.3z M296.5,120.8v2.5h-0.7v-2.5H296.5z"
          fill="#FFFFFF"
        />
        <path
          d="M297.8,119.8l0,2.7c0,0.1,0,0.1,0.1,0.2s0.1,0.1,0.2,0.1h0.2l0.1,0.5c0,0-0.1,0-0.2,0.1
      c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-2.9H297.8z"
          fill="#FFFFFF"
        />
        <path
          d="M299.3,119.8l0,2.7c0,0.1,0,0.1,0.1,0.2s0.1,0.1,0.2,0.1h0.2l0.1,0.5c0,0-0.1,0-0.2,0.1
      c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-2.9H299.3z"
          fill="#FFFFFF"
        />
        <path
          d="M302.5,120.8l-0.8,2.3c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0h-0.6c-0.1,0-0.1,0-0.1,0
      c0,0-0.1-0.1-0.1-0.1l-0.8-2.3h0.8l0.5,1.5c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2h0.1c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.1,0.1-0.2
      l0.4-1.5H302.5z"
          fill="#FFFFFF"
        />
        <path
          d="M303.8,120.8c0.4,0,0.7,0.1,0.9,0.2s0.3,0.4,0.3,0.6c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.6,0.2h-1.4
      v-0.4h1.2c0.1,0,0.2,0,0.2-0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1
      c-0.1,0-0.1,0.1-0.2,0.2c0,0.1,0,0.3,0,0.5c0,0.2,0,0.4,0.1,0.5c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0
      c0.2,0,0.3,0,0.5,0l0.1,0.5c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6,0-0.8-0.1
      c-0.2-0.1-0.3-0.2-0.4-0.4s-0.1-0.4-0.1-0.7c0-0.3,0-0.6,0.1-0.8s0.2-0.3,0.4-0.4C303.3,120.8,303.5,120.8,303.8,120.8z"
          fill="#FFFFFF"
        />
        <path
          d="M306,120.8l0.1,0.5l0.1,0.1v1.9h-0.7v-2.5H306z M307.2,120.8l-0.1,0.7h-0.2c-0.1,0-0.2,0-0.4,0
      c-0.1,0-0.3,0.1-0.5,0.1l0-0.4c0.2-0.2,0.3-0.3,0.5-0.3c0.2-0.1,0.3-0.1,0.5-0.1H307.2z"
          fill="#FFFFFF"
        />
        <path
          d="M308.2,119.8l0,1.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3v0.8
      h-0.7v-3.5H308.2z M308.5,121.7v0.4h-0.6v-0.4H308.5z M309.8,120.8l-0.6,0.9c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1v0
      c0,0,0.1,0.1,0.1,0.1s0.1,0.1,0.1,0.2l0.7,1.1h-0.8l-0.8-1.4l0.8-1.1H309.8z"
          fill="#FFFFFF"
        />
        <path
          d="M312.1,121.8l0,0.4l-1.1,0c-0.1,0-0.2,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2v0.1c0,0.1,0,0.1,0.1,0.2
      c0,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2v0.3c0,0.1-0.1,0.1-0.1,0.2
      c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
      s-0.1-0.2-0.1-0.4v-0.3c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2H312.1z M311.4,120.8c0.2,0,0.4,0,0.5,0.1
      c0.1,0.1,0.3,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5v1.6h-0.6l-0.1-0.5l0-0.1v-1c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1
      c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0l-0.1-0.5c0.1,0,0.3,0,0.4-0.1c0.2,0,0.3,0,0.5,0C311.2,120.8,311.3,120.8,311.4,120.8z"
          fill="#FFFFFF"
        />
        <path
          d="M313.4,120.8l0.1,0.5l0.1,0.1v1.9h-0.7v-2.5H313.4z M314.7,120.8l-0.1,0.7h-0.2c-0.1,0-0.2,0-0.4,0
      c-0.1,0-0.3,0.1-0.5,0.1l0-0.4c0.2-0.2,0.3-0.3,0.5-0.3c0.2-0.1,0.3-0.1,0.5-0.1H314.7z"
          fill="#FFFFFF"
        />
        <path
          d="M316,120.8c0.4,0,0.7,0.1,0.9,0.2s0.3,0.4,0.3,0.6c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.6,0.2H315
      v-0.4h1.2c0.1,0,0.2,0,0.2-0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1
      c-0.1,0-0.1,0.1-0.2,0.2c0,0.1,0,0.3,0,0.5c0,0.2,0,0.4,0.1,0.5c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0
      c0.2,0,0.3,0,0.5,0l0.1,0.5c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6,0-0.8-0.1
      c-0.2-0.1-0.3-0.2-0.4-0.4s-0.1-0.4-0.1-0.7c0-0.3,0-0.6,0.1-0.8s0.2-0.3,0.4-0.4C315.5,120.8,315.7,120.8,316,120.8z"
          fill="#FFFFFF"
        />
        <path
          d="M318,121c0.1,0,0.2,0,0.2,0.1c0,0,0.1,0.1,0.1,0.2v0.2c0,0.1,0,0.2-0.1,0.2c0,0-0.1,0.1-0.2,0.1h-0.2
      c-0.1,0-0.2,0-0.2-0.1c0,0-0.1-0.1-0.1-0.2v-0.2c0-0.1,0-0.2,0.1-0.2s0.1-0.1,0.2-0.1H318z M318,122.5c0.1,0,0.2,0,0.2,0.1
      c0,0,0.1,0.1,0.1,0.2v0.2c0,0.1,0,0.2-0.1,0.2c0,0-0.1,0.1-0.2,0.1h-0.2c-0.1,0-0.2,0-0.2-0.1s-0.1-0.1-0.1-0.2v-0.2
      c0-0.1,0-0.2,0.1-0.2c0,0,0.1-0.1,0.2-0.1H318z"
          fill="#FFFFFF"
        />
        <path
          d="M267.6,125.5c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.4,0.2,0.5,0.3s0.2,0.3,0.3,0.6c0.1,0.3,0.1,0.6,0.1,0.9
      c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.1,0.4-0.3,0.6s-0.3,0.2-0.5,0.3c-0.2,0.1-0.5,0.1-0.8,0.1s-0.5,0-0.8-0.1
      c-0.2-0.1-0.4-0.2-0.5-0.3s-0.2-0.3-0.3-0.6s-0.1-0.6-0.1-0.9c0-0.4,0-0.7,0.1-0.9c0.1-0.2,0.1-0.4,0.3-0.6s0.3-0.2,0.5-0.3
      C267.1,125.5,267.3,125.5,267.6,125.5z M267.6,126.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2-0.1,0.4-0.1,0.8
      c0,0.3,0,0.6,0.1,0.8c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.4
      c0-0.2,0.1-0.4,0.1-0.8c0-0.3,0-0.6-0.1-0.8s-0.1-0.3-0.2-0.4C267.9,126.2,267.8,126.1,267.6,126.1z"
          fill="#FFFFFF"
        />
        <path
          d="M270.5,125.5l0,2.9c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0.1h0.2l0.1,0.5c0,0-0.1,0-0.2,0.1
      c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.3-0.2-0.5v-3.1H270.5z"
          fill="#FFFFFF"
        />
        <path
          d="M273.9,126.6l-0.9,2.5c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.2,0.1h-0.7c-0.1,0-0.1,0-0.2-0.1
      c0,0-0.1-0.1-0.1-0.1l-0.9-2.5h0.8l0.5,1.7c0,0.1,0,0.2,0.1,0.2c0,0.1,0,0.2,0.1,0.2h0.1c0-0.1,0-0.2,0.1-0.2c0-0.1,0-0.2,0.1-0.2
      l0.5-1.7H273.9z"
          fill="#FFFFFF"
        />
        <path
          d="M274.7,125.4c0.2,0,0.2,0.1,0.2,0.2v0.3c0,0.2-0.1,0.2-0.2,0.2h-0.3c-0.2,0-0.2-0.1-0.2-0.2v-0.3
      c0-0.2,0.1-0.2,0.2-0.2H274.7z M275,126.6v2.7h-0.8v-2.7H275z"
          fill="#FFFFFF"
        />
        <path
          d="M278.1,125.5c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.4,0.2,0.5,0.3s0.2,0.3,0.3,0.6c0.1,0.3,0.1,0.6,0.1,0.9
      c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.1,0.4-0.3,0.6s-0.3,0.2-0.5,0.3c-0.2,0.1-0.5,0.1-0.8,0.1s-0.5,0-0.8-0.1
      c-0.2-0.1-0.4-0.2-0.5-0.3s-0.2-0.3-0.3-0.6s-0.1-0.6-0.1-0.9c0-0.4,0-0.7,0.1-0.9c0.1-0.2,0.1-0.4,0.3-0.6s0.3-0.2,0.5-0.3
      C277.6,125.5,277.8,125.5,278.1,125.5z M278.1,126.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2-0.1,0.4-0.1,0.8
      c0,0.3,0,0.6,0.1,0.8c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.4
      c0-0.2,0.1-0.4,0.1-0.8c0-0.3,0-0.6-0.1-0.8s-0.1-0.3-0.2-0.4C278.5,126.2,278.3,126.1,278.1,126.1z"
          fill="#FFFFFF"
        />
        <path
          d="M280.8,126.6l0.5,1.7c0,0.1,0,0.1,0,0.2s0,0.1,0,0.2h0.1l-0.3,0.5h-0.2c-0.1,0-0.1,0-0.2-0.1
      s-0.1-0.1-0.1-0.1l-0.9-2.5H280.8z M282.9,126.6l-1,2.7c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
      s-0.4,0.1-0.6,0l-0.1-0.5c0.2-0.1,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.2,0.3-0.4l0.2-0.4c0-0.1,0.1-0.2,0.1-0.3s0.1-0.2,0.1-0.3
      l0.5-1.7H282.9z"
          fill="#FFFFFF"
        />
        <path
          d="M284,126.6v2.5c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.3l-0.4-0.3
      c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.3,0.1-0.4v-2.5H284z M283.7,125.4c0.2,0,0.2,0.1,0.2,0.2v0.3c0,0.2-0.1,0.2-0.2,0.2h-0.4
      c-0.2,0-0.2-0.1-0.2-0.2v-0.3c0-0.2,0.1-0.2,0.2-0.2H283.7z"
          fill="#FFFFFF"
        />
        <path
          d="M285,128.5c0.1,0,0.2,0,0.2,0.1c0,0,0.1,0.1,0.1,0.2v0.3c0,0.1,0,0.3,0,0.4c0,0.1-0.1,0.2-0.1,0.3
      c0,0.1-0.1,0.2-0.1,0.3l-0.3-0.1c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0-0.4c-0.2,0-0.2-0.1-0.2-0.2l0-0.3c0-0.1,0-0.2,0.1-0.2
      c0,0,0.1-0.1,0.2-0.1H285z"
          fill="#FFFFFF"
        />
        <path
          d="M288.3,125.5c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.4,0.2,0.5,0.3s0.2,0.3,0.3,0.6c0.1,0.3,0.1,0.6,0.1,0.9
      c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.1,0.4-0.3,0.6s-0.3,0.2-0.5,0.3c-0.2,0.1-0.5,0.1-0.8,0.1s-0.5,0-0.8-0.1
      c-0.2-0.1-0.4-0.2-0.5-0.3s-0.2-0.3-0.3-0.6s-0.1-0.6-0.1-0.9c0-0.4,0-0.7,0.1-0.9c0.1-0.2,0.1-0.4,0.3-0.6s0.3-0.2,0.5-0.3
      C287.8,125.5,288,125.5,288.3,125.5z M288.3,126.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2-0.1,0.4-0.1,0.8
      c0,0.3,0,0.6,0.1,0.8c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.4
      c0-0.2,0.1-0.4,0.1-0.8c0-0.3,0-0.6-0.1-0.8s-0.1-0.3-0.2-0.4C288.7,126.2,288.5,126.1,288.3,126.1z"
          fill="#FFFFFF"
        />
        <path
          d="M291.2,125.5l0,2.9c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0.1h0.2l0.1,0.5c0,0-0.1,0-0.2,0.1
      c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.3-0.2-0.5v-3.1H291.2z"
          fill="#FFFFFF"
        />
        <path
          d="M294.6,126.6l-0.9,2.5c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.2,0.1h-0.7c-0.1,0-0.1,0-0.2-0.1
      c0,0-0.1-0.1-0.1-0.1l-0.9-2.5h0.8l0.5,1.7c0,0.1,0,0.2,0.1,0.2c0,0.1,0,0.2,0.1,0.2h0.1c0-0.1,0-0.2,0.1-0.2c0-0.1,0-0.2,0.1-0.2
      l0.5-1.7H294.6z"
          fill="#FFFFFF"
        />
        <path
          d="M295.5,125.4c0.2,0,0.2,0.1,0.2,0.2v0.3c0,0.2-0.1,0.2-0.2,0.2h-0.3c-0.2,0-0.2-0.1-0.2-0.2v-0.3
      c0-0.2,0.1-0.2,0.2-0.2H295.5z M295.7,126.6v2.7h-0.8v-2.7H295.7z"
          fill="#FFFFFF"
        />
        <path
          d="M298,126.6v0.5h-2v-0.5l0.5,0H298z M297.3,125.9v2.6c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0.1,0.2,0.1h0.3l0.1,0.5
      c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.2-0.3-0.2-0.5l0-2.7H297.3z"
          fill="#FFFFFF"
        />
        <path
          d="M299,125.4c0.2,0,0.2,0.1,0.2,0.2v0.3c0,0.2-0.1,0.2-0.2,0.2h-0.3c-0.2,0-0.2-0.1-0.2-0.2v-0.3
      c0-0.2,0.1-0.2,0.2-0.2H299z M299.2,126.6v2.7h-0.8v-2.7H299.2z"
          fill="#FFFFFF"
        />
        <path
          d="M301,126.5c0.5,0,0.8,0.1,1,0.2s0.3,0.4,0.3,0.7c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.7,0.2h-1.5
      v-0.4h1.3c0.1,0,0.2,0,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.3,0-0.3,0.1
      c-0.1,0-0.1,0.1-0.2,0.2c0,0.1,0,0.3,0,0.5c0,0.2,0,0.4,0.1,0.5c0,0.1,0.1,0.2,0.2,0.3c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0,0.5,0
      c0.2,0,0.4,0,0.5,0l0.1,0.5c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4,0.1s-0.3,0-0.4,0c-0.3,0-0.6,0-0.8-0.1
      c-0.2-0.1-0.4-0.3-0.4-0.5s-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.4,0.4-0.4C300.4,126.6,300.7,126.5,301,126.5z"
          fill="#FFFFFF"
        />
        <path d="M304.6,125.6v3.7h-0.8v-3.7H304.6z" fill="#FFFFFF" />
        <path d="M306.8,127.7v0.5h-1.6v-0.5H306.8z" fill="#FFFFFF" />
        <path d="M308.2,125.6v3.7h-0.8v-3.7H308.2z" fill="#FFFFFF" />
        <path
          d="M311.9,125.6l-1,3.5c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.8c-0.1,0-0.1,0-0.2-0.1
      c-0.1,0-0.1-0.1-0.1-0.2l-1-3.5h0.9l0.7,2.7c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2h0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
      l0.7-2.7H311.9z"
          fill="#FFFFFF"
        />
        <path
          d="M312.4,128.5c0.1,0,0.2,0,0.2,0.1c0,0,0.1,0.1,0.1,0.2v0.3c0,0.1,0,0.3,0,0.4c0,0.1-0.1,0.2-0.1,0.3
      c0,0.1-0.1,0.2-0.1,0.3L312,130c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0-0.4c-0.2,0-0.2-0.1-0.2-0.2l0-0.3c0-0.1,0-0.2,0.1-0.2
      c0,0,0.1-0.1,0.2-0.1H312.4z"
          fill="#FFFFFF"
        />
        <path
          d="M268.1,131.6c0.1,0,0.2,0,0.2,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2l-1.1,3.3h-0.8l1-2.7
      c0-0.1,0-0.1,0.1-0.2s0.1-0.1,0.1-0.2h-1.7l0-0.7H268.1z"
          fill="#FFFFFF"
        />
        <path
          d="M270.4,131.6l-0.9,2.1c0,0.1,0,0.1,0,0.1c0,0,0.1,0,0.1,0h2.1v0.5l-0.5,0.1H269c-0.1,0-0.2,0-0.3-0.1
      s-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.3l1-2.3H270.4z M271.3,132.3v3h-0.8v-2.4l0.1-0.6H271.3z"
          fill="#FFFFFF"
        />
        <path
          d="M273.7,131.6v3.7h-0.8v-2.6c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2l-0.9,0.2l-0.1-0.5l1-0.4H273.7z"
          fill="#FFFFFF"
        />
        <path
          d="M275.7,131.5c0.4,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.3,0.1,0.7,0.1,1.1c0,0.5,0,0.9-0.1,1.1
      c-0.1,0.3-0.2,0.5-0.5,0.6c-0.2,0.1-0.5,0.2-0.8,0.2c-0.4,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.3-0.1-0.7-0.1-1.1
      c0-0.5,0-0.9,0.1-1.1c0.1-0.3,0.2-0.5,0.5-0.6C275.1,131.5,275.4,131.5,275.7,131.5z M275.7,132.1c-0.1,0-0.2,0-0.3,0.1
      s-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0,0.4,0,0.6c0,0.3,0,0.5,0,0.6s0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.2
      c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.6
      c0-0.2-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.2S275.9,132.1,275.7,132.1z"
          fill="#FFFFFF"
        />
        <path
          d="M279,131.5c0.4,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.3,0.1,0.7,0.1,1.1c0,0.5,0,0.9-0.1,1.1
      c-0.1,0.3-0.2,0.5-0.5,0.6c-0.2,0.1-0.5,0.2-0.8,0.2c-0.4,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.3-0.1-0.7-0.1-1.1
      c0-0.5,0-0.9,0.1-1.1c0.1-0.3,0.2-0.5,0.5-0.6C278.4,131.5,278.7,131.5,279,131.5z M279,132.1c-0.1,0-0.2,0-0.3,0.1
      s-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0,0.4,0,0.6c0,0.3,0,0.5,0,0.6s0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.2
      c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.6
      c0-0.2-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.2S279.2,132.1,279,132.1z"
          fill="#FFFFFF"
        />
        <path d="M282.9,131.6v3.7h-0.8v-3.7H282.9z" fill="#FFFFFF" />
        <path d="M284.4,131.6v3.7h-0.8v-3.7H284.4z" fill="#FFFFFF" />
        <path
          d="M286.2,131.5c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0.1,0.6,0.1l-0.1,0.5c-0.2,0-0.3,0-0.6,0c-0.2,0-0.4,0-0.6,0
      c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0s-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.2l0.7,0.2
      c0.3,0.1,0.5,0.2,0.6,0.4c0.1,0.2,0.2,0.4,0.2,0.7c0,0.2,0,0.4-0.1,0.5c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2
      c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0-0.3,0-0.5,0c-0.2,0-0.5-0.1-0.8-0.1l0.1-0.6c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0,0.4,0
      c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.3,0c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.1-0.1-0.1
      c-0.1,0-0.1-0.1-0.2-0.1l-0.8-0.3c-0.3-0.1-0.5-0.2-0.6-0.4c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.2,0-0.4,0.1-0.5
      c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.1,0.4-0.2C285.8,131.5,286,131.5,286.2,131.5z"
          fill="#FFFFFF"
        />
        <path
          d="M289.8,131.6c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.1,0.2l1.1,3.5h-0.8l-0.8-2.7c0-0.1,0-0.1,0-0.2
      c0-0.1,0-0.1,0-0.2h-0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2l-0.8,2.7h-0.8l1.1-3.5c0-0.1,0.1-0.1,0.1-0.2s0.1-0.1,0.2-0.1H289.8
      z M290.4,133.7v0.6h-1.9v-0.6H290.4z"
          fill="#FFFFFF"
        />
        <path
          d="M292.3,131.6v2.8c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0.1,0.2,0.1h1.3l0,0.6c-0.3,0-0.5,0-0.8,0.1
      c-0.3,0-0.6,0-0.8,0c-0.3,0-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.3-0.2-0.6v-3H292.3z"
          fill="#FFFFFF"
        />
        <path
          d="M298.1,131.6c0.2,0,0.3,0.1,0.3,0.3l0.1,3.4h-0.8l-0.1-3.1h-0.1l-0.6,2.3c0,0.2-0.1,0.3-0.3,0.3h-0.5
      c-0.2,0-0.3-0.1-0.3-0.3l-0.6-2.3h-0.1l-0.1,3.1h-0.8l0.1-3.4c0-0.2,0.1-0.3,0.3-0.3h0.8c0.2,0,0.3,0.1,0.3,0.3l0.5,1.9
      c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3h0.1c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.3l0.5-1.9c0-0.2,0.1-0.3,0.3-0.3H298.1z"
          fill="#FFFFFF"
        />
        <path d="M299.9,131.6v3.7h-0.8v-3.7H299.9z" fill="#FFFFFF" />
        <path
          d="M267.3,137.5c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0.1,0.6,0.1l-0.1,0.5c-0.2,0-0.3,0-0.6,0c-0.2,0-0.4,0-0.6,0
      c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0s-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.2l0.7,0.2
      c0.3,0.1,0.5,0.2,0.6,0.4c0.1,0.2,0.2,0.4,0.2,0.7c0,0.2,0,0.4-0.1,0.5c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2
      c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0-0.3,0-0.5,0c-0.2,0-0.5-0.1-0.8-0.1l0.1-0.6c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0,0.4,0
      c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.3,0c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.1-0.1-0.1
      c-0.1,0-0.1-0.1-0.2-0.1l-0.8-0.3c-0.3-0.1-0.5-0.2-0.6-0.4c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.2,0-0.4,0.1-0.5
      c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.1,0.4-0.2C266.9,137.5,267.1,137.5,267.3,137.5z"
          fill="#FFFFFF"
        />
        <path
          d="M272.2,137.6v2.2c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.2,0.4-0.5,0.5s-0.5,0.2-0.9,0.2c-0.4,0-0.7-0.1-0.9-0.2
      s-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.9v-2.2h0.8v2.2c0,0.2,0,0.4,0.1,0.5c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.4,0.1
      c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.2-0.3c0-0.1,0.1-0.3,0.1-0.5v-2.2H272.2z"
          fill="#FFFFFF"
        />
        <path
          d="M274.3,137.5c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.4,0.2,0.5,0.3s0.2,0.3,0.3,0.6c0.1,0.3,0.1,0.6,0.1,0.9
      c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.1,0.4-0.3,0.6s-0.3,0.2-0.5,0.3c-0.2,0.1-0.5,0.1-0.8,0.1s-0.5,0-0.8-0.1
      c-0.2-0.1-0.4-0.2-0.5-0.3s-0.2-0.3-0.3-0.6s-0.1-0.6-0.1-0.9c0-0.4,0-0.7,0.1-0.9c0.1-0.2,0.1-0.4,0.3-0.6s0.3-0.2,0.5-0.3
      C273.7,137.5,274,137.5,274.3,137.5z M274.3,138.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2-0.1,0.4-0.1,0.8
      c0,0.3,0,0.6,0.1,0.8c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.4
      c0-0.2,0.1-0.4,0.1-0.8c0-0.3,0-0.6-0.1-0.8s-0.1-0.3-0.2-0.4C274.6,138.2,274.4,138.1,274.3,138.1z"
          fill="#FFFFFF"
        />
        <path
          d="M280.2,137.6c0.2,0,0.3,0.1,0.3,0.3l0.1,3.4h-0.8l-0.1-3.1h-0.1l-0.6,2.3c0,0.2-0.1,0.3-0.3,0.3h-0.5
      c-0.2,0-0.3-0.1-0.3-0.3l-0.6-2.3h-0.1l-0.1,3.1h-0.8l0.1-3.4c0-0.2,0.1-0.3,0.3-0.3h0.8c0.2,0,0.3,0.1,0.3,0.3l0.5,1.9
      c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3h0.1c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0.1-0.3l0.5-1.9c0-0.2,0.1-0.3,0.3-0.3H280.2z"
          fill="#FFFFFF"
        />
        <path d="M282,137.6v3.7h-0.8v-3.7H282z" fill="#FFFFFF" />
        <path d="M285.2,137.6l-2.1,4.1h-0.6l2.1-4.1H285.2z" fill="#FFFFFF" />
        <path
          d="M286.3,137.5c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0s0.4,0,0.5,0l0,0.6h-1.4c-0.1,0-0.2,0-0.2,0.1
      c-0.1,0-0.1,0.1-0.1,0.2v2.8h-0.8v-3c0-0.2,0.1-0.4,0.2-0.6C285.9,137.6,286.1,137.5,286.3,137.5z M285.6,139.1h2.2v0.6h-2.2
      V139.1z"
          fill="#FFFFFF"
        />
        <path d="M289.3,137.6v3.7h-0.8v-3.7H289.3z" fill="#FFFFFF" />
        <path
          d="M293.1,137.6v3.4c0,0.2-0.1,0.3-0.3,0.3h-0.5c-0.1,0-0.2,0-0.2-0.1c0,0-0.1-0.1-0.1-0.2l-1-2
      c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3h-0.1c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3v2.2h-0.8v-3.4c0-0.2,0.1-0.3,0.3-0.3h0.4
      c0.1,0,0.2,0,0.2,0.1c0,0,0.1,0.1,0.1,0.2l1,1.9c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.1,0.3h0.1c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3l0-2.1
      H293.1z"
          fill="#FFFFFF"
        />
        <path
          d="M294.5,137.6v2.8c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0.1,0.2,0.1h1.3l0,0.6c-0.3,0-0.5,0-0.8,0.1
      c-0.3,0-0.6,0-0.8,0c-0.3,0-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.3-0.2-0.6v-3H294.5z"
          fill="#FFFFFF"
        />
        <path
          d="M298.3,137.6c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.1,0.2l1.1,3.5h-0.8l-0.8-2.7c0-0.1,0-0.1,0-0.2
      c0-0.1,0-0.1,0-0.2h-0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2l-0.8,2.7h-0.8l1.1-3.5c0-0.1,0.1-0.1,0.1-0.2s0.1-0.1,0.2-0.1H298.3
      z M298.9,139.7v0.6h-1.9v-0.6H298.9z"
          fill="#FFFFFF"
        />
        <path
          d="M303.1,137.6v3.4c0,0.2-0.1,0.3-0.3,0.3h-0.5c-0.1,0-0.2,0-0.2-0.1c0,0-0.1-0.1-0.1-0.2l-1-2
      c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3h-0.1c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3v2.2h-0.8v-3.4c0-0.2,0.1-0.3,0.3-0.3h0.4
      c0.1,0,0.2,0,0.2,0.1c0,0,0.1,0.1,0.1,0.2l1,1.9c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.1,0.3h0.1c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3l0-2.1
      H303.1z"
          fill="#FFFFFF"
        />
        <path
          d="M305.2,137.5c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.6
      c0.1,0.2,0.1,0.6,0.1,0.9c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.1,0.4-0.3,0.6c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.5,0.1-0.7,0.1
      c-0.3,0-0.5,0-0.8,0s-0.5,0-0.7,0l0.3-0.6c0.1,0,0.3,0,0.5,0c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.4
      c0-0.2,0.1-0.4,0.1-0.8c0-0.3,0-0.6-0.1-0.8c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.4,0-0.6,0
      c-0.2,0-0.3,0-0.5,0l-0.4-0.6c0.2,0,0.4,0,0.7,0C304.6,137.5,304.9,137.5,305.2,137.5z M304.5,137.6v3.7h-0.8v-3.7H304.5z"
          fill="#FFFFFF"
        />
        <path
          d="M267.5,143.5c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.3,0.2,0.4,0.4s0.1,0.4,0.1,0.7c0,0.3,0,0.6-0.1,0.7
      c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.7,0.1c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.4-0.1s-0.3,0-0.4-0.1
      c-0.1,0-0.2,0-0.2-0.1l0-0.4c0.2,0,0.4,0,0.7,0s0.5,0,0.7,0c0.1,0,0.3,0,0.3-0.1s0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.4
      c0-0.2,0-0.3-0.1-0.4c0-0.1-0.1-0.1-0.2-0.2s-0.2-0.1-0.3-0.1c-0.3,0-0.6,0-0.9,0c-0.2,0-0.4,0-0.5,0l-0.1-0.6c0.2,0,0.3,0,0.4,0
      c0.1,0,0.3,0,0.4,0C267.1,143.5,267.3,143.5,267.5,143.5z M266.9,143.6v3.7h-0.8v-3.7H266.9z"
          fill="#FFFFFF"
        />
        <path
          d="M272.2,143.6v2.2c0,0.4,0,0.7-0.1,0.9c-0.1,0.2-0.2,0.4-0.5,0.5s-0.5,0.2-0.9,0.2c-0.4,0-0.7-0.1-0.9-0.2
      s-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.9v-2.2h0.8v2.2c0,0.2,0,0.4,0.1,0.5c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.4,0.1
      c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.2-0.3c0-0.1,0.1-0.3,0.1-0.5v-2.2H272.2z"
          fill="#FFFFFF"
        />
        <path
          d="M273.6,143.6v3.7h-0.8v-3.7H273.6z M274.9,145v0.6h-1.4V145H274.9z M275.7,143.6v3.7h-0.8v-3.7H275.7z"
          fill="#FFFFFF"
        />
        <path d="M278.7,143.6l-2.1,4.1H276l2.1-4.1H278.7z" fill="#FFFFFF" />
        <path d="M281.8,143.6v0.6h-3.1v-0.6H281.8z M280.7,143.6v3.7h-0.8v-3.7H280.7z" fill="#FFFFFF" />
        <path
          d="M282.8,143.5c0.2,0,0.4,0,0.6,0s0.4,0,0.6,0c0.2,0,0.4,0,0.6,0l0,0.6h-1.4c-0.1,0-0.2,0-0.2,0.1
      c0,0-0.1,0.1-0.1,0.2v1.9c0,0.1,0,0.2,0.1,0.2s0.1,0.1,0.2,0.1h1.4l0,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.6,0
      c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-2.3c0-0.2,0.1-0.4,0.2-0.5C282.4,143.6,282.6,143.5,282.8,143.5z M282.1,145.1
      h2.2v0.6h-2.2V145.1z"
          fill="#FFFFFF"
        />
        <path
          d="M285.8,143.6v2.8c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0.1,0.2,0.1h1.3l0,0.6c-0.3,0-0.5,0-0.8,0.1
      c-0.3,0-0.6,0-0.8,0c-0.3,0-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.3-0.2-0.6v-3H285.8z"
          fill="#FFFFFF"
        />
        <path
          d="M288,144.9c0.1,0,0.2,0,0.2,0.1c0,0,0.1,0.1,0.1,0.2v0.3c0,0.1,0,0.2-0.1,0.2c0,0-0.1,0.1-0.2,0.1h-0.2
      c-0.1,0-0.2,0-0.2-0.1c0,0-0.1-0.1-0.1-0.2v-0.3c0-0.1,0-0.2,0.1-0.2c0,0,0.1-0.1,0.2-0.1H288z M288,146.5c0.1,0,0.2,0,0.2,0.1
      c0,0,0.1,0.1,0.1,0.2v0.3c0,0.1,0,0.2-0.1,0.2s-0.1,0.1-0.2,0.1h-0.2c-0.1,0-0.2,0-0.2-0.1s-0.1-0.1-0.1-0.2v-0.3
      c0-0.1,0-0.2,0.1-0.2c0,0,0.1-0.1,0.2-0.1H288z"
          fill="#FFFFFF"
        />
        <path d="M268.4,151.4v0.6H266v-0.6H268.4z M267.5,150.5v2.4h-0.6l0-2.4H267.5z" fill="#FFFFFF" />
        <path
          d="M269.9,149.5c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.3,0.1,0.4,0.3s0.1,0.3,0.1,0.5c0,0.2,0,0.3,0,0.5
      c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.4,0.2v0c0.3,0,0.4,0.1,0.5,0.3c0.1,0.2,0.1,0.4,0.2,0.6c0,0.3,0,0.5-0.1,0.6
      s-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.8,0.1c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4,0-0.6-0.1l0-0.6c0.1,0,0.3,0,0.4,0s0.2,0,0.3,0
      s0.2,0,0.3,0c0.2,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.2-0.2s0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.2-0.2
      c-0.1,0-0.2-0.1-0.4-0.1l-0.7,0v-0.5h0.7c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.1s0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2
      c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4,0-0.5,0c-0.2,0-0.3,0-0.5,0l0-0.6
      c0.2,0,0.4-0.1,0.6-0.1C269.5,149.5,269.7,149.5,269.9,149.5z"
          fill="#FFFFFF"
        />
        <path
          d="M273.9,149.6l0,0.6h-1.5l0,0.7l0.5,0.1c0.2,0,0.4,0.1,0.6,0.1c0.2,0.1,0.3,0.1,0.4,0.3s0.2,0.2,0.2,0.4
      c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.3-0.1,0.5c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.6,0.1
      c-0.2,0-0.4,0-0.6,0s-0.4,0-0.7-0.1l0.1-0.6c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.4,0c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.2-0.2
      c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3s-0.1-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.3,0-0.4,0l-0.5-0.1
      c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.1-1.7H273.9z"
          fill="#FFFFFF"
        />
        <path
          d="M275.7,149.5c0.3,0,0.5,0,0.7,0.1c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.3,0.1,0.4
      c0,0.1,0,0.3-0.1,0.4s-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.1-0.3,0.1v0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4
      c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.3-0.4,0.3c-0.2,0.1-0.5,0.1-0.9,0.1c-0.4,0-0.7,0-0.9-0.1
      c-0.2-0.1-0.3-0.2-0.4-0.3s-0.1-0.3-0.1-0.6c0-0.2,0-0.3,0.1-0.5s0.1-0.3,0.2-0.4s0.2-0.1,0.4-0.2v0c-0.1,0-0.2-0.1-0.3-0.1
      s-0.1-0.2-0.2-0.3c0-0.1-0.1-0.3-0.1-0.4c0-0.2,0-0.3,0.1-0.4c0-0.1,0.1-0.2,0.2-0.3s0.2-0.1,0.4-0.2
      C275.2,149.5,275.5,149.5,275.7,149.5z M275.7,151.6c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0.1s-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0,0.3
      c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.1,0.2,0.2s0.2,0,0.4,0c0.2,0,0.3,0,0.4,0c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0-0.2,0-0.3
      c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1-0.1-0.2c0,0-0.1-0.1-0.2-0.1C276,151.6,275.9,151.6,275.7,151.6z M275.7,150.1c-0.2,0-0.3,0-0.4,0
      c-0.1,0-0.1,0.1-0.2,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.4,0c0.2,0,0.3,0,0.4,0
      c0.1,0,0.1-0.1,0.2-0.1s0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.2-0.2C276,150.1,275.9,150.1,275.7,150.1z"
          fill="#FFFFFF"
        />
        <path
          d="M279.9,149l0.1,0.4c-0.3,0.1-0.5,0.4-0.7,0.8s-0.2,0.9-0.2,1.4c0,0.4,0,0.7,0.1,1s0.2,0.5,0.3,0.8
      c0.1,0.2,0.3,0.4,0.5,0.5l-0.1,0.4c-0.5-0.2-0.9-0.5-1.2-0.9c-0.3-0.4-0.4-1-0.4-1.7c0-0.7,0.1-1.3,0.4-1.7
      C279,149.5,279.4,149.2,279.9,149z"
          fill="#FFFFFF"
        />
        <path
          d="M281.6,149.5c0.4,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.3,0.1,0.7,0.1,1.1c0,0.5,0,0.9-0.1,1.1
      c-0.1,0.3-0.2,0.5-0.5,0.6c-0.2,0.1-0.5,0.2-0.8,0.2c-0.4,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.3-0.1-0.7-0.1-1.1
      c0-0.5,0-0.9,0.1-1.1c0.1-0.3,0.2-0.5,0.5-0.6C281,149.5,281.2,149.5,281.6,149.5z M281.6,150.1c-0.1,0-0.2,0-0.3,0.1
      s-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0,0.4,0,0.6c0,0.3,0,0.5,0,0.6s0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.2
      c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.6
      c0-0.2-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.2S281.7,150.1,281.6,150.1z"
          fill="#FFFFFF"
        />
        <path
          d="M283.2,149c0.5,0.2,0.9,0.5,1.2,0.9c0.3,0.4,0.4,1,0.4,1.7c0,0.7-0.1,1.3-0.4,1.7
      c-0.3,0.4-0.6,0.7-1.2,0.9l-0.1-0.4c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.3-0.8c0.1-0.3,0.1-0.6,0.1-1
      c0-0.6-0.1-1.1-0.2-1.4c-0.1-0.4-0.4-0.6-0.7-0.8L283.2,149z"
          fill="#FFFFFF"
        />
        <path
          d="M287.3,149.5c0.3,0,0.5,0,0.7,0.1c0.2,0,0.3,0.1,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1,0,0.3-0.1,0.4
      c0,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.2,0.3-0.4,0.5l-1,1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0h1.1v0.7H286v-0.4c0-0.1,0-0.1,0-0.2
      c0-0.1,0-0.1,0.1-0.2l0.9-1c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1,0-0.1-0.1-0.2c0,0-0.1-0.1-0.2-0.1
      c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.4,0s-0.2,0-0.4,0c-0.1,0-0.3,0-0.4,0l0-0.6c0.2,0,0.3-0.1,0.5-0.1c0.1,0,0.3,0,0.4,0
      C287,149.5,287.2,149.5,287.3,149.5z"
          fill="#FFFFFF"
        />
        <path
          d="M290.2,149.5c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.6c0,0.2,0.1,0.6,0.1,0.9
      c0,0.4,0,0.8-0.1,1c-0.1,0.3-0.1,0.5-0.3,0.6c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0-0.4,0.1-0.7,0.1c-0.1,0-0.2,0-0.4,0
      c-0.2,0-0.4,0-0.6-0.1l0-0.5c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.2-0.2
      s0.1-0.2,0.1-0.4c0-0.2,0-0.4,0-0.7c0-0.3,0-0.5,0-0.6s0-0.3-0.1-0.4s-0.1-0.2-0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1
      c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1,0,0.2,0,0.4c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.2,0.2,0.2
      c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.4-0.2l0,0.3c-0.1,0.2-0.2,0.3-0.3,0.4
      c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3,0.1-0.5,0.1c-0.3,0-0.5,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6
      c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.3,0.4-0.4C289.6,149.5,289.9,149.5,290.2,149.5z"
          fill="#FFFFFF"
        />
        <path
          d="M293.3,149.5c0.4,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.3,0.1,0.7,0.1,1.1c0,0.5,0,0.9-0.1,1.1
      c-0.1,0.3-0.2,0.5-0.5,0.6c-0.2,0.1-0.5,0.2-0.8,0.2c-0.4,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.3-0.1-0.7-0.1-1.1
      c0-0.5,0-0.9,0.1-1.1c0.1-0.3,0.2-0.5,0.5-0.6C292.7,149.5,293,149.5,293.3,149.5z M293.3,150.1c-0.1,0-0.2,0-0.3,0.1
      s-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0,0.4,0,0.6c0,0.3,0,0.5,0,0.6s0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.2
      c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.6
      c0-0.2-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.2S293.5,150.1,293.3,150.1z"
          fill="#FFFFFF"
        />
        <path
          d="M297.5,149.5c0.4,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.3,0.1,0.7,0.1,1.1c0,0.5,0,0.9-0.1,1.1
      c-0.1,0.3-0.2,0.5-0.5,0.6c-0.2,0.1-0.5,0.2-0.8,0.2c-0.4,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.3-0.1-0.7-0.1-1.1
      c0-0.5,0-0.9,0.1-1.1c0.1-0.3,0.2-0.5,0.5-0.6C296.8,149.5,297.1,149.5,297.5,149.5z M297.5,150.1c-0.1,0-0.2,0-0.3,0.1
      s-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0,0.4,0,0.6c0,0.3,0,0.5,0,0.6s0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.2
      c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.6
      c0-0.2-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.2S297.6,150.1,297.5,150.1z"
          fill="#FFFFFF"
        />
        <path
          d="M300.7,149.5c0.4,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.3,0.1,0.7,0.1,1.1c0,0.5,0,0.9-0.1,1.1
      c-0.1,0.3-0.2,0.5-0.5,0.6c-0.2,0.1-0.5,0.2-0.8,0.2c-0.4,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.3-0.1-0.7-0.1-1.1
      c0-0.5,0-0.9,0.1-1.1c0.1-0.3,0.2-0.5,0.5-0.6C300,149.5,300.3,149.5,300.7,149.5z M300.7,150.1c-0.1,0-0.2,0-0.3,0.1
      s-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0,0.4,0,0.6c0,0.3,0,0.5,0,0.6s0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.2
      c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.6
      c0-0.2-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.2S300.8,150.1,300.7,150.1z"
          fill="#FFFFFF"
        />
        <path d="M304,149.6v3.7h-0.8v-2.6c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2l-0.9,0.2l-0.1-0.5l1-0.4H304z" fill="#FFFFFF" />
        <path
          d="M306,149.5c0.4,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.3,0.1,0.7,0.1,1.1c0,0.5,0,0.9-0.1,1.1
      c-0.1,0.3-0.2,0.5-0.5,0.6c-0.2,0.1-0.5,0.2-0.8,0.2c-0.4,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.3-0.1-0.7-0.1-1.1
      c0-0.5,0-0.9,0.1-1.1c0.1-0.3,0.2-0.5,0.5-0.6C305.3,149.5,305.6,149.5,306,149.5z M306,150.1c-0.1,0-0.2,0-0.3,0.1
      s-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0,0.4,0,0.6c0,0.3,0,0.5,0,0.6s0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.2
      c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.6
      c0-0.2-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.2S306.1,150.1,306,150.1z"
          fill="#FFFFFF"
        />
        <path
          d="M310.1,149.6l0,0.6h-1.5l0,0.7l0.5,0.1c0.2,0,0.4,0.1,0.6,0.1c0.2,0.1,0.3,0.1,0.4,0.3s0.2,0.2,0.2,0.4
      c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.3-0.1,0.5c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.6,0.1
      c-0.2,0-0.4,0-0.6,0s-0.4,0-0.7-0.1l0.1-0.6c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.4,0c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.2-0.2
      c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3s-0.1-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.3,0-0.4,0l-0.5-0.1
      c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.1-1.7H310.1z"
          fill="#FFFFFF"
        />
        <path
          d="M311.9,149.5c0.4,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.3,0.1,0.7,0.1,1.1c0,0.5,0,0.9-0.1,1.1
      c-0.1,0.3-0.2,0.5-0.5,0.6c-0.2,0.1-0.5,0.2-0.8,0.2c-0.4,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.3-0.1-0.7-0.1-1.1
      c0-0.5,0-0.9,0.1-1.1c0.1-0.3,0.2-0.5,0.5-0.6C311.3,149.5,311.6,149.5,311.9,149.5z M311.9,150.1c-0.1,0-0.2,0-0.3,0.1
      s-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0,0.4,0,0.6c0,0.3,0,0.5,0,0.6s0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.2
      c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.6
      c0-0.2-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.2S312,150.1,311.9,150.1z"
          fill="#FFFFFF"
        />
      </g>
    </g>
    <g>
      <path
        d="M97.4,204.3l1.1-5.1h0.8l1.1,5.1h-0.8l-0.2-1.2h-1l-0.2,1.2H97.4z M98.5,202.5h0.8l-0.4-2.2L98.5,202.5z"
        fill="#FFFFFF"
      />
      <path d="M100.8,204.3v-5.1h0.8v4.5h1.3v0.6H100.8z" fill="#FFFFFF" />
      <path d="M103.4,204.3v-5.1h0.8v2.3l1.1-2.3h0.8l-1,2.3l1.1,2.8h-0.8l-0.9-2.4l-0.3,0.5v1.9H103.4z" fill="#FFFFFF" />
      <path d="M106.6,204.3v-0.8h0.8v0.8H106.6z" fill="#FFFFFF" />
      <path
        d="M110.7,204.3v-1.3h-1.5v-0.7l1.3-3.1h0.9v3.2h0.5v0.6h-0.5v1.3H110.7z M109.9,202.3h0.8V200L109.9,202.3z"
        fill="#FFFFFF"
      />
      <path
        d="M112.5,205.1l-0.1-0.3c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.4h-0.4v-0.8h0.8v0.7
    c0,0.2-0.1,0.4-0.2,0.6C112.9,204.9,112.7,205,112.5,205.1z"
        fill="#FFFFFF"
      />
      <path d="M113.9,204.3l1-4.5h-1.4v-0.6h2.1v0.4l-1,4.7H113.9z" fill="#FFFFFF" />
      <path
        d="M118.7,201.9c-0.4,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.2-0.5-0.2-0.9v-0.4c0-0.4,0.1-0.7,0.2-0.9
    c0.2-0.2,0.4-0.3,0.8-0.3c0.4,0,0.6,0.1,0.8,0.3c0.2,0.2,0.2,0.5,0.2,0.9v0.4c0,0.4-0.1,0.7-0.2,0.9
    C119.3,201.8,119.1,201.9,118.7,201.9z M118.7,201.4c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3v-0.4
    c0-0.2,0-0.3-0.1-0.5c0-0.1-0.1-0.2-0.3-0.2c-0.2,0-0.3,0.1-0.3,0.2c0,0.1-0.1,0.3-0.1,0.5v0.4c0,0.1,0,0.2,0,0.3
    c0,0.1,0.1,0.2,0.1,0.2C118.5,201.3,118.6,201.4,118.7,201.4z M119.3,204.3l1.5-5.1h0.6l-1.4,5.1H119.3z M121.9,204.3
    c-0.4,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.2-0.5-0.2-0.9v-0.4c0-0.4,0.1-0.7,0.2-0.9c0.2-0.2,0.4-0.3,0.8-0.3c0.4,0,0.6,0.1,0.8,0.3
    c0.2,0.2,0.2,0.5,0.2,0.9v0.4c0,0.4-0.1,0.7-0.2,0.9C122.6,204.2,122.3,204.3,121.9,204.3z M121.9,203.7c0.1,0,0.2,0,0.2-0.1
    c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3v-0.4c0-0.2,0-0.3-0.1-0.5c0-0.1-0.1-0.2-0.3-0.2c-0.2,0-0.3,0.1-0.3,0.2
    c0,0.1-0.1,0.3-0.1,0.5v0.4c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.2,0.1,0.2S121.8,203.7,121.9,203.7z"
        fill="#FFFFFF"
      />
      <path d="M125.6,204.3v-4.5h-0.8v-0.6h2.5v0.6h-0.8v4.5H125.6z" fill="#FFFFFF" />
      <path d="M127.8,204.3v-5.1h0.8v5.1H127.8z" fill="#FFFFFF" />
      <path d="M129.4,204.3v-5.1h0.8v4.5h1.3v0.6H129.4z" fill="#FFFFFF" />
      <path d="M131.9,204.3v-0.8h0.8v0.8H131.9z" fill="#FFFFFF" />
      <path d="M134.6,204.3l1.4-5.1h0.6l-1.4,5.1H134.6z" fill="#FFFFFF" />
      <path d="M139.5,204.3l-1.1-5.1h0.7l0.7,3.7l0.7-3.7h0.7l-1.1,5.1H139.5z" fill="#FFFFFF" />
      <path
        d="M143.2,204.3c-0.4,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.3-0.1-0.5-0.1-0.9v-1.9
    c0-0.3,0-0.6,0.1-0.9c0.1-0.2,0.2-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.8-0.2c0.4,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.6
    c0.1,0.2,0.1,0.5,0.1,0.9v1.9c0,0.3,0,0.6-0.1,0.9c-0.1,0.2-0.2,0.4-0.5,0.6C143.8,204.2,143.5,204.3,143.2,204.3z M143.2,203.7
    c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.3,0-0.4v-2.3c0-0.2,0-0.3,0-0.4c0-0.1-0.1-0.2-0.2-0.3
    c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.3,0,0.4v2.3c0,0.2,0,0.3,0,0.4
    c0,0.1,0.1,0.2,0.2,0.3C142.9,203.7,143,203.7,143.2,203.7z"
        fill="#FFFFFF"
      />
      <path d="M145.3,204.3v-5.1h0.8v4.5h1.3v0.6H145.3z" fill="#FFFFFF" />
    </g>
    <g>
      <path
        d="M63.3,204.2c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-2.2
    c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.3,0.3,0.4,0.5
    c0.1,0.2,0.1,0.5,0.1,0.8v2.2c0,0.3,0,0.6-0.1,0.8c-0.1,0.2-0.2,0.4-0.4,0.5C63.8,204.1,63.6,204.2,63.3,204.2z M63.3,203.5
    c0.1,0,0.3,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3,0-0.4v-2.2c0-0.1,0-0.3,0-0.4c0-0.1-0.1-0.2-0.1-0.3
    c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0,0.4v2.2c0,0.1,0,0.3,0,0.4
    c0,0.1,0.1,0.2,0.2,0.3C63,203.5,63.2,203.5,63.3,203.5z"
        fill="#FFFFFF"
      />
      <path
        d="M65.3,205l-0.1-0.3c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.4h-0.4v-0.8H66v0.7c0,0.2-0.1,0.4-0.2,0.6
    C65.7,204.8,65.5,204.9,65.3,205z"
        fill="#FFFFFF"
      />
      <path
        d="M67.8,204.2c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.7v-0.2h0.8
    c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.2,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.3-0.1
    c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.3,0-0.5c0-0.3,0-0.5-0.1-0.6c-0.1-0.2-0.3-0.2-0.5-0.3c0,0,0,0-0.1,0c0,0,0,0-0.1,0v-0.7
    c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.1-0.3,0.1-0.6c0-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.2-0.2-0.4-0.2
    c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.6c0,0,0,0,0,0.1s0,0.1,0,0.1h-0.8v-0.2c0-0.3,0-0.5,0.1-0.7
    c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.4,0.1,0.7
    c0,0.3-0.1,0.5-0.2,0.7s-0.3,0.3-0.5,0.4c0.1,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4c0.1,0.2,0.1,0.4,0.1,0.6c0,0.3,0,0.6-0.1,0.8
    c-0.1,0.2-0.2,0.4-0.4,0.5C68.3,204.1,68.1,204.2,67.8,204.2z"
        fill="#FFFFFF"
      />
      <path
        d="M70.8,204.2c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.7v-0.2h0.8
    c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.2,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.3-0.1
    c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.3,0-0.5c0-0.3,0-0.5-0.1-0.6c-0.1-0.2-0.3-0.2-0.5-0.3c0,0,0,0-0.1,0c0,0,0,0-0.1,0v-0.7
    c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.1-0.3,0.1-0.6c0-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.2-0.2-0.4-0.2
    c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.6c0,0,0,0,0,0.1s0,0.1,0,0.1h-0.8v-0.2c0-0.3,0-0.5,0.1-0.7
    c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.7-0.2c0.3,0,0.5,0.1,0.7,0.2c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.4,0.1,0.7
    c0,0.3-0.1,0.5-0.2,0.7s-0.3,0.3-0.5,0.4c0.1,0,0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4c0.1,0.2,0.1,0.4,0.1,0.6c0,0.3,0,0.6-0.1,0.8
    c-0.1,0.2-0.2,0.4-0.4,0.5C71.4,204.1,71.1,204.2,70.8,204.2z"
        fill="#FFFFFF"
      />
      <path d="M72.8,204.1V199h0.8v4.5h1.3v0.6H72.8z" fill="#FFFFFF" />
    </g>
    <g>
      <path
        d="M3.4,204c-0.4,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6C2,203,2,202.7,2,202.4v-1.9c0-0.3,0-0.6,0.1-0.9
    c0.1-0.2,0.2-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.8-0.2c0.4,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.1,0.5,0.1,0.9v1.9
    c0,0.3,0,0.6-0.1,0.9c-0.1,0.2-0.2,0.4-0.5,0.6C4,204,3.8,204,3.4,204z M3.4,203.4c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.2,0.2-0.3
    c0-0.1,0-0.3,0-0.4v-2.3c0-0.2,0-0.3,0-0.4c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0-0.4,0.1
    c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.3,0,0.4v2.3c0,0.2,0,0.3,0,0.4c0,0.1,0.1,0.2,0.2,0.3C3.1,203.4,3.2,203.4,3.4,203.4z"
        fill="#FFFFFF"
      />
      <path d="M5.5,204v-5.1h0.8v4.5h1.3v0.6H5.5z" fill="#FFFFFF" />
      <path
        d="M9.5,204c-0.4,0-0.7-0.1-0.9-0.2c-0.2-0.1-0.3-0.3-0.4-0.6c-0.1-0.3-0.1-0.6-0.1-0.9v-3.4h0.8v3.5
    c0,0.2,0,0.4,0,0.5c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.2,0.2-0.4c0-0.2,0-0.3,0-0.5
    v-3.5h0.8v3.4c0,0.4,0,0.7-0.1,0.9c-0.1,0.3-0.2,0.5-0.4,0.6C10.1,204,9.8,204,9.5,204z"
        fill="#FFFFFF"
      />
      <path d="M12.1,204v-4.5h-0.8v-0.6h2.5v0.6H13v4.5H12.1z" fill="#FFFFFF" />
      <path d="M14.1,204l1.4-5.1h0.6l-1.4,5.1H14.1z" fill="#FFFFFF" />
      <path
        d="M18,204c-0.4,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.3-0.1-0.5-0.1-0.9v-1.9
    c0-0.3,0-0.6,0.1-0.9c0.1-0.2,0.2-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.8-0.2c0.4,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.5,0.6
    c0.1,0.2,0.1,0.5,0.1,0.9v1.9c0,0.3,0,0.6-0.1,0.9c-0.1,0.2-0.2,0.4-0.5,0.6C18.7,204,18.4,204,18,204z M17.1,198.2v-0.8h0.7v0.8
    H17.1z M18,203.4c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.3,0-0.4v-2.3c0-0.2,0-0.3,0-0.4c0-0.1-0.1-0.2-0.2-0.3
    c-0.1-0.1-0.2-0.1-0.4-0.1s-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.3,0,0.4v2.3c0,0.2,0,0.3,0,0.4
    c0,0.1,0.1,0.2,0.2,0.3C17.7,203.4,17.9,203.4,18,203.4z M18.2,198.2v-0.8H19v0.8H18.2z"
        fill="#FFFFFF"
      />
      <path d="M20.2,204v-5.1H21v4.5h1.3v0.6H20.2z" fill="#FFFFFF" />
      <path d="M22.5,204l1.4-5.1h0.6l-1.4,5.1H22.5z" fill="#FFFFFF" />
      <path
        d="M25.1,204v-5.1h1.2c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.2,0.3,0.4
    c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.4,0.2c0.2,0,0.4,0.1,0.5,0.2
    c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.1,0.3-0.3,0.5c-0.1,0.1-0.3,0.2-0.4,0.3
    c-0.2,0.1-0.4,0.1-0.6,0.1H25.1z M26,201h0.4c0.2,0,0.3,0,0.5-0.1c0.1-0.1,0.2-0.1,0.2-0.3c0-0.1,0.1-0.3,0.1-0.5
    c0-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.5-0.1H26V201z M26,203.4h0.4c0.3,0,0.5-0.1,0.6-0.2
    c0.1-0.1,0.2-0.4,0.2-0.7c0-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.1-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.5-0.1H26V203.4z"
        fill="#FFFFFF"
      />
      <path d="M28.6,204v-5.1h2.1v0.6h-1.2v1.6h1v0.6h-1v1.8h1.3v0.6H28.6z" fill="#FFFFFF" />
      <path d="M31.2,204v-5.1h2.1v0.6H32v1.6h1v0.6h-1v1.8h1.3v0.6H31.2z" fill="#FFFFFF" />
      <path
        d="M33.8,204v-5.1H35c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.5,0.2,0.8
    c0,0.2,0,0.4-0.1,0.5c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.3l0.7,2.5h-0.8l-0.6-2.4h-0.5v2.4H33.8z M34.6,201.1h0.3
    c0.2,0,0.3,0,0.5-0.1c0.1-0.1,0.2-0.1,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.5c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.1-0.3-0.2-0.6-0.2h-0.3V201.1
    z"
        fill="#FFFFFF"
      />
    </g>
    <g>
      <rect fill="#FFFFFF" height="109.5" width="76.2" x="323.3" y="100.1" />
      <g id="barcode_16_">
        <image
          height="1295"
          id="_x36_419800023036"
          transform="matrix(2.633640e-12 -5.877896e-02 5.947522e-02 2.675640e-12 322.3785 205.1233)"
          width="1800"
          xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABwgAAAUPCAYAAACiCbQXAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAPQBJREFUeNrs2cENgCAQAEGx/57P
Gnwp7EwDGgS5ZK8LAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAC2sSwBL8zHe2x+sMcnfM7mg2cu7+8bHnJ+Z8Mzf9KMMPauO8j84O4xf2bmf3eo+cf7u7+snf+n
+9f5221+O21+tX98PzZxWwIAAAAAAADoEAgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
AAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAA
AIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAA
CBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQ
gRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEI
AQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAA
AAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAA
AAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAA
AABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAA
IEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABC
BEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQg
BAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIA
AAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAA
AAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAA
AAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAA
gBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAI
EQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCB
EAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgB
AAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAA
AAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAA
AAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAA
AEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAg
RCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIE
QgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAE
AAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAA
AAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAA
AACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAA
AAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACA
EIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgR
CAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQ
AAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEA
AAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAA
AAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAA
ACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAA
QgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBE
IAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRC
AAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQA
AAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
AAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAA
AIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAA
CBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQ
gRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEI
AQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAA
AAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAA
AAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAA
AABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAA
IEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABC
BEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQg
BAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIA
AAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAA
AAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAA
AAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAA
gBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAI
EQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCB
EAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgB
AAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAA
AAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAA
AAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAA
AEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAg
RCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIE
QgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAE
AAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAA
AAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAA
AACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAA
AAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACA
EIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgR
CAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQ
AAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEA
AAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAA
AAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAA
ACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAA
QgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBE
IAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRC
AAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQA
AAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
AAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAA
AIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAA
CBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQ
gRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEI
AQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAA
AAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAA
AAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAA
AABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAA
IEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABC
BEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQg
BAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIA
AAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAA
AAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAA
AAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAA
gBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAI
EQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCB
EAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgB
AAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAgIe9O9hx24YCKAoW+f9fdoEuCnQxje2IEsl7
DtBdm4z1SEryRVIAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAA
AAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAA
AEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAg
RCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIE
QgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAE
AAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAA
AAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAA
AACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAA
AAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACA
EIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgR
CAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQ
AAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEA
AAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAA
AAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAA
ACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAA
QgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBE
IAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRC
AAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQA
AAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
AAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAA
AIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAA
CBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQ
gRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEI
AQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAA
AAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAA
AAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAA
AABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAA
IEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABC
BEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQg
BAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIA
AAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAA
AAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAA
AAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAA
gBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAI
EQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCB
EAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgB
AAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAA
AAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAA
AAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAA
AEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAg
RCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIE
QgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAE
AAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAA
AAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAA
AACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAA
AAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACA
EIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgR
CAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQ
AAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEA
AAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAA
AAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAA
ACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAA
QgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBE
IAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRC
AAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQA
AAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
AAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAA
AIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAA
CBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQ
gRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEI
AQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAA
AAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAA
AAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAA
AABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAA
IEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABC
BEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQg
BAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIA
AAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAA
AAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAA
AAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAA
gBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAI
EQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCB
EAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgB
AAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAA
AAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAA
AAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAA
AEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAg
RCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIE
QgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAE
AAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAA
AAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAA
AACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAA
AAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACA
EIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgR
CAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQ
AAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEA
AAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAkOES8IHX
w2vsZY0DAOAZ/LZnYM/fAAB49r7v+dfzN7fyJwgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRC
AAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQA
AAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
AAAACBEIAQAAAAAAIOSXS7Ct1wf/7nC5rBVrZPm5mIHzlWdmaJ57z9DMzA+sX3Nyn+/M0Qydn9h7
7LV+rB1YnEB47ssPWDtmwvyZCL1n7Svz3G+OL/MyP7B+zch9PjNHM3R+Yu+x57s2sCiB0OEL2Nfm
MefX9dK0/54yz71m+TIr8wPr14zc55MzND/nJ/Ye+7xnAwsRCM88gN2gcVM3B56fh5ems/aUee4z
S7MyP7B+zcfsPHfj/DQje4/714y1AJsRCPc/gB28rPJSjRmYx8+/t7P6nD3lJXifWdp75gfWrxm5
z/eeu83P+WlG9h73rBfzhgMIhPsdwg5f8FDO3JmMCTP2wrT+PfNlpsvNc1zw65iT+YH16xn625/H
7Pado/k5P83I3uPZ92tgEzb2Hofw2PjnHwf9/ievM2fB8w/kZvD8TIb95jy7ce2Y53XX82VO5ufa
T7tep9znrN+zn6NnM7+952h+zk8zsvfY/zvpyrP3Sc/fbMLicQg7IK01Z8EaD+Nm8NxcBKUz9tY4
YB2Z5ffX0ZdM5mcGc67V7s/f1m/nWXo289t7hubn/LT37D3rxOx2ef71/Te3+sslWPZQsbFh/b3t
rxPtvsz+6a9j/Vw7w1n3zJl/RalZXjvHd/97czI/rF/rd+93ZT+r64LzE9eEeevEd9IQIxDOPYS/
+dMPDmE8IJ63tznvZfanX9O+vfcsu+Oe+claMc/v96P7pfmZH9avGfmZMTvnpxnae1xz3X0nDbxF
IFznBugQxkPXeQ9Y9F5mneX3nWV3X2uRcI/7kjmZH1i/ZuRndy3MzvlpTn5e62Pdd2tgIb9cgscP
YocweJjlrDmND3+el3vBxzMci8/WTJ+dpTmZH1i/ntlmf4b6+TPzC+iX2Tk/zdDe45b1YSaAQLjJ
TRpOf8l2XblrVneduyLh/jNk/jzHAnvQlxXmh/Vr/e7zfO1PGTWe24Z5Oj+dn/Ye0++vnqGBf/gr
Rh3EtF+ycV29JM19wbXunr2mT/4ML3vSc5D5gfXLJffdT/+/SOPL/85z27X7bFy8BszO+WlG9h7X
XFd7GfiXQOggBt7bo+M3/7DfTE+5pxTvoz6bz3vlvvdlhflh/Vq/a83o6mfsWc/rzp/7rvVOz/PO
T+envcfK+9c8gf8QCO89iIUEvJDu+ZA93vx3sQesi3kzHOZ61NnqPDE/88P67azfVe737t/z5njX
dx1X/T7uG85Pe8/eK91b71wrwGYEwnsPYpi9/qyzZ1+AXP81z+Bx8GfDPH1O57L5Yf1av6c8e48N
fx/3+WfWuv3l/DQje8+6MDfgAgKhg5izXqqBM/amSOh8O/m5yOfwuX0OzL2zft/5bL7k9oxmhs4d
56e9x5w1b17A/xIIHcR4AAZ7AHP0Yuy6OV/MD+vX+n1iJid8yX367E7922vcM5yf9p55nX7tvLMC
vyUQOojxgAheZtf8Obw4dZ8ffLYzzwHzMz+sX854RsP8nJ9ma++x+r41I+AtAuFZD1A016Cbvgd2
+wBwPjpnzM/8sH69vzy3Pkp/gmnY385P56e9Z+9Z38AZBMJ5B7EbJQBeBLzgWJ+YH1i/rM/7u+vk
/MTe45Q9a70AbxMIHcTsvQ6tNewDL3XA9fvPl2fmB9av5yHP3vvMzrpyXpx0ftp7/MmaNhPgIwKh
g5h916G1Bu5FYF1ifli/7DKn1d9fvF/9fD1cG+cn9h7r71frBfjYL5fAQQzgTAYmsx/ND6xfCs+r
w/7B/HHtueB+Ys0At/AnCK87iOHOtejGD60Xvfp9yX0Z6w7zw/ptPgP5OTE73P/tPWsYYBKB8LqD
2A0RDwUAuD+ty7Oa+WH9Wr+A89P5CaftU/sE+NrfArR3Z7ux3EYAQC8N/f8v0w824Cy+0sw0l1rO
AfISO5KGtbDYVCsuCA0syDcAYO0hHfGDzvk7k59fnLecq9E/UXvWE2jBBaEhgly5aONHPVgHnz/f
zzPEAfED+QugfwLqFIjEBeHzRuzCBkMBYN8AOS9+IH8RA1C7wLs8kwaucUFoYEK+AVQ/OIG8Ez+Q
v2fPLcP5BWdr9E/UnrUEYnNBaHAgRy7a+AGHGZ/VvCQe4of8lb+IF+ifoEYBlnBB6DVuDAWgLqyH
NRAHAIAOPMsBtZf9bGstgWVcEIIhCqDLQUr/BgAAAIBfLgj9pgbRc1GuQU1q+94envX70jPfED+Q
v2AGR/9Ue/LTWgJbeIMQDK0AWQ+Js/jns1chfiB/6TXbsK9XDGsCag+A/9b5gtBvauCACcCO2UH/
thbiB/IXQP8Enpwr1SawnTcIIeZQYNMHVh8mIlr1FuG07gAAOF+D2gPgdV0vCP2mBpnzEICzPfWd
r21WkGOIH8hfiFMjZjO5gdqrkJPWE9jCG4QQj00fHAb1vDgxdzkIAJgBxAVQewDldLwg9MCYyHlo
iALIuc8Pa5rGUBPih/yVv1Be93rTPxErdQnwI28Q2uAwEABU22Pn4a9jRgAAiHO+NpuB2nNeBnhB
twtCFzPY8AF69MD5cN93OQgAffkT43njJB6g9rLvKwDHfFkChwtC5IK4A/zVC1cfnOabPdbFIABA
3lkSUHu8f74VR2iq0wXhkweO4+D3mpqzHARgef8dL/57Dk4AAPnO1+YzUHtZ1uwdnkkDW3mDMEaz
f+Xrasp1iS3s7c1qLF9PnBdyweUgAOAsl2fGFwdQe7x3lv3064ozFPaHJXjcKJ/+fxy9+72otVnb
ZOF7akTcT+yl88WfST6q3yl+4of8lb8QqhbMZ/qn/qn2usbDM2ngsS4XhKtf5b7ZGDVlwyhABzsP
nPPNHt358GsPEz+Qv8g984G+YJ2QU5xfO8+kge38idG8G6I/m5ef+AH83Cd3/7kUfRoAIL75w39v
bgO11zEOt34WcYci/InRnI048s/EazGykYJeyWtGs+8LAJhPeH+e92YLqL3OcfAzAR/p8Abh01e5
55v//unm6bc2bOAA1e18k/DdvRwAcIYjbjy81QRqr8Ke4pk0cIQ/Mfq8eb7TCMemxqwh5yJWsHcw
1idr98554HsAAJgX7pmLvoZYgdqrHAvPpIHH/InRZ4eCcfF/v2MTZ388bJqAnv18/xUXAMAcUDMG
U0xB7fHjmdgzaWAJF4T3m6iG7GAJqE3u7Z3yBACIMIN0n9Nnwq8Nak/tnd6DPJMGlnJBeK8Ja8gO
k4D6sRYx18FhFgAwg+01D89cZjtQe5n3H8+kgS2qXxCuaEw7m7DDhpwD1Chx9075AgBmRNau881f
xBJn1J7ay/SZPZMGtvMG4f0mvKMhG3rjbf42WzC0kjMv7KkAgDn02SwV6a8zmO1Qe2ovw57jmTRw
hAvCugcPDdlhEtAfkTMAwLN93nkOgA48k4aGvixB2IasmdY6VAKx+uP85WGP/itnAMAcwYn5PVoM
zHWoPbXH7/PG3giNeIPQYYe962zwgfMHncg9MtKfeLHPiR8AcGbu5Ps1vvHn9Mx0qD21hzhDey4I
HUTYt+GJIRhaV38vfeWftbzZfx12AKD+HGjuOu/WhQWoPbWH/Q9aqnxB6OGddQAMrBH6pLfOzu5r
438OufZXAICc875f+gK1V/HM6uwOhOENwvibMjk3OrGDXD1yx+DqYvD8AWMc7sfiCwB9Zgruzfzi
AWqP8/EHGnBBqAmy/kApfyBnv15xoTd/uRi80Xd/ygWXhADAipmCu3P/uJAPoPbU3un1BjjGBWGP
jcGmC+iT7/XMVy/55pv//gj0ObN4ZW3Hi+s3Nv6MAIC5EjECtYeYOytDGl+WAJZubgYmuDe4zsP1
/slgbThev/4jUK7YAwDAWY4zs7/ZGtQeAA95gxAcKKHaYcXPVb/fPl1bbxICgNkC87/cALUH0JoL
wl4bL0CHfjkK/izd9oF5YE1cEqIWxQ/kL9ZX7JAjWFfEGdpyQQiv8/YgGGJf+b6/uxh0cfS81+6K
V+bP4ECI+CF/wVlOv8B6INcAeJsLQgciHCih8mFlFPxe9qr1ay12AOAsBwDcO+cDF7ggzMPBBeDz
/rnjAm/X19X/76zH6q/rEOSAiviB/I295mYsM6L61D9Re9w7gwMBfFkCcKiExoPtqTfWHHpyrNFY
/HNM+wQApJgJAQCgHReE8L358J9X+ZwO11Q1/HypetA4tOYudAGg7nzh/FJ7tjfHgdoD4EX+xCgA
cFPEg+Qo/vk6xxbxQ/4iBwC1AwD8ckEIAFEP2X67/e56WH8AqDd72d/NjIDaA+BvLggBgFui/4by
aPI5HfZrr4P4WQdxsw5mC+uIvmEdAID/44LQYAPAPt4etPcCAPZzxBvUHmILhFP5glDjAoC43rk8
vb2nj8OfFwBYt9d6NgBAtvMjwBFfloBEG5wNFsjE24P19kCXfEC3GdieRPa5Sw4DAJnmX7MLR/kT
owDAaVkv2gzqAJBnrrBvAwDAN1wQAsB63h6sa/jcalP8xA/5K3/Dr6t1Qw7on3JA3gHwAxeEa4cP
AMAB03xhphI/kL/ci7GH06B/gvoDeIELwj4ckgBiD/T6tD0VAHg2b9mjMd+B2kOMgRd9NWhAfvOC
bpvYbPI5Qa/JaRaK2WyYp1N9ip/4IX/lb9BZwnqB/qkfoPYA3uANQgBYxwEAAOD8rOUyAAAA3uSC
EADu8kBL7ACAn7kcBACAhTpcEH56WJjWAIDN+4b+TIeZYqpR8RM/5K/83bSG1gn0T32BSrUXqf5u
rgFwiDcIAcDBFQAg25xllmIVuQRqD6AlF4QAAA61ABCRy0EAANikywWhwwMAu3h7kG6Gz+lz+ZyI
q895ccayPqCv6J/ISYAFvEH42YHERgTAp/uEvgwA8NmMZY5i9Zwup0DtZV7HyMQYEuh0QVihKU0p
C5C6JxuQexuNP8tMVq9DLoof8lf+hluzkfSzTGd59E/9E7VXdG8GkvMGoQ0IAD058jo4iABAH9Uu
BzGfA2pPjIGwXBAa6gHYvzcYjnHo87l8Tp8LcZa/n81XFS4H9Sb0GZ8LVu2LAMt0uyAcPisAh4d0
/RgccMUP5C9iRKx8MqOrTdTeSZ5JAyF1fIPwkyYVYSAylAHk68UGY+tS/TOPYnU75KX4IX/lb6h1
8vYg6J/6J51rzzNpYCt/YtSGA8CeoVgv7nEgcVgCgD376Ej4WcwFOWYzczqoPfYQY0im6wVh1t/Y
ACDuYcdQTNd8GBtr6WTt+nNH4of8lb9x1mgU+izEioP9Qv9E7WU7G9pjgG28QVjvsGHDBbjXg/Xh
fevkUAQA9eerkWyW+umtQXMhABX3a3seFNH5grDib2xoxAD3hmF9WH50zomIv4XuMCt+yF/5m2v/
zPL/i/af/8Fcpn/qn2pPrHbW3ukaPPmZgAC+NOTwDdahAyB+/zUMn9mHp7U2VznMip/4IX/LzlfO
vuyc2+0X+idqT+2tjy+QnD8xGvtPnHk4DRB/ENZ/6+7DDsN5D5sOs+IH8rff2mefcaLH7t/+YyZT
w3qE2lN7Nc/CnolAEy4I4w4tGjFA/B6v/9Y/GDkM742ln9d6iB/y13xF7LjNF/75DJxX9gr9U+2p
Pfvpvu8hzmAIaN1gR4CfRwyJnsvWXxwqxk+s+vYuh+H4taSOxQ/5K3/rzMdZjeZxG0F+PnuF/qn2
1J6zsHoEFHK6hqwRU2mwsP7iUC1u4tS3fzkMx59nzFDiB/K3zmyc2RC3beug1vRP66/2xF6cAUNr
yYbsATWVhgrrLw4OwVTqYS4H49eXWhY/kL915uLsXBCuXxPPS/RPa6/2xH/fmoszGFq53JA1YioM
FNZeX64aL3HJUUt+gzJ3PIdaFj/xQ/6aiwsY4rdsjWbDtdc/e8dV7eGZNGBwbdaQbbpUGCasuf5c
NV7ikLO2hjxJG8shTuInfshfs3FyLgitu/6pf6o9TueBZ9KAJh5scx4bvq54EWmQsM56deWYWfM6
tTY2fx+5cv4A6iArfiB/zciRDbG03vqn/qn2uJgLnkkDirvY5ixO7M5XOZZ3MBe7Mwda1KJcyRlL
cRI/kL9mZ3GvGU97hP5pvdWefBBvQJEbimBBnsq1nIO52KkBsVVzYilO4gfy1z5tNu8UU3uE/mmt
1Z6cEG9AsZduyOLC6RyVc3mGcrFbFyN5rzblS704ipP4gfw1O5vN68VWfemf1lvt4Zk0oOhLN2ax
ANjbb/VZuSFn6sZRrMQP5C/knrvUlv6J2uNOTog5NKYB3GvO1h5g3/Crx8oXc1Ht+ImV+IH8hbwz
l5rSP1F73MsJsQcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAvvUnd88g6Uucl5sAAAAASUVORK5CYII="
        />
      </g>
    </g>
    <text
      fill="#FFFFFF"
      font-family="'ArialMT'"
      font-size="8px"
      id="expiry"
      transform="matrix(1 0 0 1 265.7864 202.5906)"
    >
      12.12.2012
    </text>
    <text
      fill="#FFFFFF"
      font-family="'ArialMT'"
      font-size="7px"
      id="order-id"
      transform="matrix(1 0 0 1 195.1691 203.2975)"
    >
      #{{ orderNumber }}
    </text>
    <rect clip-rule="evenodd" fill="none" fill-rule="evenodd" height="36.9" width="130.8" x="265.8" y="4.3" />
    <g id="back-text-area-13">
      <rect fill="none" height="61.31" width="124.79" x="270.89" y="8.45" />
      <text id="back-text-container" transform="matrix(1 0 0 1 270.894 13.4312)" />
    </g>
  </svg>
</template>

<script>
import { BeerLabelBaseMixin } from '@/components/labels/utils'
import { createStyle } from '@/assets/styles/StyleArial'

export default {
  mixins: [BeerLabelBaseMixin],

  computed: {
    style() {
      return createStyle()
    },
  },
}
</script>
